import React, { useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./Crop.css";
import { CloudUploadOutlined, RadiusSettingOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Row, Typography } from "antd";

const { Text } = Typography;
const defaultSrc = "/assets/img/trimitra-background.png";

export const NoCrop = (props) => {
  const [image, setImage] = useState(defaultSrc);
  const [cropData, setCropData] = useState("#");
  const [cropper, setCropper] = useState();
  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
      // console.log(cropData);
    }
  };

  const saveImage = () => {
    props.onSaveImage(cropData);
  };

  return (
    <React.Fragment>
      <Row>
        <Col xs={24} xl={24} className={`pad-10 mb-10`}>
          <input onChange={onChange} type="file" className="upload-box" placeholder="Upload File" />

          <label className="custom-file-upload">
            <input type="file" multiple onChange={onChange} />
            <i className="fa fa-cloud-upload" /> <CloudUploadOutlined /> Upload Image
          </label>

          <Button onClick={getCropData} style={{ float: "right" }} type="primary" icon={<RadiusSettingOutlined />}>
            {" "}
            Crop Image{" "}
          </Button>

          <br />
          <br />
          <Cropper
            style={{ height: 400, width: "100%" }}
            preview=".img-preview"
            src={image}
            viewMode={1}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            onInitialized={(instance) => {
              setCropper(instance);
            }}
            // initialAspectRatio={1 / 1}
            // aspectRatio={1 / 1}
            guides={true}
            // minCropBoxHeight={200}
            // minCropBoxWidth={200}
          />
        </Col>

        <Divider />

        <div className="col-lg-6">
          <h6>
            <span>Crop Result:</span>
          </h6>
          <div style={cropData !== "#" ? { width: "100%" } : { display: "none" }}>
            <img style={{ width: "100%" }} src={cropData} alt="please attach file then crop" />
          </div>
        </div>

        <div className="col-lg-6">
          <div style={{ display: "none" }}>
            <h1>
              <span>Upload Image:</span>
            </h1>
            <div>
              <Text type="secondary">Target Website</Text>: {props.params?.targetWebsite}
              <br />
            </div>
            <div>
              <Text type="secondary">Category</Text>: {props.params?.imageCategory?.replace("_", " ")?.toUpperCase()}
              <br />
            </div>
            <div>
              <Text type="secondary">Vehicle Brand</Text>: {props.params?.vehicleBrand?.toUpperCase()}
              <br />
            </div>
            <div style={props.params?.vehicleModel ? { width: "100%" } : { display: "none" }}>
              <Text type="secondary">Vehicle Model</Text>: {props.params?.vehicleModel?.toUpperCase()}
              <br />
            </div>
            <div style={props.params?.vehicleVariantCode ? { width: "100%" } : { display: "none" }}>
              <Text type="secondary">Vehicle Variant</Text>: {props.params?.vehicleVariantCode?.toUpperCase()} - {props.params?.vehicleVariantName?.toUpperCase()}
              <br />
            </div>
            <div style={props.params?.vehicleVariantColorCode ? { width: "100%" } : { display: "none" }}>
              <Text type="secondary">Vehicle Variant Color</Text>: {props.params?.vehicleVariantColorCode?.toUpperCase()} - {props.params?.vehicleVariantColorName?.toUpperCase()}
              <br />
            </div>
            <br />
          </div>
          <Button style={{ float: "right" }} loading={props.params?.processing} onClick={saveImage} type="primary" icon={<SaveOutlined />}>
            {" "}
            Save Image Changes{" "}
          </Button>
        </div>

        <br style={{ clear: "both" }} />
      </Row>
    </React.Fragment>
  );
};

export default NoCrop;
