import React, { Component } from "react";
import Cookies from "universal-cookie";
import { v4 as uuidv4 } from "uuid";
import { HotTable } from "@handsontable/react";
import LayoutDefault from "../../layout/layout-default.component";

import SelectBrand from "../../layout/select-brand.component";
import SelectCategory from "../../layout/select-category.component";
import SelectClass from "../../layout/select-class.component";

import { vehicleServices } from "../../../services/vehicle.services";
import { config } from "../../../config/general.config";
import { vehicleServicesPost } from "../../../services/vehicle-post.services";
import { modalError, modalSuccess } from "../../../helpers/modal.helper";

import { Button, Form } from "antd";

import "handsontable/dist/handsontable.full.css";
import FormItem from "antd/lib/form/FormItem";

class ImportVehicleVariant extends Component {
  cookies = new Cookies();

  constructor(props) {
    super(props);

    this.initState = {
      isValidTable: true,
      loadingButton: false,
      listBrand: {},
      listModel: {},
      inputValueBrandUUID: "",
      inputValueBrandName: "",
      inputValueCategory: "",
      inputValueClass: "",
    };

    this.state = { ...this.initState };
    this.hotTableComponent = React.createRef();
    this.settingHandsontable = {
      rowHeaders: true,
      colWidths: 150,
      width: "100%",
      height: 400,
      colHeaders: [
        "Variant Name",
        "Variant Code",
        "Variant Code Vin",
        "Market Name",
        "Series Name",
        "Distributor Code",
        "Model Uuid",
        "Link Wiki",
        "Link Image",
        "Engine Cap",
        "Engine Code",
        "Engine Hp",
        "Engine Torque",
        "Engine Valve Config",
        "Engine Valve",
        "Engine Cylinder",
        "Fuel Type",
        "Fuel Supply System",
        "Transmission",
        "Transmission Speed",
      ],
      columns: [
        { data: "variant_name", type: "text" },
        { data: "variant_code", type: "text" },
        { data: "variant_code_vin", type: "text" },
        { data: "market_name", type: "text" },
        { data: "series_name", type: "text" },
        { data: "distributor_code", type: "text" },
        { data: "model_uuid", type: "text" },
        { data: "link_wiki", type: "text" },
        { data: "link_image", type: "text" },
        { data: "engine_cap", type: "text" },
        { data: "engine_code", type: "text" },
        { data: "engine_hp", type: "text" },
        { data: "engine_torque", type: "text" },
        { data: "engine_valve_config", type: "text" },
        { data: "engine_valve", type: "text" },
        { data: "engine_cylinder", type: "text" },
        { data: "fuel_type", type: "text" },
        { data: "fuel_supply_system", type: "text" },
        { data: "transmission", type: "text" },
        { data: "transmission_speed", type: "text" },
      ],
    };
  }

  componentDidMount = () => {
    this.onClickRefreshTable();
  };

  afterValidate = (isValid) => {
    this.setState({
      isValidTable: isValid,
    });
  };

  onClickRefreshTable = () => {
    let emptyData = [];
    this.hotTableComponent.current.hotInstance.loadData([]);
    for (let i = 0; i < 15; i++) {
      emptyData.push(["", "", "", "", "", "", "", "", "", "", "", "", ""]);
    }
    this.hotTableComponent.current.hotInstance.loadData(emptyData);
    console.log("countRows", this.hotTableComponent.current.hotInstance.countRows());

    this.setState({
      loadingButton: false,
    });
  };

  onClickUploadData = async () => {
    const { isValidTable, inputValueBrandUUID, inputValueBrandName, inputValueCategory, inputValueClass } = this.state;

    if (!isValidTable) {
      modalError({
        title: "Validation Error",
        content: "Data Table Not Valid",
      });
      return false;
    }

    let dataTable = this.hotTableComponent.current.hotInstance.getData();
    let notValidInput = [];
    let dataCleaned = [];

    if (inputValueBrandUUID && inputValueCategory && inputValueClass) {
      let i = 1;
      const { listModel } = this.state;
      for (const row of dataTable) {
        const variant_name = row[0] ? row[0].toString().toLowerCase() : null;
        const variant_code = row[1] ? row[1].toString().toUpperCase() : null;
        const variant_code_vin = row[2] ? row[2].toString().toUpperCase() : null;
        const market_name = row[3] ? row[3] : null;
        const series_name = row[4] ? row[4] : null;
        const distributor_code = row[5] ? row[5] : null;
        const category = inputValueCategory;
        const variant_class = inputValueClass;
        const brand_uuid = inputValueBrandUUID;
        const brand_name = inputValueBrandName;
        const model_uuid = row[6];
        let model_name = "";
        let model_market_name = "";
        let model_category = "";
        let model_class = "";
        const link_wiki = row[7] ? row[7] : null;
        const link_image = row[8] ? row[8] : null;
        const engine_cap = row[9] ? Number(row[9]) : null;
        const engine_code = row[10] ? row[10] : null;
        const engine_hp = row[11] ? Number(row[11]) : null;
        const engine_torque = row[12] ? Number(row[12]) : null;
        const engine_valve_config = row[13] ? row[13].toString().toLowerCase() : null;
        const engine_valve = row[14] ? Number(row[14]) : null;
        const engine_cylinder = row[15] ? Number(row[15]) : null;
        const fuel_type = row[16] ? row[16].toString().toLowerCase() : null;
        const fuel_supply_system = row[17] ? row[17].toString().toLowerCase() : null;
        const transmission = row[18] ? row[18].toString().toLowerCase() : null;
        const transmission_speed = row[19] ? row[19] : null;

        if (variant_name || variant_code || variant_code_vin || market_name || series_name || distributor_code || model_uuid) {
          if (!variant_name) {
            notValidInput.push("Empty Variant Name On Line " + i);
          }

          if (!variant_code) {
            notValidInput.push("Empty Variant Code On Line " + i);
          }

          if (!variant_code_vin) {
            notValidInput.push("Empty Variant Code Vin On Line " + i);
          }

          if (!market_name) {
            notValidInput.push("Empty Market Name On Line " + i);
          }

          if (!series_name) {
            notValidInput.push("Empty Series Name On Line " + i);
          }

          if (!distributor_code) {
            notValidInput.push("Empty Distributor Code On Line " + i);
          }

          if (!category) {
            notValidInput.push("Empty Category On Line " + i);
          } else {
            if (!config.enum_category.includes(category)) {
              notValidInput.push("Category Not Listed On Line " + i);
            }
          }

          if (!variant_class) {
            notValidInput.push("Empty Class On Line " + i);
          } else {
            if (!config.enum_class.includes(variant_class)) {
              notValidInput.push("Class Not Listed On Line " + i);
            }
          }

          if (model_uuid) {
            // console.log(model_uuid);
            // console.log(listModel);
            // console.log(listModel[model_uuid]);
            if (listModel[model_uuid]) {
              model_name = listModel[model_uuid].model_name;
              model_market_name = listModel[model_uuid].market_name;
              model_category = listModel[model_uuid].category;
              model_class = listModel[model_uuid].class;
            } else {
              notValidInput.push("Model UUID Not Listed On Line " + i);
            }
          } else {
            notValidInput.push("Empty Model UUID On Line " + i);
          }

          if (engine_valve_config) {
            if (!config.enum_valve_config.includes(engine_valve_config)) {
              notValidInput.push("Valve Config Not Listed On Line " + i);
            }
          }

          if (fuel_type) {
            if (!config.enum_fuel.includes(fuel_type)) {
              notValidInput.push("Fuel Type Not Listed On Line " + i);
            }
          }

          if (fuel_supply_system) {
            if (!config.enum_fuel_supply_system.includes(fuel_supply_system)) {
              notValidInput.push("Fuel Supply System Not Listed On Line " + i);
            }
          }

          if (transmission) {
            if (!config.enum_transmission.includes(transmission)) {
              notValidInput.push("Transmission Not Listed On Line " + i);
            }
          }

          if (notValidInput.length < 1) {
            dataCleaned.push({
              uuid: uuidv4(),
              name: variant_name,
              market_name: market_name,
              distributor_code: distributor_code,
              code: variant_code,
              code_vin: variant_code_vin,
              color: null,
              price: null,
              engine_capacity: engine_cap,
              engine_code: engine_code,
              engine_cylinder: engine_cylinder,
              engine_hp: engine_hp,
              engine_torque: engine_torque,
              engine_valve: engine_valve,
              engine_valve_config: engine_valve_config,
              fuel: fuel_type,
              fuel_supply_system: fuel_supply_system,
              transmission: transmission,
              transmission_speed: transmission_speed,
              transmission_type: transmission,
              generation: null,
              year_production: null,
              year_first_production: null,
              year_latest_production: null,
              url_image: link_image,
              url_info: link_wiki,
              series_name: series_name,
              brand_uuid: brand_uuid,
              brand_name: brand_name,
              model_uuid: model_uuid,
              model_name: model_name,
              model_market_name: model_market_name,
              model_category: model_category,
              model_class: model_class,
            });
          }
        }
        i++;
      }
    } else {
      modalError({
        title: "Validation Error",
        content: "Empty Category | Brand | Class",
      });
      return false;
    }

    if (notValidInput.length > 0) {
      modalError({
        title: "Validation Error",
        content: (
          <div>
            {notValidInput.map((d, i) => {
              return (
                <div>
                  {d}
                  <br />
                </div>
              );
            })}
          </div>
        ),
      });
      return false;
    }

    if (dataCleaned.length > 0) {
      try {
        this.setState({
          loadingButton: true,
        });
        const token = this.cookies.get("_t_autotrimitrabiz");
        await vehicleServicesPost.setToken(token);
        await vehicleServicesPost.addVariant(dataCleaned);
        modalSuccess({
          title: "Data Processed",
          width: 800,
          content: "SUCCESS",
        });
        this.onClickRefreshTable();
      } catch (error) {
        this.setState({
          loadingButton: false,
        });
        modalError({
          title: "Process Error",
          content: error.message,
        });
      }
    } else {
      modalError({
        title: "Validation Error",
        content: "Empty Table",
      });
    }
  };

  changeInputValueBrand = async (v, i) => {
    await this.setState({
      inputValueBrandUUID: v,
      inputValueBrandName: i.children,
    });

    await this.getListModel();
  };

  changeInputCategory = async (v) => {
    await this.setState({
      inputValueCategory: v,
    });
    await this.getListModel();
  };

  changeInputClass = async (v) => {
    await this.setState({
      inputValueClass: v,
    });

    await this.getListModel();
  };

  getListModel = async (v) => {
    const { inputValueBrandUUID, inputValueCategory, inputValueClass } = this.state;

    if (inputValueBrandUUID && inputValueCategory && inputValueClass) {
      const dataModel = await vehicleServices.getModelList({
        category: inputValueCategory,
        brand_uuid: inputValueBrandUUID,
        class: inputValueClass,
      });
      const listModel = {};
      for (const b of dataModel.data.data) {
        listModel[b.model_uuid] = b;
      }

      return this.setState({
        listModel: listModel,
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <LayoutDefault defaultOpenKeys={["m-import"]} defaultSelectedKeys={["m-import-vehicle-variant"]}>
          <div className="row px-3">
            <Form name="form-filter" layout="vertical" className="pt-2">
              <div className="row mx-0">
                <div className="col-lg-11">
                  <div className="row px-2">
                    <div className="col-lg-2 px-1">
                      <SelectCategory value={this.state.inputValueCategory} onChangeValue={this.changeInputCategory} />
                    </div>
                    <div className="col-lg-2 px-1">
                      <SelectBrand value={this.state.inputValueBrandUUID} onChangeValue={this.changeInputValueBrand} />
                    </div>
                    <div className="col-lg-2 px-1">
                      <SelectClass value={this.state.inputValueClass} onChangeValue={this.changeInputClass} />
                    </div>
                    <div className="col-lg-3 px-1 pt-2">
                      <span className="mb-3 me-0">
                        <FormItem label=" ">
                          <Button
                            onClick={this.onClickUploadData}
                            title="add"
                            type="primary"
                            style={{
                              background: "#1784b8",
                              borderColor: "#1784b8",
                            }}
                            loading={this.state.loadingButton}
                            className="custom-class"
                          >
                            <span>
                              <strong style={{ fontSize: "100%" }}>+&nbsp;</strong> Add Variant
                            </span>
                          </Button>
                          &nbsp;
                          <Button
                            onClick={this.onClickRefreshTable}
                            title="add"
                            type="danger"
                            style={{
                              background: "#b81717",
                              borderColor: "#b81717",
                            }}
                            loading={this.state.loadingButton}
                            className="custom-class"
                          >
                            <span>Refresh Table</span>
                          </Button>
                        </FormItem>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Form>

            <div className="col-lg-12">
              <HotTable ref={this.hotTableComponent} settings={this.settingHandsontable} afterValidate={this.afterValidate} />
            </div>
          </div>
        </LayoutDefault>
      </React.Fragment>
    );
  }
}

export default ImportVehicleVariant;
