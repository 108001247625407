import { Modal } from "antd";

function modalInfo({ title, content, width = 300 }) {
  Modal.info({
    title: title,
    content: content,
    width: width,
  });
}

function modalSuccess({ content }) {
  Modal.success({
    content: content,
  });
}

function modalError({ title, content }) {
  Modal.error({
    title: title,
    content: content,
  });
}

function modalWarning({ title, content }) {
  Modal.warning({
    title: title,
    content: content,
  });
}

export { modalInfo, modalSuccess, modalError, modalWarning };
