import axios from "axios";

class BusinessEntityServices {
  axios = axios.create();

  async getBusinessEntity(params = {}) {
    try {

      const queries = {
        ...(params?.brand_name && {
          brand_name: params.brand_name,
        }),
      }

      return await this.axios.get("https://asia-southeast2-autotrimitra.cloudfunctions.net/api-trimitra-biz__get-businessentities/business-entity/generalentity?verified=false", {
        params: {
          ...queries,
        }
      });
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  }

  async verifyBusinessEntity(inputData) {
    const headers = {
      'Authorization': 'Basic cHVibGljOnB1YmxpYy03ODM3MmRJSzhkNjdxOGFKNzZmZDllSks='
    };
    try {
      return await this.axios.put('https://asia-southeast2-autotrimitra.cloudfunctions.net/api-trimitra-biz__post-businessentities/business-entity/generalentity', inputData, { headers })
    } catch (e) {
      throw new Error(e.response.data.error.message);
    }
  }

  async removeBusinessEntity(inputData) {
      const headers = {
        'Authorization': 'Basic cHVibGljOnB1YmxpYy03ODM3MmRJSzhkNjdxOGFKNzZmZDllSks='
      };
      try {
        return await this.axios.delete('https://asia-southeast2-autotrimitra.cloudfunctions.net/api-trimitra-biz__post-businessentities/business-entity/generalentity', {
          headers: headers,
          data: inputData // Menempatkan inputData dalam properti data
        });
      } catch (e) {
        throw new Error(e.response.data.error.message);
      }
    }


}

export const businessEntityServices = new BusinessEntityServices();
