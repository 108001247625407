import axios from "axios";
import { baseUrlAutotrimitraPartPost } from "../config/api-url.config";

class VehiclePartServicesPost {
  axios = axios.create({
    baseURL: baseUrlAutotrimitraPartPost,
  });

  setToken(token) {
    this.axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }

  async addPartType(postdata) {
    try {
      return await this.axios.post("vehicle/add-part-type", postdata);
    } catch (e) {
      throw new Error("SERVER REJECTED YOUR INPUT");
      // const { response } = e;
      // console.log(response);
      // const { request, ...errorObject } = response;
      // throw new Error(errorObject.data.error.message);
    }
  }

  async addPart(postdata) {
    try {
      return await this.axios.post("vehicle/add-part", postdata);
    } catch (e) {
      const { response } = e;
      const { request, ...errorObject } = response;
      throw new Error(errorObject.data.error);
    }
  }


}

export const vehicleServicesPost = new VehiclePartServicesPost();
