import React, { Component } from "react";
import Cookies from "universal-cookie";
import { v4 as uuidv4 } from "uuid";
import LayoutDefault from "../../layout/layout-default.component";

import SelectBrand from "../../layout/select-brand.component";
import SelectCategory from "../../layout/select-category.component";
import SelectClass from "../../layout/select-class.component";
import SelectFuel from "../../layout/select-fuel.component";
import SelectFuelSupply from "../../layout/select-fuel-supply-system.component";
import SelectTransmission from "../../layout/select-transmission.component";
import SelectValveConfig from "../../layout/select-valve-config.component";
import SelectActive from "../../layout/select-active.component";
import SelectDriveType from "../../layout/select-drive-type.component";
import SelectDisplayType from "../../layout/select-display-type.component";
import SelectDisplayCategory from "../../layout/select-display-category.component";
import EditorAbout from "../../layout/editor-about.component";

import { vehicleServices } from "../../../services/vehicle.services";
import { vehicleServicesPost } from "../../../services/vehicle-post.services";
import { modalError, modalSuccess } from "../../../helpers/modal.helper";

import { Form, Input, Table, Button, Select, Modal, Image, Checkbox } from "antd";
import { SearchOutlined, DeleteOutlined, PlusSquareOutlined, CloudUploadOutlined, EditOutlined } from "@ant-design/icons";
import SelectChairs from "../../layout/select-chairs.component";
import { distributionServices } from "../../../services/distribution.services";
import SelectDistributionDestination from "../../layout/select-destination-distribution";

const { Option } = Select;
const { TextArea } = Input;

class MasterVehicleVariant extends Component {
  cookies = new Cookies();

  constructor(props) {
    super(props);

    this.initState = {
      processing: false,
      processingFetchTableData: false,

      pageSize: 100,
      // filterCategory: "",
      // filterBrand: "",
      // filterModel: "",
      // filterVariant: "",
      dataOptionFilterModel: [],

      tableSelectedRowKeys: [],
      // dataVariant: [],
      dataOptionModel: [],
      titleCanvas: "Default Title",

      inputVariantUUID: "",
      inputValueVariantName: "",
      inputValueVariantCode: "",
      inputValueVariantCodeVin: "",

      inputValueMarketName: "",
      inputValueSeriesName: "",
      inputValueDistributorCode: "",

      inputValueCategory: "",
      inputValueClass: "",
      inputValueBrandUUID: "",
      inputValueBrandName: "",

      inputValueModelUuid: "",
      inputValueModelName: "",
      inputValueModelMarketName: "",

      inputValueUrlInfo: "",
      inputValueUrlImage: "",
      inputValueUrlImageThumb: "",

      inputValueEngineCapacity: "",
      inputValueEngineCode: "",
      inputValueEngineHP: "",
      inputValueEngineTorque: "",
      inputValueEngineValveConfig: "",
      inputValueEngineCylinder: "",
      inputValueEngineValve: "",

      inputValueFuel: "",
      inputValueFuelSupplySystem: "",
      inputValueTransmission: "",
      inputValueTransmissionSpeed: "",
      inputValueDriveType: "",
      inputValueDoors: "",
      inputValueChairs: [],
      inputValueStatus: "true",
      inputValueAbout: "",

      loadingButton: false,
      showHideReadCanvas: "d-block",
      showHideFormCanvas: "m-2 px-1 d-none",
      showHideButtonForm: "me-1",
      formEdit: false,
      disableInput: "",

      color_order_list: [],
      color_modal: false,

      inputColorCode: "",
      inputColorName: "",
      inputColorMarketName: "",

      display_list: [],
      display_modal: false,

      inputDisplayCategory: "",
      inputDisplayType: "",
      inputDisplayLink: "",
      inputDisplayLinkThumb: "",
      inputDisplayCaption: "",

      distributeModal: false,
      distCheckboxChecked: false,
      distributeVariant: {},
      distributeVariantColor: [],
      distributeVariantColorObj: {},
      distModalLabel: "",
      distDestination: "NONE",
      dataOptionArea: [],
      distArea: "",
      distPriceDefault: 0,
      distPriceStrikethrough: 0,
      distPriceMin: 0,
      distPriceMax: 0,
      distImageUrl: "",
      distImageThumbUrl: "",
    };

    this.state = { ...this.initState };
    this.formInput = React.createRef();
  }

  tableColumn = [
    {
      title: "Variant UUID",
      dataIndex: "variant_uuid",
      key: "variant_uuid",
    },
    {
      title: "Variant Name",
      dataIndex: "variant_name",
      key: "variant_name",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Variant Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Brand UUID",
      dataIndex: "brand_uuid",
      key: "brand_uuid",
    },
    {
      title: "Brand Name",
      dataIndex: "brand_name",
      key: "brand_name",
    },
    {
      title: "Model UUID",
      dataIndex: "model_uuid",
      key: "model_uuid",
    },
    {
      title: "Model Name",
      dataIndex: "model_name",
      key: "model_name",
    },
    {
      title: "Market Name",
      dataIndex: "market_name",
      key: "market_name",
    },
    {
      title: "Distributor Code",
      dataIndex: "distributor_code",
      key: "distributor_code",
    },

    {
      title: "Action",
      dataIndex: "model_uuid",
      key: "model_uuid",
      render: (text, record) => {
        return (
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              onClick={() => {
                this.showDistributeModal(record);
              }}
              icon={<CloudUploadOutlined />}
              type="primary"
              title="Distribute"
              style={{
                background: "#36383a",
                borderColor: "#36383a",
                minWidth: "40px",
              }}
              loading={this.state.loadingButton}
            />
            <Button
              onClick={() => {
                this.onClickEditVariant(record);
              }}
              icon={<EditOutlined />}
              type="primary"
              title="Edit"
              style={{
                background: "#ad253e",
                borderColor: "#ad253e",
                minWidth: "40px",
              }}
              loading={this.state.loadingButton}
            />
          </div>
        );
      },
    },
    {
      title: "Preview",
      dataIndex: "url_image_thumbnail",
      key: "url_image_thumbnail",
      render: (text, record) => {
        let url_image_thumbnail = !record.url_image_thumbnail ? record.url_image : record.url_image_thumbnail;

        if (record?.url_display_assets?.length > 0) {
          url_image_thumbnail = record.url_display_assets[0].url_asset_thumbnail;
        }

        console.log("url_image_thumbnail", url_image_thumbnail);

        return (
          <>
            {!url_image_thumbnail ? (
              <span>No Image</span> // Display "NONE" if both URLs are empty
            ) : (
              <Image
                src={url_image_thumbnail}
                preview={true} // Enable image preview
                style={{
                  width: "30px", // Adjust image size
                  height: "30px",
                  display: "block",
                }}
                alt="Show Image"
              />
            )}
          </>
        );
      },
    },
  ];

  changeFilterCategory = async (v) => {
    await this.setState({
      filterCategory: v,
    });
    this.fetchFilterModel();
  };

  changeFilterBrand = async (v) => {
    await this.setState({
      filterBrand: v,
    });
    this.fetchFilterModel();
  };

  changeFilterModel = (v) => {
    // console.log(v);
    this.setState({
      filterModel: v,
    });
  };

  changeFilterVariant = (v) => {
    this.setState({
      filterVariant: v.target.value,
    });
  };

  fetchFilterModel = async () => {
    try {
      const { filterCategory, filterBrand } = this.state;

      // console.log(filterCategory);
      // console.log(filterBrand);

      if (filterCategory !== "" && filterBrand !== "") {
        const params = {
          category: filterCategory,
          brand_uuid: filterBrand,
        };

        const dataModel = await vehicleServices.getModelList(params);

        this.setState({
          dataOptionFilterModel: dataModel.data.data,
        });
      }
    } catch (error) {
      this.setState({
        dataOptionFilterModel: [],
      });
    }
  };

  fetchDataVariant = async () => {
    try {
      const filter = this.state;
      const params = {};

      if (filter?.filterCategory === "" && filter?.filterBrand === "" && filter?.filterModel === "" && filter?.filterVariant === "") {
        modalError({
          title: "Validation Error",
          content: "Invalid Filter",
        });
        return false;
      } else {
        if (filter?.filterBrand !== "") {
          params.brand_uuid = filter.filterBrand;
        }

        if (filter?.filterModel !== "") {
          params.model_uuid = filter.filterModel;
        }

        if (filter?.filterVariant !== "") {
          params.code = filter.filterVariant;
        }

        // console.log(params);

        if (!params.code && !params.model_uuid) {
          modalError({
            title: "Validation Error",
            content: "Invalid Filter",
          });
          return false;
        }
        if (Object.keys(params).length > 0) {
          this.setState({
            processingFetchTableData: true,
            loadingButton: true,
          });

          const dataVariant = await vehicleServices.getVariantlList(params);

          this.setState({
            dataVariant: dataVariant.data.data,
            processingFetchTableData: false,
            loadingButton: false,
            tableSelectedRowKeys: [],
          });
        }
      }
    } catch (error) {
      this.setState({
        dataVariant: [],
        processingFetchTableData: false,
        loadingButton: false,
        tableSelectedRowKeys: [],
      });
    }
  };

  onClickFilter = async () => {
    await this.setState({
      filterValueVariantUUID: "",
    });
    await this.fetchDataVariant();
  };

  onClickAddVariant = async () => {
    this.setState({
      titleCanvas: "Add New Variant",
      showHideReadCanvas: "d-none",
      showHideFormCanvas: "m-2 px-1 d-block",
      formEdit: false,
      color_order_list: [],
      disableInput: "",
    });
  };

  onClickEditVariant = async (r) => {
    if (r.color !== null && r.color?.length > 0) {
      await this.setState({
        color_order_list: r.color,
      });
    }
    if (r.url_display_assets !== null && r.url_display_assets?.length > 0) {
      await this.setState({
        display_list: r.url_display_assets,
      });
    }
    await this.setState({
      titleCanvas: "Edit Variant",
      showHideReadCanvas: "d-none",
      showHideFormCanvas: "m-2 px-1 d-block",

      inputVariantUUID: r.variant_uuid,
      inputValueVariantName: r.variant_name,
      inputValueVariantCode: r.code,
      inputValueVariantCodeVin: r.code_vin,

      inputValueCategory: r.category,
      inputValueClass: r.class,
      inputValueBrandUUID: r.brand_uuid,
      inputValueBrandName: r.brand_name,

      inputValueMarketName: r.market_name,
      inputValueSeriesName: r.series_name,
      inputValueDistributorCode: r.distributor_code,

      inputValueModelUuid: r.model_uuid,
      inputValueModelName: r.model_name,
      inputValueModelMarketName: r.model_market_name,

      inputValueUrlInfo: r.url_info,
      inputValueUrlImage: r.url_image,
      inputValueUrlImageThumb: r.url_image_thumbnail,

      inputValueEngineCapacity: r.engine_capacity,
      inputValueEngineCode: r.engine_code,
      inputValueEngineHP: r.engine_hp,
      inputValueEngineTorque: r.engine_torque,
      inputValueEngineCylinder: r.engine_cylinder,
      inputValueEngineValve: r.engine_valve,

      inputValueFuel: r.fuel,
      inputValueFuelSupplySystem: r.fuel_supply_system,
      inputValueTransmission: r.transmission,
      inputValueTransmissionSpeed: r.transmission_speed,

      inputValueDriveType: r.drive_type,
      inputValueDoors: r.doors,
      inputValueChairs: r.chairs,
      inputValueStatus: r?.active?.toString()?.toLowerCase(),
      inputValueAbout: r.about,

      showHideButtonForm: "d-none",
      formEdit: true,
    });

    await this.formInput.current.setFieldsValue({
      brand_uuid: r.brand_uuid,
      category: r.category,
      class: r.class,
      fuel: r.fuel,
      fuel_supply_system: r.fuel_supply_system,
      transmission: r.transmission,
      engine_valve_config: r.engine_valve_config,
      drive_type: r.drive_type,
      status: r?.active?.toString()?.toLowerCase(),
    });

    await this.fetchDataModel();

    await this.formInput.current.setFieldsValue({
      model_uuid: r.model_uuid,
    });
  };

  onClickCancel = async () => {
    this.setState({
      dataOptionModel: [],
      titleCanvas: "Default Title",

      inputVariantUUID: "",
      inputValueVariantName: "",
      inputValueVariantCode: "",
      inputValueVariantCodeVin: "",

      inputValueMarketName: "",
      inputValueSeriesName: "",
      inputValueDistributorCode: "",

      inputValueCategory: "",
      inputValueClass: "",
      inputValueBrandUUID: "",
      inputValueBrandName: "",

      inputValueModelUuid: "",
      inputValueModelName: "",
      inputValueModelMarketName: "",

      inputValueUrlInfo: "",
      inputValueUrlImage: "",
      inputValueUrlImageThumb: "",

      inputValueEngineCapacity: "",
      inputValueEngineCode: "",
      inputValueEngineHP: "",
      inputValueEngineTorque: "",
      inputValueEngineValveConfig: "",
      inputValueEngineCylinder: "",
      inputValueEngineValve: "",

      inputValueFuel: "",
      inputValueFuelSupplySystem: "",
      inputValueTransmission: "",
      inputValueTransmissionSpeed: "",
      inputValueDriveType: "",
      inputValueDoors: "",
      inputValueChairs: [],
      inputValueStatus: "true",
      inputValueAbout: "",

      loadingButton: false,
      showHideReadCanvas: "d-block",
      showHideFormCanvas: "m-2 px-1 d-none",
      showHideButtonForm: "me-1",
      formEdit: false,
      disableInput: "",

      color_order_list: [],
      color_modal: false,

      inputColorCode: "",
      inputColorName: "",
      inputColorMarketName: "",

      display_list: [],
      display_modal: false,

      inputDisplayCategory: "",
      inputDisplayType: "",
      inputDisplayLink: "",
      inputDisplayLinkThumb: "",
      inputDisplayCaption: "",
    });

    this.formInput.current.resetFields();
  };

  changeInputValueVariantName = (v) => {
    this.setState({
      inputValueVariantName: v.target.value,
    });
  };

  changeInputValueVariantCode = (v) => {
    this.setState({
      inputValueVariantCode: v.target.value,
    });
  };

  changeInputValueVariantCodeVin = (v) => {
    this.setState({
      inputValueVariantCodeVin: v.target.value,
    });
  };

  changeInputValueMarketName = (v) => {
    this.setState({
      inputValueMarketName: v.target.value,
    });
  };

  changeInputValueSeriesName = (v) => {
    this.setState({
      inputValueSeriesName: v.target.value,
    });
  };

  changeInputValueDistributorCode = (v) => {
    this.setState({
      inputValueDistributorCode: v.target.value,
    });
  };

  changeInputValueCategory = async (v) => {
    await this.setState({
      inputValueCategory: v,
    });
    this.fetchDataModel();
  };

  changeInputValueClass = async (v) => {
    await this.setState({
      inputValueClass: v,
    });
    this.fetchDataModel();
  };

  changeInputValueBrand = async (v, i) => {
    await this.setState({
      inputValueBrandUUID: v,
      inputValueBrandName: i.children,
    });
    this.fetchDataModel();
  };

  changeInputValueModel = (v, i) => {
    this.setState({
      inputValueModelUuid: v,
      inputValueModelName: i.children,
      inputValueModelMarketName: i.add,
    });
  };

  fetchDataModel = async () => {
    try {
      const filter = this.state;

      // console.log("inputValueCategory = " + filter.inputValueCategory + "" + "inputValueClass = " + filter.inputValueClass + "" + "inputValueBrandUUID = " + filter.inputValueBrandUUID + "");

      if (filter.inputValueCategory !== "" && filter.inputValueClass !== "" && filter.inputValueBrandUUID !== "") {
        const params = {
          category: filter.inputValueCategory,
          class: filter.inputValueClass,
          brand_uuid: filter.inputValueBrandUUID,
        };

        const dataModel = await vehicleServices.getModelList(params);

        this.setState({
          dataOptionModel: dataModel.data.data,
        });
      }
    } catch (error) {
      this.setState({
        dataOptionModel: [],
      });
    }
  };

  changeInputUrlInfo = (v) => {
    this.setState({
      inputValueUrlInfo: v.target.value,
    });
  };

  changeInputUrlImage = (v) => {
    this.setState({
      inputValueUrlImage: v.target.value,
    });
  };

  changeInputUrlImageThumb = (v) => {
    this.setState({
      inputValueUrlImageThumb: v.target.value,
    });
  };

  changeInputValueEngineCapacity = (v) => {
    this.setState({
      inputValueEngineCapacity: v.target.value,
    });
  };

  changeInputValueEngineCode = (v) => {
    this.setState({
      inputValueEngineCode: v.target.value,
    });
  };

  changeInputValueEngineHP = (v) => {
    this.setState({
      inputValueEngineHP: v.target.value,
    });
  };

  changeInputValueEngineTorque = (v) => {
    this.setState({
      inputValueEngineTorque: v.target.value,
    });
  };

  changeInputValueEngineCylinder = (v) => {
    this.setState({
      inputValueEngineCylinder: v.target.value,
    });
  };

  changeInputValueEngineValve = (v) => {
    this.setState({
      inputValueEngineValve: v.target.value,
    });
  };

  changeInputValueFuel = (v) => {
    this.setState({
      inputValueFuel: v,
    });
  };

  changeInputValueFuelSupplySystem = (v) => {
    this.setState({
      inputValueFuelSupplySystem: v,
    });
  };

  changeInputValueTransmission = (v) => {
    this.setState({
      inputValueTransmission: v,
    });
  };

  changeInputValueEngineValveConfig = (v) => {
    this.setState({
      inputValueEngineValveConfig: v,
    });
  };

  changeInputValueTransmissionSpeed = (v) => {
    this.setState({
      inputValueTransmissionSpeed: v.target.value,
    });
  };

  changeInputValueDoors = (v) => {
    this.setState({
      inputValueDoors: v.target.value,
    });
  };

  changeInputValueChairs = (v) => {
    console.log(v);
    this.setState({
      inputValueChairs: v,
    });
  };

  changeInputValueAbout = (html) => {
    this.setState({
      inputValueAbout: html,
    });
  };

  changeInputValueDriveType = (v) => {
    this.setState({
      inputValueDriveType: v,
    });
  };

  changeInputValueStatus = (v) => {
    this.setState({
      inputValueStatus: v,
    });
  };

  onClickSave = async () => {
    const status = this.state.inputValueStatus;
    const postData = {
      name: this.state.inputValueVariantName.toString().toLowerCase(),
      code: this.state.inputValueVariantCode.toString().toUpperCase().replace(/ /g, ""),
      code_vin: this.state.inputValueVariantCodeVin.toString().toUpperCase().replace(/ /g, ""),

      market_name: this.state.inputValueMarketName,
      series_name: this.state.inputValueSeriesName,
      distributor_code: this.state.inputValueDistributorCode,

      brand_uuid: this.state.inputValueBrandUUID,
      brand_name: this.state.inputValueBrandName,
      model_uuid: this.state.inputValueModelUuid,
      model_name: this.state.inputValueModelName,
      model_market_name: this.state.inputValueModelMarketName,
      model_category: this.state.inputValueCategory,
      model_class: this.state.inputValueClass,

      color: this.state.color_order_list,
      price: null,

      engine_capacity: this.state.inputValueEngineCapacity,
      engine_code: this.state.inputValueEngineCode,
      engine_hp: this.state.inputValueEngineHP,
      engine_torque: this.state.inputValueEngineTorque,
      engine_valve: this.state.inputValueEngineValve,
      engine_valve_config: this.state.inputValueEngineValveConfig,
      engine_cylinder: this.state.inputValueEngineCylinder,

      fuel: this.state.inputValueFuel,
      fuel_supply_system: this.state.inputValueFuelSupplySystem,

      transmission: this.state.inputValueTransmission,
      transmission_type: this.state.inputValueTransmission,
      transmission_speed: this.state.inputValueTransmissionSpeed,

      generation: null,
      year_production: null,
      year_first_production: null,
      year_latest_production: null,

      url_image: this.state.inputValueUrlImage,
      url_image_thumbnail: this.state.inputValueUrlImageThumb,
      url_info: this.state.inputValueUrlInfo,
      active: status === "true" ? true : false,

      drive_type: this.state.inputValueDriveType,
      doors: this.state.inputValueDoors,
      chairs: this.state.inputValueChairs,
      about: this.state.inputValueAbout,
      url_display_assets: this.state.display_list,
    };

    if (!postData.name) {
      modalError({
        title: "Validation Error",
        content: "Invalid Variant Name",
      });
      return false;
    }
    if (!postData.code) {
      modalError({
        title: "Validation Error",
        content: "Invalid Variant Code",
      });
      return false;
    }
    if (!postData.code_vin) {
      modalError({
        title: "Validation Error",
        content: "Invalid Variant Code VIN",
      });
      return false;
    }
    if (!postData.market_name) {
      modalError({
        title: "Validation Error",
        content: "Invalid Market Name",
      });
      return false;
    }
    if (!postData.brand_uuid) {
      modalError({
        title: "Validation Error",
        content: "Invalid Brand",
      });
      return false;
    }
    if (!postData.model_uuid) {
      modalError({
        title: "Validation Error",
        content: "Invalid Model",
      });
      return false;
    }

    this.setState({
      loadingButton: true,
    });

    try {
      if (this.state.formEdit) {
        postData.uuid = this.state.inputVariantUUID;
        const token = this.cookies.get("_t_autotrimitrabiz");
        await vehicleServicesPost.setToken(token);
        await vehicleServicesPost.updateVariant([postData]);
        this.onClickCancel();
        modalSuccess({
          content: "SUCCESS",
        });
      } else {
        postData.uuid = uuidv4();
        const token = this.cookies.get("_t_autotrimitrabiz");
        await vehicleServicesPost.setToken(token);
        await vehicleServicesPost.addVariant([postData]);
        this.onClickCancel();
        modalSuccess({
          content: "SUCCESS",
        });
      }

      this.setState({
        filterValueVariantUUID: postData.uuid,
      });
      await this.fetchDataVariant();
    } catch (error) {
      this.setState({
        loadingButton: false,
      });
      modalError({
        title: "Process Error",
        content: error.message,
      });
    }
  };

  showColorModal = async () => {
    this.setState({
      inputColorCode: "",
      inputColorName: "",
      inputColorMarketName: "",
      color_modal: true,
    });
  };

  closeColorModal = async () => {
    this.setState({
      inputColorCode: "",
      inputColorName: "",
      inputColorMarketName: "",
      color_modal: false,
    });
  };

  changeInputColorCode = (v) => {
    this.setState({
      inputColorCode: v.target.value,
    });
  };

  changeInputColorName = (v) => {
    this.setState({
      inputColorName: v.target.value,
    });
  };

  changeInputColorMarketName = (v) => {
    this.setState({
      inputColorMarketName: v.target.value,
    });
  };

  addColorList = async () => {
    const color_code = this.state.inputColorCode;
    const color_name = this.state.inputColorName;
    const color_market_name = this.state.inputColorMarketName;
    if (color_code !== "" && color_name !== "" && color_market_name !== "") {
      const color_order_list = this.state.color_order_list;
      const new_color = {
        color_code: this.state.inputColorCode,
        color_name: this.state.inputColorName,
        color_market_name: this.state.inputColorMarketName,
      };

      color_order_list.push(new_color);
      this.setState({
        color_modal: false,
        color_order_list: color_order_list,
      });
    } else {
      modalError({
        title: "Error Validation",
        content: "Error Validation",
      });
    }
  };

  deleteColorList = (value) => {
    let color_order_list = this.state.color_order_list;
    const index = color_order_list.indexOf(value);
    if (index !== -1) {
      color_order_list.splice(index, 1);
    }
    this.setState({
      color_order_list: color_order_list,
    });
  };

  changeInputDisplayType = (v) => {
    this.setState({
      inputDisplayType: v,
    });
  };

  changeInputDisplayCategory = (v) => {
    this.setState({
      inputDisplayCategory: v,
    });
  };

  changeInputDisplayLink = (v) => {
    this.setState({
      inputDisplayLink: v.target.value,
    });
  };

  changeInputDisplayLinkThumb = (v) => {
    this.setState({
      inputDisplayLinkThumb: v.target.value,
    });
  };

  changeInputDisplayCaption = (v) => {
    this.setState({
      inputDisplayCaption: v.target.value,
    });
  };

  showDisplayModal = async () => {
    this.setState({
      inputDisplayType: "",
      inputDisplayCategory: "",
      inputDisplayCaption: "",
      inputDisplayLink: "",
      inputDisplayLinkThumb: "",
      display_modal: true,
    });
  };

  closeDisplayModal = async () => {
    this.setState({
      inputDisplayType: "",
      inputDisplayCategory: "",
      inputDisplayCaption: "",
      inputDisplayLink: "",
      inputDisplayLinkThumb: "",
      display_modal: false,
    });
  };

  addDisplayList = async () => {
    const display_type = this.state.inputDisplayType;
    const display_category = this.state.inputDisplayCategory;
    const display_caption = this.state.inputDisplayCaption;
    const display_link = this.state.inputDisplayLink;
    const display_link_thumb = this.state.inputDisplayLinkThumb;

    if (display_type !== "" && display_category !== "" && display_caption !== "" && display_link !== "" && display_link_thumb !== "") {
      const display_list = this.state.display_list;
      const new_display = {
        type: display_type,
        category: display_category,
        caption: display_caption,
        url_asset: display_link,
        url_asset_thumbnail: display_link_thumb,
        show: true,
      };

      display_list.push(new_display);
      this.setState({
        display_modal: false,
        display_list: display_list,
      });

      this.closeDisplayModal();
    } else {
      modalError({
        title: "Error Validation",
        content: "Error Validation",
      });
    }
  };

  deleteDisplayList = (value) => {
    let display_list = this.state.display_list;
    const index = display_list.indexOf(value);
    if (index !== -1) {
      display_list.splice(index, 1);
    }
    this.setState({
      display_list: display_list,
    });
  };

  showDistributeModal = async (row) => {
    if (row) {
      const distributeVariantColor = row.color.map((c) => {
        const matchedAsset = row.url_display_assets.find((asset) => asset.caption.includes(c.color_name));

        return {
          color_code: c.color_code,
          color_name: c.color_name,
          price_default: row.price || 0,
          price_strikethrough: 0,
          price_min: row.price || 0,
          price_max: row.price || 0,
          url_image: matchedAsset ? matchedAsset.url_asset : null,
          url_image_thumbnail: matchedAsset ? matchedAsset.url_asset_thumbnail : null,
        };
      });

      this.setState({
        distributeModal: true,
        distributeVariant: row,
        distributeVariantColor: distributeVariantColor,
        distributeVariantColorObj: {},
        distModalLabel: row?.variant_name,
        distDestination: "NONE",
        dataOptionArea: [],
        distArea: "",
        distPriceDefault: row.price,
        distPriceStrikethrough: 0,
        distPriceMin: row.price,
        distPriceMax: row.price,
        distImageUrl: row.url_image || "https://trimitra-catalog.s3.ap-southeast-1.amazonaws.com/vehicle-not-found.png",
        distImageThumbUrl: row.url_image_thumbnail || "https://trimitra-catalog.s3.ap-southeast-1.amazonaws.com/vehicle-not-found.png",
      });
    }
  };

  closeDistributeModal = async () => {
    this.setState({
      distributeModal: false,
      distributeVariant: {},
      distributeVariantColor: [],
      distributeVariantColorObj: {},
      distModalLabel: "",
      distDestination: "NONE",
      dataOptionArea: [],
      distArea: "",
      distPriceDefault: 0,
      distPriceStrikethrough: 0,
      distPriceMin: 0,
      distPriceMax: 0,
      distImageUrl: "",
      distImageThumbUrl: "",
    });
  };

  changeDistDestination = async (v) => {
    await this.setState({
      distDestination: v,
    });
    if (v !== "NONE") {
      await this.fetchDataArea();
    }
  };

  fetchDataArea = async () => {
    try {
      const destination = this.state.distDestination;

      if (destination !== "NONE" && destination !== "") {
        const dataModel = await distributionServices.getArea(destination);

        this.setState({
          dataOptionArea: dataModel.data.data,
        });
      }
    } catch (error) {
      this.setState({
        dataOptionArea: [],
      });
    }
  };

  changeCheckboxVariatPrice = (e) => {
    const checked = e.target.checked;

    const new_color_pricing_list = [];
    const color_pricing_list = this.state.distributeVariantColor;

    const variant_price_default = this.state.distPriceDefault;
    const variant_price_strikethrough = this.state.distPriceStrikethrough;
    const variant_price_min = this.state.distPriceMin;
    const variant_price_max = this.state.distPriceMax;

    for (let i = 0; i < color_pricing_list?.length; i++) {
      const c = color_pricing_list[i];
      if (checked === true) {
        new_color_pricing_list.push({
          color_code: c.color_code,
          color_name: c.color_name,
          price_default: variant_price_default,
          price_strikethrough: variant_price_strikethrough,
          price_min: variant_price_min,
          price_max: variant_price_max,
          url_image: c?.url_image,
          url_image_thumbnail: c?.url_image_thumbnail,
        });
      } else {
        new_color_pricing_list.push({
          color_code: c.color_code,
          color_name: c.color_name,
          price_default: 0,
          price_strikethrough: 0,
          price_min: 0,
          price_max: 0,
          url_image: c.url_image,
          url_image_thumbnail: c.url_image_thumbnail,
        });
      }
    }

    // console.log("new_color_pricing_list", new_color_pricing_list);

    this.setState({
      distributeVariantColor: new_color_pricing_list,
      distCheckboxChecked: checked,
    });
  };

  addColorPrice = (price_type, v, index) => {
    let color_pricing_list = this.state.distributeVariantColor;
    if (color_pricing_list[index]) {
      color_pricing_list[index][price_type] = v?.target?.value;
    }
    // console.log(color_pricing_list[index]);
    this.setState({
      distributeVariantColor: color_pricing_list,
    });
  };

  onDistributeData = async () => {
    try {
      const { distributeVariant, distributeVariantColor, distDestination, distArea, distPriceDefault, distPriceStrikethrough, distPriceMin, distPriceMax, distImageUrl, distImageThumbUrl } = this.state;

      if (Object.keys(distributeVariant).length < 1) {
        throw new Error("Error Validation, No Model Selected");
      }

      if (!distDestination || distDestination === "NONE") {
        throw new Error("Error Validation, Required Distribute Destination");
      }
      if (!distArea) {
        throw new Error("Error Validation, Required Distribute Area");
      }
      if (!distPriceDefault || distPriceDefault < 0) {
        throw new Error("Error Validation, Required Distribute Price Default");
      }
      if (distPriceStrikethrough < 0) {
        throw new Error("Error Validation, Required Distribute Price Strikethrough");
      }
      if (!distPriceMin || distPriceMin < 0) {
        throw new Error("Error Validation, Required Distribute Price Min");
      }
      if (!distPriceMax || distPriceMax < 0) {
        throw new Error("Error Validation, Required Distribute Price Max");
      }
      if (!distImageUrl || distImageUrl === "NONE") {
        throw new Error("Error Validation, Required Distribute Image Url");
      }
      if (!distImageThumbUrl || distImageThumbUrl === "NONE") {
        throw new Error("Error Validation, Required Distribute Image ThumbUrl");
      }

      const distVariantData = {
        company_code: distDestination,
        area_code: distArea,
        brand_uuid: distributeVariant.brand_uuid,
        brand: distributeVariant.brand_name,
        class: distributeVariant.class,
        category: distributeVariant.category,
        model_uuid: distributeVariant.model_uuid,
        name: distributeVariant.model_market_name,
        variant_uuid: distributeVariant.variant_uuid,
        variant_code: distributeVariant.code,
        variant_name: distributeVariant.variant_name,
        variant_distributor_code: distributeVariant.distributor_code,
        price: Number(distPriceDefault),
        price_strikethrough: Number(distPriceStrikethrough),
        price_max: Number(distPriceMin),
        price_min: Number(distPriceMax),
        sorting: 0,
        label: "new",
        url_image: distImageUrl,
        url_thumb_image: distImageThumbUrl,
        url_brochure: "",
        url_video: "",
        url_specification: "",
        highlight: true,
        active: true,
        fuel: distributeVariant.fuel,
        seat: Number(distributeVariant.chairs) > 0 ? distributeVariant.chairs : 0,
        transmission: distributeVariant.transmission,
        engine_capacity: Number(distributeVariant.engine_capacity),
      };

      // await distributionServices.addVariant(distVariantData);

      // const distVariantColorData = [];
      for (const c of distributeVariantColor) {
        const dataTemp = { ...distVariantData };

        if (!c.color_code || !c.color_name || c.price_default < 1 || c.price_strikethrough < 0 || c.price_min < 1 || c.price_max < 1 || c.url_image < 1 || c.url_image_thumbnail < 0) {
          throw new Error("Error Validation, Color Data not completed");
        }

        dataTemp["color_code"] = c.color_code;
        dataTemp["color_name"] = c.color_name;
        dataTemp["url_image"] = c.url_image;
        dataTemp["url_thumb_image"] = c.url_image_thumbnail;
        dataTemp["price"] = Number(c.price_default);
        dataTemp["price_strikethrough"] = Number(c.price_strikethrough);
        dataTemp["price_max"] = Number(c.price_max);
        dataTemp["price_min"] = Number(c.price_min);

        // distVariantColorData.push(dataTemp);

        await distributionServices.addVariantColor(dataTemp);
      }

      Modal.success({
        content: "Success Distribute Data",
      });
      this.closeDistributeModal();
    } catch (error) {
      // console.log(error);

      Modal.error({
        title: "Error Distribute Data",
        content: error?.message,
      });
    }
  };

  changeDistArea = (v) => {
    this.setState({
      distArea: v,
    });
  };

  handleFieldChange = (field, value) => {
    this.setState({ [field]: value });
  };

  render() {
    const rowTableSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          tableSelectedRowKeys: selectedRows,
        });
      },
    };

    return (
      <React.Fragment>
        <LayoutDefault defaultOpenKeys={["sm-master"]} defaultSelectedKeys={["m-master-vehicle-variant"]}>
          <div id="read_data_canvas" className={this.state.showHideReadCanvas}>
            <Form name="form-filter" layout="vertical" className="me-3 pt-1">
              <div className="row mx-0">
                <div className="col-lg-3 px-2 my-0 py-0">
                  <SelectCategory label="Category" value={this.state.filterCategory} onChangeValue={this.changeFilterCategory} />
                </div>
                <div className="col-lg-3 px-2 my-0 py-0">
                  <SelectBrand label="Brand" value={this.state.filterBrand} onChangeValue={this.changeFilterBrand} />
                </div>
                <div className="col-lg-3 px-2 my-0 py-0">
                  <Form.Item label="Model" className="col-lg-12 my-1 py-1">
                    <Select
                      name="model_uuid"
                      style={{ fontSize: "85%", minWidth: "200px" }}
                      placeholder="Select a Model"
                      value={this.state.filterModel}
                      onChange={this.changeFilterModel}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                    >
                      {this.state.dataOptionFilterModel.map((d, i) => {
                        return (
                          <Option key={d.model_uuid} value={d.model_uuid} add={d.market_name}>
                            {d.model_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-lg-3 px-2 my-0 py-0">
                  <Form.Item label="Variant Code" className="col-lg-12 my-1 py-1">
                    <Input value={this.state.filterVariant} onChange={this.changeFilterVariant} placeholder="Variant Code" />
                  </Form.Item>
                </div>
                <div className="col-lg-12 px-2 pb-2">
                  <div className="px-0 mx-0 py-1">
                    <Button
                      onClick={this.onClickFilter}
                      type="primary"
                      icon={<SearchOutlined />}
                      title="filter"
                      className="px-2 me-1"
                      style={{
                        background: "#36383a",
                        borderColor: "#36383a",
                      }}
                      loading={this.state.loadingButton}
                    >
                      {" "}
                      Filter Variant{" "}
                    </Button>
                  </div>
                  <div className="px-0 mx-0 py-1">
                    <Button className="float-end " onClick={this.onClickAddVariant} title="add" type="primary" style={{ background: "#1784b8", borderColor: "#1784b8" }} loading={this.state.loadingButton}>
                      <span>
                        <strong style={{ fontSize: "100%" }}>+&nbsp;</strong> Add Variant
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </Form>

            <Table
              rowSelection={rowTableSelection}
              rowKey={"variant_uuid"}
              size={"small"}
              loading={this.state.processingFetchTableData}
              columns={this.tableColumn}
              dataSource={this.state.dataVariant}
              pagination={{ pageSize: this.state.pageSize }}
              onChange={this.onChangeTable}
            />
          </div>
          <div id="add_edit_canvas" className={this.state.showHideFormCanvas}>
            {/* <h4 style={{ textTransform: "capitalize" }}>{this.state.titleCanvas}</h4>
            <hr /> */}
            <div className="code-box-title my-2 pb-2">{/* <strong>{this.state.titleCanvas}</strong> */}</div>
            <Form layout="vertical" labelCol={{ span: 12 }} wrapperCol={{ span: 24 }} ref={this.formInput}>
              <div className="row mx-0">
                <div className="col-lg-6 mb-3">
                  <div className="code-box-title  border-bottom border-secondary">
                    <strong>Detail Variant</strong>
                  </div>
                  <div className="row mx-0">
                    <div className="col-lg-6">
                      <Form.Item label="Variant Name" className="my-1 py-1">
                        <Input name="variant_name" value={this.state.inputValueVariantName} onChange={this.changeInputValueVariantName} placeholder="Variant Name" disabled={this.state.formEdit} />
                      </Form.Item>
                      <Form.Item label="Variant Code" className="my-1 py-1">
                        <Input name="code" value={this.state.inputValueVariantCode} onChange={this.changeInputValueVariantCode} placeholder="Variant Code" disabled={this.state.formEdit} />
                      </Form.Item>
                      <Form.Item label="Variant Code Vin" className="my-1 py-1">
                        <Input name="code_vin" value={this.state.inputValueVariantCodeVin} onChange={this.changeInputValueVariantCodeVin} placeholder="Variant Code Vin" />
                      </Form.Item>
                      <Form.Item label="Market Name" className="my-1 py-1">
                        <Input name="market_name" value={this.state.inputValueMarketName} onChange={this.changeInputValueMarketName} placeholder="Market Name" />
                      </Form.Item>
                      <Form.Item label="Series Name" className="my-1 py-1">
                        <Input name="series_name" value={this.state.inputValueSeriesName} onChange={this.changeInputValueSeriesName} placeholder="Series Name" />
                      </Form.Item>
                      <Form.Item label="Distributor Code" className="my-1 py-1">
                        <Input name="distributor_code" value={this.state.inputValueDistributorCode} onChange={this.changeInputValueDistributorCode} placeholder="Distributor Code" />
                      </Form.Item>
                    </div>
                    <div className="col-lg-6">
                      <SelectCategory value={this.state.inputValueCategory} onChangeValue={this.changeInputValueCategory} disabled={this.state.formEdit} />
                      <SelectClass value={this.state.inputValueClass} onChangeValue={this.changeInputValueClass} disabled={this.state.formEdit} />
                      <SelectBrand value={this.state.inputValueBrandUUID} onChangeValue={this.changeInputValueBrand} disabled={this.state.formEdit} />
                      <Form.Item label="Model" name="model_uuid" className="col-lg-12 my-1 py-1">
                        <Select name="model_uuid" style={{ fontSize: "85%", minWidth: "200px" }} placeholder="Select a Model" onChange={this.changeInputValueModel} disabled={this.state.formEdit}>
                          {this.state.dataOptionModel.map((d, i) => {
                            return (
                              <Option key={d.model_uuid} value={d.model_uuid} add={d.market_name}>
                                {d.model_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                      <SelectDriveType value={this.state.inputValueDriveType} onChangeValue={this.changeInputValueDriveType}></SelectDriveType>
                      <Form.Item label="Doors" className="my-1 py-1">
                        <Input type="number" name="doors" value={this.state.inputValueDoors} onChange={this.changeInputValueDoors} placeholder="Total Doors" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row mx-0">
                    <div className="col-lg-6">
                      <SelectActive value={this.state.inputValueStatus} onChangeValue={this.changeInputValueStatus}></SelectActive>
                    </div>
                    <div className="col-lg-6">
                      <SelectChairs value={this.state.inputValueChairs} onChangeValue={this.changeInputValueChairs} mode="multiple" />
                    </div>
                  </div>

                  <div className="row mx-0">
                    <div className="col-lg-6">
                      <Form.Item label="Link Info" className="my-1 py-1">
                        <Input name="url_info" value={this.state.inputValueUrlInfo} onChange={this.changeInputUrlInfo} placeholder="Link Wiki" />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="row mx-0">
                    <div className="col-lg-12">
                      <EditorAbout value={this.state.inputValueAbout} onChange={this.changeInputValueAbout}></EditorAbout>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 mb-3">
                  <div className="row mx-0">
                    <div className="code-box-title  border-bottom border-secondary">
                      <strong>Engine Detail</strong>
                    </div>
                    <div className="row mx-0">
                      <div className="col-lg-6">
                        <Form.Item label="Engine Capacity" className="my-1">
                          <Input type="number" name="engine_capacity" value={this.state.inputValueEngineCapacity} onChange={this.changeInputValueEngineCapacity} placeholder="Engine Capacity" />
                        </Form.Item>
                      </div>
                      <div className="col-lg-6">
                        <Form.Item label="Engine Code" className="my-1">
                          <Input name="engine_code" value={this.state.inputValueEngineCode} onChange={this.changeInputValueEngineCode} placeholder="Engine Code" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row mx-0">
                      <div className="col-lg-6">
                        <Form.Item label="Engine HP" className="my-1 ">
                          <Input type="number" name="engine_hp" value={this.state.inputValueEngineHP} onChange={this.changeInputValueEngineHP} placeholder="Engine HP" />
                        </Form.Item>
                      </div>
                      <div className="col-lg-6">
                        <Form.Item label="Engine Torque" className="my-1 ">
                          <Input type="number" name="engine_torque" value={this.state.inputValueEngineTorque} onChange={this.changeInputValueEngineTorque} placeholder="Engine Torque" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row mx-0">
                      <div className="col-lg-12">
                        <SelectValveConfig value={this.state.inputValueEngineValveConfig} onChangeValue={this.changeInputValueEngineValveConfig} />
                      </div>
                    </div>
                    <div className="row mx-0">
                      <div className="col-lg-6">
                        <Form.Item label="Engine Valve" className="my-1">
                          <Input type="number" name="engine_valve" value={this.state.inputValueEngineValve} onChange={this.changeInputValueEngineValve} placeholder="Engine Valve" />
                        </Form.Item>
                      </div>
                      <div className="col-lg-6">
                        <Form.Item label="Engine Cylinder" className="my-1">
                          <Input type="number" name="engine_cylinder" value={this.state.inputValueEngineCylinder} onChange={this.changeInputValueEngineCylinder} placeholder="Engine Cylinder" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  <div className="row mx-0 mt-4">
                    <div className="code-box-title  border-bottom border-secondary">
                      <strong>Fuel & Transmission Detail</strong>
                    </div>
                    <div className="row mx-0">
                      <div className="col-lg-6">
                        <SelectFuel value={this.state.inputValueFuel} onChangeValue={this.changeInputValueFuel} />
                      </div>
                      <div className="col-lg-6">
                        <SelectFuelSupply value={this.state.inputValueFuelSupplySystem} onChangeValue={this.changeInputValueFuelSupplySystem} />
                      </div>
                    </div>

                    <div className="row mx-0">
                      <div className="col-lg-6">
                        <SelectTransmission value={this.state.inputValueTransmission} onChangeValue={this.changeInputValueTransmission} />
                      </div>
                      <div className="col-lg-6">
                        <Form.Item label="Transmission Speed" className="my-1 ">
                          <Input type="number" name="transmission_speed" value={this.state.inputValueTransmissionSpeed} onChange={this.changeInputValueTransmissionSpeed} placeholder="Transmission Speed" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  <div className="row mx-0 mt-4 ">
                    <div className="code-box-title  border-bottom border-secondary">
                      <strong>Vehicle Color</strong>
                    </div>
                    <div className="row mx-0 px-0" style={{ minHeight: 120, width: "100%" }}>
                      <div className="col-lg-12 px-0">
                        <Button
                          // onClick={this.addColorList}
                          onClick={this.showColorModal}
                          className="mt-2"
                          type="primary"
                          icon={<PlusSquareOutlined />}
                        >
                          Add Color
                        </Button>
                        <div className="row mx-0 mt-2">
                          <table id="color_list" className=" col-lg-12 px-1 table-color">
                            <tr>
                              <th className="text-center">Color Code</th>
                              <th className="text-center">Color Name</th>
                              <th className="text-center">Color Market Name</th>
                              <th className="text-center"></th>
                            </tr>
                            {this.state.color_order_list.map((r) => (
                              <tr id={r} key={r}>
                                <td className="px-2">{r.color_code}</td>
                                <td className="px-2">{r.color_name}</td>
                                <td className="px-2">{r.color_market_name}</td>
                                <td className="text-center">
                                  <Button onClick={() => this.deleteColorList(r)} type="danger" icon={<DeleteOutlined />} className="px-2 me-1 my-1" />
                                </td>
                              </tr>
                            ))}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mx-0 my-2">
                    <div className="code-box-title  border-bottom border-secondary">
                      <strong>Other Display</strong>
                    </div>
                    <div className="row mx-0 px-0" style={{ minHeight: 120, width: "100%" }}>
                      <div className="col-lg-12 px-0">
                        <Button
                          // onClick={this.addColorList}
                          onClick={this.showDisplayModal}
                          className="mt-2"
                          type="primary"
                          icon={<PlusSquareOutlined />}
                        >
                          Add Display
                        </Button>
                        <div className="row mx-0 mt-2 ">
                          <table id="display_list" className=" col-lg-12 px-1 table-color">
                            <tr>
                              <th className="text-center">Type</th>
                              <th className="text-center">Category Display</th>
                              <th className="text-center">Link Display</th>
                              <th className="text-center">Link Display Thumb</th>
                              <th className="text-center">Caption Display</th>
                              <th className="text-center"></th>
                            </tr>
                            {this.state.display_list.map((r, index) => (
                              <tr id={index} key={index}>
                                <td className="px-2 py-0">{r.type}</td>
                                <td className="px-2 py-0">{r.category}</td>
                                <td className="px-2 py-0">
                                  {r.url_asset !== "" && (
                                    <a href={r.url_asset} target="_blank" rel="noreferrer">
                                      Open Link
                                    </a>
                                  )}
                                </td>
                                <td className="px-2 py-0">
                                  {r.url_asset_thumbnail !== "" && (
                                    <a href={r.url_asset_thumbnail} target="_blank" rel="noreferrer">
                                      Open Link
                                    </a>
                                  )}
                                </td>
                                <td className="px-2 py-0">{r.caption}</td>
                                <td className="text-center">
                                  <Button onClick={() => this.deleteDisplayList(r)} type="danger" icon={<DeleteOutlined />} className="px-2 me-1 my-1" />
                                </td>
                              </tr>
                            ))}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mx-0 ">
                <div className="col-lg-12">
                  <div className="row mx-0 mt-3">
                    <div className="col-lg-12">
                      <Button onClick={this.onClickSave} className="mb-2" type="primary" loading={this.state.loadingButton}>
                        Save Variant
                      </Button>
                      <Button onClick={this.onClickCancel} type="danger" className="mb-2 mx-1" loading={this.state.loadingButton}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>

            <Modal title="Add Color" visible={this.state.color_modal} onOk={this.addColorList} onCancel={this.closeColorModal}>
              <Form layout="horizontal" labelCol={{ span: 6 }}>
                <Form.Item label="Color Code" className="my-1 py-1">
                  <Input type="text" value={this.state.inputColorCode} onChange={this.changeInputColorCode} placeholder="Color Code" />
                </Form.Item>
                <Form.Item label="Color Name" className="my-1 py-1">
                  <Input type="text" value={this.state.inputColorName} onChange={this.changeInputColorName} placeholder="Color Name" />
                </Form.Item>
                <Form.Item label="Market Name" className="my-1 py-1">
                  <Input type="text" value={this.state.inputColorMarketName} onChange={this.changeInputColorMarketName} placeholder="Color Market Name" />
                </Form.Item>
              </Form>
            </Modal>

            <Modal title="Add Display" visible={this.state.display_modal} onOk={this.addDisplayList} onCancel={this.closeDisplayModal}>
              <div className="row mx-0">
                <div className="col-lg-12 ">
                  <Form layout="vertical">
                    <SelectDisplayType value={this.state.inputDisplayType} onChangeValue={this.changeInputDisplayType} />
                    <SelectDisplayCategory value={this.state.inputDisplayCategory} onChangeValue={this.changeInputDisplayCategory} />
                    <Form.Item label="Link Display" className="my-1 py-1">
                      <Input name="link_display" value={this.state.inputDisplayLink} onChange={this.changeInputDisplayLink} placeholder="Link Image" />
                    </Form.Item>
                    <Form.Item label="Link Display Thumbnail" className="my-1 py-1">
                      <Input name="link_display_thumbnail" value={this.state.inputDisplayLinkThumb} onChange={this.changeInputDisplayLinkThumb} placeholder="Link Image" />
                    </Form.Item>
                    <Form.Item label="Caption Display" className="my-1 py-1">
                      <TextArea value={this.state.inputDisplayCaption} onChange={this.changeInputDisplayCaption}></TextArea>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </Modal>
          </div>
          <Modal title={`Distribute Variant ${this.state.distModalLabel?.toUpperCase()}`} visible={this.state.distributeModal} onOk={this.onDistributeData} onCancel={this.closeDistributeModal} width={1000}>
            <Form layout="vertical">
              <div className="row mx-0 px-0">
                <div className="row mx-0">
                  <div className="col-lg-6 mx-0 ps-0">
                    <SelectDistributionDestination value={this.state.distDestination} onChangeValue={this.changeDistDestination} />
                  </div>
                  <div className="col-lg-6 mx-0 ps-0">
                    <Form.Item label="Area" className="col-lg-12 my-1 py-1">
                      <Select style={{ fontSize: "85%" }} placeholder="Select an Area" onChange={this.changeDistArea} value={this.state.distArea}>
                        {this.state.dataOptionArea.map((d, i) => {
                          return (
                            <Select.Option key={d.area} value={d.area} add={d.area}>
                              {d.area}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="row mx-0">
                  <div className="col-lg-6 mx-0 ps-0">
                    <Form.Item label="Price Default" className="my-1 py-0">
                      <Input type="number" value={this.state.distPriceDefault} onChange={(event) => this.handleFieldChange("distPriceDefault", event.target.value)} placeholder="Price Default" />
                    </Form.Item>
                  </div>
                  <div className="col-lg-6 mx-0 ps-0">
                    <Form.Item label="Price Strikethrough" className="my-1 py-0">
                      <Input type="number" value={this.state.distPriceStrikethrough} onChange={(event) => this.handleFieldChange("distPriceStrikethrough", event.target.value)} placeholder="Price Strikethrough" />
                    </Form.Item>
                  </div>
                </div>
                <div className="row mx-0">
                  <div className="col-lg-6 mx-0 ps-0">
                    <Form.Item label="Price Min" className="my-1 py-0">
                      <Input type="number" value={this.state.distPriceMin} onChange={(event) => this.handleFieldChange("distPriceMin", event.target.value)} placeholder="Price Min" />
                    </Form.Item>
                  </div>
                  <div className="col-lg-6 mx-0 ps-0">
                    <Form.Item label="Price Max" className="my-1 py-0">
                      <Input type="number" value={this.state.distPriceMax} onChange={(event) => this.handleFieldChange("distPriceMax", event.target.value)} placeholder="Price Max" />
                    </Form.Item>
                  </div>
                </div>
                <div className="row mx-0">
                  <div className="col-lg-12 mx-0 ps-0">
                    <Form.Item label="Image Url" className="my-1 py-0">
                      <Input value={this.state.distImageUrl} onChange={(event) => this.handleFieldChange("distImageUrl", event.target.value)} />
                    </Form.Item>
                  </div>
                  <div className="col-lg-12 mx-0 ps-0">
                    <Form.Item label="Image Url Thumbnail" className="my-1 py-0">
                      <Input value={this.state.distImageThumbUrl} onChange={(event) => this.handleFieldChange("distImageThumbUrl", event.target.value)} />
                    </Form.Item>
                  </div>
                </div>
                <div className="row mx-0 px-0">
                  <div className="col-lg-12">
                    <div className="code-box-title  border-bottom border-secondary my-3">
                      <strong>Variant Color Price</strong>
                    </div>

                    <Checkbox onChange={this.changeCheckboxVariatPrice} checked={this.state.distCheckboxChecked}>
                      <strong>Same as Variant Price</strong>
                    </Checkbox>

                    <div className="row mx-0 mt-2 ">
                      <table id="color_list" className=" col-lg-12 px-1 table-color">
                        <tr>
                          <th className="text-center">&nbsp;Color&nbsp;Code&nbsp;</th>
                          <th className="text-center">&nbsp;Color&nbsp;Name&nbsp;</th>
                          <th className="text-center">Price Default</th>
                          <th className="text-center">Price Strikethrough</th>
                          <th className="text-center">Price Min</th>
                          <th className="text-center">Price Max</th>
                          <th className="text-center">URL Color Image</th>
                          <th className="text-center">URL Color Image Thumb</th>
                          <th className="text-center">Preview Image</th>
                        </tr>
                        {this.state.distributeVariantColor.map((r, index) => (
                          <tr id={index} key={index}>
                            <td className="px-2 py-0">{r.color_code}</td>
                            <td className="px-2 py-0">{r.color_name}</td>
                            <td>
                              <Form.Item className="mx-1 px-1 pt-1 pb-0 mb-1">
                                <Input type="number" onChange={(e) => this.addColorPrice("price_default", e, index)} value={r.price_default} placeholder="Price Default" />
                              </Form.Item>
                            </td>
                            <td>
                              <Form.Item className="mx-1 px-1 pt-1 pb-0 mb-1">
                                <Input type="number" onChange={(e) => this.addColorPrice("price_strikethrough", e, index)} value={r.price_strikethrough} placeholder="Price Strikethrough" />
                              </Form.Item>
                            </td>
                            <td>
                              <Form.Item className="mx-1 px-1 pt-1 pb-0 mb-1">
                                <Input type="number" onChange={(e) => this.addColorPrice("price_min", e, index)} value={r.price_min} placeholder="Price Min" />
                              </Form.Item>
                            </td>
                            <td>
                              <Form.Item className="mx-1 px-1 pt-1 pb-0 mb-1">
                                <Input type="number" onChange={(e) => this.addColorPrice("price_max", e, index)} value={r.price_max} placeholder="Price Max" />
                              </Form.Item>
                            </td>
                            <td>
                              <Form.Item className="mx-1 px-1 pt-1 pb-0 mb-1">
                                <Input onChange={(e) => this.addColorPrice("url_image", e, index)} value={r.url_image} placeholder="URL Image" />
                              </Form.Item>
                            </td>
                            <td>
                              <Form.Item className="mx-1 px-1 pt-1 pb-0 mb-1">
                                <Input onChange={(e) => this.addColorPrice("url_image_thumbnail", e, index)} value={r.url_image_thumbnail} placeholder="URL Image" />
                              </Form.Item>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {!r.url_image_thumbnail ? (
                                <span>No Image</span> // Display "NONE" if both URLs are empty
                              ) : (
                                <Image
                                  src={r.url_image_thumbnail}
                                  preview={true} // Enable image preview
                                  style={{
                                    width: "30px", // Adjust image size
                                    height: "30px",
                                    display: "block",
                                  }}
                                  alt="Show Image"
                                />
                              )}
                            </td>
                          </tr>
                        ))}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </Modal>
        </LayoutDefault>
      </React.Fragment>
    );
  }
}

export default MasterVehicleVariant;
