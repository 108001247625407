import React, { Component } from "react";
import Cookies from "universal-cookie";
import LayoutDefault from "../../layout/layout-default.component";

import FilterSelectCategory from "../../layout/filter-select-category.component";
import FilterSelectPartType from "../../layout/filter-select-part-type.component";

import { vehiclePartService } from "../../../services/vehicle-part.services";
import { modalError } from "../../../helpers/modal.helper";

import { Form, Input, Table, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";

class MasterVehiclePart extends Component {
  cookies = new Cookies();

  constructor(props) {
    super(props);

    this.initState = {
      processing: false,
      processingFetchTableData: false,

      pageSize: 100,
      lastFilteredUUID: "",
      filterPartNumber: "",
      filterCategory: "",
      filterPartType: "",
      filterVariantCode: "",

      tableSelectedRowKeys: [],
      dataPart: [],
      titleCanvas: "Default Title",

      loadingButton: false,
      showHideReadCanvas: "d-block",
      showHideFormCanvas: "m-2 px-1 d-none",
      showHideButtonForm: "me-1",
      formEdit: false,
    };

    this.state = { ...this.initState };
  }

  tableColumn = [
    {
      title: "Part UUID",
      dataIndex: "uuid",
      key: "uuid",
    },
    {
      title: "Part Number",
      dataIndex: "part_number",
      key: "part_number",
    },
    {
      title: "Part Name",
      dataIndex: "part_name",
      key: "part_name",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Part Type",
      dataIndex: "part_type",
      key: "part_type",
    },
    {
      title: "Part Type Market Name",
      dataIndex: "part_type_market_name",
      key: "part_type_market_name",
    },
    {
      title: "Part Maker Name",
      dataIndex: "part_maker_name",
      key: "part_maker_name",
    },
    {
      title: "Part Maker Image",
      dataIndex: "part_maker_image",
      key: "part_maker_image",
      render: (text, record) => {
        return (
          <span>
            <a style={{ display: "table-cell", color: "#1890ff" }} href={record.part_maker_image} target="_blank" rel="noreferrer">
              Link Image
            </a>
          </span>
        );
      },
    },
    {
      title: "Qty",
      dataIndex: "part_qty",
      key: "part_qty",
    },
    {
      title: "Brand Name",
      dataIndex: "brand_name",
      key: "brand_name",
    },
    {
      title: "Model Name",
      dataIndex: "model_name",
      key: "model_name",
    },
    {
      title: "Variant Code",
      dataIndex: "variant_code",
      key: "variant_code",
    },
    {
      title: "Variant Name",
      dataIndex: "variant_name",
      key: "variant_name",
    },
    {
      title: "Price Default",
      dataIndex: "price_default",
      key: "price_default",
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
      render: (text, record) => {
        return (
          <span>
            {record?.weight} {record?.weight_type}
          </span>
        );
      },
    },
    {
      title: "Dimension",
      dataIndex: "dimension",
      key: "dimension",
      render: (text, record) => {
        return (
          <span>
            {record?.dimension?.length}x{record?.dimension?.width}x{record?.dimension?.height}
          </span>
        );
      },
    },
    {
      title: "Part Color",
      dataIndex: "part_color_text",
      key: "part_color_text",
    },
    {
      title: "Part Image",
      dataIndex: "url_display_assets",
      key: "url_display_assets",
      render: (text, record) => {
        if (record?.url_display_assets?.length > 0) {
          return (
            <span>
              <a style={{ display: "table-cell", color: "#1890ff" }} href={"https://trimitra-cdn.s3.ap-southeast-1.amazonaws.com/part_image/" + record.part_number + "/" + record?.url_display_assets[0]?.url_asset} target="_blank" rel="noreferrer">
                Image
              </a>
            </span>
          );
        } else {
          return "-";
        }
      },
    },
  ];

  changeFilterPartNumber = (v) => {
    this.setState({
      filterPartNumber: v.target.value,
    });
  };

  changeFilterVariantCode = (v) => {
    this.setState({
      filterVariantCode: v.target.value,
    });
  };

  changeFilterCategory = (v) => {
    this.setState({
      filterCategory: v,
    });
  };

  changeFilterPartType = (v) => {
    this.setState({
      filterPartType: v,
    });
  };

  fetchDataPart = async () => {
    try {
      const filter = this.state;

      if ((!filter.filterCategory || filter.filterCategory === "0") && (!filter.filterPartType || filter.filterPartType === "0") && !filter.filterVariantCode && !filter.filterPartNumber) {
        modalError({
          title: "Validation Error",
          content: "Filter Not Valid",
        });
        return false;
      }

      if ((!filter.filterCategory || filter.filterCategory === "0" || !filter.filterPartType || filter.filterPartType === "0") && !filter.filterVariantCode && !filter.filterPartNumber) {
        modalError({
          title: "Validation Error",
          content: "Filter Not Valid",
        });
        return false;
      }

      this.setState({
        processingFetchTableData: true,
        loadingButton: true,
      });

      const params = {};

      if (filter.filterCategory !== "" && filter.filterCategory !== "0") {
        params.category = filter.filterCategory;
      }
      if (filter.filterPartType !== "" && filter.filterPartType !== "0") {
        params.part_type_uuid = filter.filterPartType;
      }
      if (filter.filterVariantCode !== "") {
        params.variant_code = filter.filterVariantCode;
      }
      if (filter.filterPartNumber !== "") {
        params.part_number = filter.filterPartNumber;
      }
      if (this.state.lastFilteredUUID !== "") {
        params.last_uuid = this.state.lastFilteredUUID;
      }

      const token = this.cookies.get("_t_autotrimitrabiz");
      await vehiclePartService.setToken(token);
      const dataPart = await vehiclePartService.getPartList(params);

      this.setState({
        dataPart: dataPart.data.data,
        lastFilteredUUID: dataPart.data.last_uuid,
        processingFetchTableData: false,
        loadingButton: false,
        tableSelectedRowKeys: [],
      });
    } catch (error) {
      this.setState({
        dataPart: [],
        lastFilteredUUID: "",
        processingFetchTableData: false,
        loadingButton: false,
        tableSelectedRowKeys: [],
      });
    }
  };

  onClickFilter = async () => {
    await this.fetchDataPart();
  };

  onClickCancel = async () => {
    this.setState({
      inputPartType: "",
      inputCategory: "",
      inputActive: "",
      showHideReadCanvas: "d-block",
      showHideFormCanvas: "m-2 px-1 d-none",
      loadingButton: false,
    });
  };

  render() {
    const rowTableSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          tableSelectedRowKeys: selectedRows,
        });
      },
    };

    return (
      <React.Fragment>
        <LayoutDefault defaultOpenKeys={["sm-master"]} defaultSelectedKeys={["m-master-vehicle-part"]}>
          <div id="read_data_canvas" className={this.state.showHideReadCanvas}>
            <div className="row mx-0">
              <div className="col-lg-7">
                <Form name="form-filter" layout="vertical" className="me-3 pt-2">
                  <div className="row px-2">
                    <div className="col-lg-3 col-11 px-1">
                      <FilterSelectCategory value={this.state.filterCategory} onChangeValue={this.changeFilterCategory} />
                    </div>
                    <div className="col-lg-3 col-11 px-1">
                      <FilterSelectPartType value={this.state.filterPartType} onChangeValue={this.changeFilterPartType} />
                    </div>
                    <div className="col-lg-2 col-11 px-1">
                      <Form.Item label="Variant Code" name="variant_code" className="col-lg-12 mb-1">
                        <Input onChange={this.changeFilterVariantCode} placeholder="Variant Code" />
                      </Form.Item>
                    </div>
                    <div className="col-lg-2 col-11 px-1">
                      <Form.Item label="Part Number" name="part_number" className="col-lg-12 mb-1">
                        <Input onChange={this.changeFilterPartNumber} placeholder="Part Number" />
                      </Form.Item>
                    </div>
                    <div className="col-lg-1 col-1 px-0">
                      <Form.Item label=" ">
                        <Button
                          onClick={this.onClickFilter}
                          type="primary"
                          icon={<SearchOutlined />}
                          title="filter"
                          className="px-2 me-1 mb-1"
                          style={{
                            background: "#36383a",
                            borderColor: "#36383a",
                            width: "40px",
                          }}
                          loading={this.state.loadingButton}
                        ></Button>
                      </Form.Item>
                    </div>
                  </div>
                </Form>
              </div>
            </div>

            <Table
              rowSelection={rowTableSelection}
              // rowKey={"part_uuid"}
              size={"small"}
              loading={this.state.processingFetchTableData}
              columns={this.tableColumn}
              dataSource={this.state.dataPart}
              pagination={{
                pageSize: this.state.pageSize,
              }}
            />
          </div>
        </LayoutDefault>
      </React.Fragment>
    );
  }
}

export default MasterVehiclePart;
