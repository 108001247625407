import React, { Component } from "react";
import Cookies from "universal-cookie";

// import { div, div, Col } from "react-bootstrap";
import { Layout, Form, Input, Button } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

import { modalError } from "../../../helpers/modal.helper";
import { authServices } from "../../../services/auth.services";
import { config as generalConfig } from "../../../config/general.config";

const { Footer } = Layout;

class Login extends Component {
  cookies = new Cookies();

  state = {
    username: "",
    password: "",
    loadingButton: false,
  };

  handleFieldChange = (target, value) => {
    const currentState = { ...this.state };
    switch (target) {
      case "username":
        currentState.username = value;
        break;
      case "password":
        currentState.password = value;
        break;
      default:
        break;
    }
    this.setState({
      ...currentState,
    });
  };

  onLogin = async () => {
    try {
      const { username, password } = this.state;
      if (!username) {
        modalError({ title: "Validation Error", content: "Invalid Username " });
        return false;
      }
      if (!password) {
        modalError({ title: "Validation Error", content: "Invalid Password " });
        return false;
      }
      this.setState({
        loadingButton: true,
      });
      const login = await authServices.login({ username, password });
      const loginData = login.data.data;

      this.cookies.set("_t_autotrimitrabiz", loginData.token);
      this.cookies.set("_u_autotrimitrabiz", btoa(loginData.username));

      authServices.setToken(loginData.token);
      this.props.history.push("/dashboard");
    } catch (error) {
      this.setState({
        loadingButton: false,
      });
      modalError({ title: "Login Failed", content: error.message });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="bg-secondary text-white">
          <div className="d-flex justify-content-center align-items-center" style={{ height: "90vh" }}>
            <div className="col-lg-3">
              <div>
                <div className="col-12">
                  <h1 className="text-white">
                    <img src="https://ideal-trimitra.web.app/favicon.ico" className="me-2" style={{ width: "40px" }} alt="" />
                    Autotrimitra
                  </h1>
                </div>
              </div>
              <div>
                <div className="col-12">
                  <Form name="form-login" layout="vertical">
                    <Form.Item
                      label="Username"
                      name="username"
                      // initialValue={this.state.username}
                    >
                      <Input onChange={(event) => this.handleFieldChange("username", event.target.value)} placeholder="username" prefix={<UserOutlined />} />
                    </Form.Item>
                    <Form.Item
                      label="Password"
                      name="password"
                      // initialValue={this.state.password}
                    >
                      <Input.Password onChange={(event) => this.handleFieldChange("password", event.target.value)} placeholder="Password" prefix={<LockOutlined />} />
                    </Form.Item>
                    <Form.Item>
                      <Button onClick={this.onLogin} type="primary" className="px-4" loading={this.state.loadingButton}>
                        Login
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          <Footer className="bg-secondary text-white">
            <center>
              Autotrimitra ©2022
              <br />
              version-{generalConfig.version}
            </center>
          </Footer>
        </div>
      </React.Fragment>
    );
  }
}

export default Login;
