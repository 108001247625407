import axios from "axios";
import { apikeyAutotrimitraBizApi, baseUrlAutotrimitraBizApi } from "../config/api-url.config";

class VehicleServices {
  axios = axios.create({
    baseURL: baseUrlAutotrimitraBizApi,
  });

  async getBrandList(params = {}) {
    try {

      const queries = {
        key: apikeyAutotrimitraBizApi,
        ...(params?.brand_name && {
          brand_name: params.brand_name,
        }),
      }

      return await this.axios.get("vehicle/brand", {
        params: {
          ...queries,
        }
      });
    } catch (e) {
      const { response } = e;
      // console.log(response);
      const { request, ...errorObject } = response;
      throw new Error(errorObject.data.error.message);
    }
  }

  async getModelList(params = {}) {
    try {

      const queries = {
        key: apikeyAutotrimitraBizApi,
        ...(params?.category && {
          category: params.category,
        }),
        ...(params?.class && {
          class: params.class,
        }),
        ...(params?.brand_uuid && {
          brand_uuid: params.brand_uuid,
        }),
        ...(params?.model_uuid && {
          model_uuid: params.model_uuid,
        }),
      }

      return await this.axios.get("vehicle/model", {
        params: {
          ...queries,
        }
      });
    } catch (e) {
      const { response } = e;
      const { request, ...errorObject } = response;
      throw new Error(errorObject.data.error.message);
    }
  }

  async getVariantlList(params = {}) {
    try {
      // console.log(params);

      const queries = {
        key: apikeyAutotrimitraBizApi,
        ...(params?.model_uuid && {
          model_uuid: params.model_uuid,
        }),
        ...(params?.code && {
          code: params.code,
        }),
        ...(params?.brand_uuid && {
          brand_uuid: params.brand_uuid,
        }),
        ...(params?.variant_uuid && {
          variant_uuid: params.variant_uuid,
        }),

      }

      return await this.axios.get("vehicle/variant", {
        params: {
          ...queries,
        }
      });
    } catch (e) {
      const { response } = e;
      // console.log(response);
      const { request, ...errorObject } = response;
      throw new Error(errorObject.data.error.message);
    }
  }


}

export const vehicleServices = new VehicleServices();
