import React, { Component } from "react";
import Cookies from "universal-cookie";
import { v4 as uuidv4 } from "uuid";
import LayoutDefault from "../../layout/layout-default.component";

import SelectBrand from "../../layout/select-brand.component";
import SelectCategory from "../../layout/select-category.component";
import SelectClass from "../../layout/select-class.component";
import SelectFuel from "../../layout/select-fuel.component";
import SelectFuelSupply from "../../layout/select-fuel-supply-system.component";
import SelectTransmission from "../../layout/select-transmission.component";
import SelectValveConfig from "../../layout/select-valve-config.component";
import SelectActive from "../../layout/select-active.component";
import SelectDriveType from "../../layout/select-drive-type.component";
import SelectDisplayType from "../../layout/select-display-type.component";
import SelectDisplayCategory from "../../layout/select-display-category.component";
import SelectTemplateImage from "../../layout/select-template-image.component";
import SelectDistributionDestination from "../../layout/select-destination-distribution";
import EditorAbout from "../../layout/editor-about.component";

import { vehicleServices } from "../../../services/vehicle.services";
import { vehicleServicesPost } from "../../../services/vehicle-post.services";
import { distributionServices } from "../../../services/distribution.services";
import { launch } from "../../../services/launch.services";
import { generateImage } from "../../../services/generate-image.services";
import { modalError, modalSuccess } from "../../../helpers/modal.helper";

import NoCrop from "../../image/NoCrop";

import { Form, Input, Button, Select, Modal, Typography, Spin, Tabs, Checkbox } from "antd";
import { DeleteOutlined, PlusSquareOutlined } from "@ant-design/icons";
import SelectChairs from "../../layout/select-chairs.component";

const { Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

class MasterVehicleVariant extends Component {
  cookies = new Cookies();

  constructor(props) {
    super(props);

    this.initState = {
      dataOptionModel: [],
      titleCanvas: "Launch Vehicle Variant",

      inputVariantUUID: "",
      inputValueVariantName: "",
      inputValueVariantCode: "",
      inputValueVariantCodeVin: "",

      inputValueMarketName: "",
      inputValueSeriesName: "",
      inputValueDistributorCode: "",

      inputValueCategory: "",
      inputValueClass: "",
      inputValueBrandUUID: "",
      inputValueBrandName: "",

      inputValueModelUuid: "",
      inputValueModelName: "",
      inputValueModelMarketName: "",

      inputValueUrlInfo: "",

      inputValueEngineCapacity: "",
      inputValueEngineCode: "",
      inputValueEngineHP: "",
      inputValueEngineTorque: "",
      inputValueEngineValveConfig: "",
      inputValueEngineCylinder: "",
      inputValueEngineValve: "",

      inputValueFuel: "",
      inputValueFuelSupplySystem: "",
      inputValueTransmission: "",
      inputValueTransmissionSpeed: "",
      inputValueStatus: "true",
      inputValueDriveType: "",
      inputValueDoors: "",
      inputValueChairs: [],
      inputValueAbout: "",

      inputBackgroundImageCode: "",
      inputLeftTopImageCode: "",
      inputRightTopImageCode: "",
      inputRightBottomImageCode: "",
      inputLeftBottomImageCode: "",

      inputTemplateImage: "",

      // inputAddMarginLeft: "",
      // inputAddMarginRight: "",
      // inputAddMarginTop: "",
      // inputAddMarginBottom: "",

      loadingButton: false,
      showHideButtonForm: "me-1",
      showHideFormCanvas: "m-2 px-1 d-block",
      showHideSpiner: "d-none",
      formEdit: false,
      disableInput: "",

      color_list: [],
      color_modal: false,

      display_list: [],
      display_modal: false,

      dataOptionArea: [],
      pricing_list: [],
      pricing_modal: false,
      color_pricing_list: [],
      checkboxChecked: false,
      inputAreaDistribution: "",
      inputDistributionDestination: "none",
      inputModelPriceDefault: "",
      inputModelPriceStrikethrough: 0,
      inputModelPriceMin: "",
      inputModelPriceMax: "",

      inputVariantPriceDefault: "",
      inputVariantPriceStrikethrough: 0,
      inputVariantPriceMin: "",
      inputVariantPriceMax: "",

      inputColorCode: "",
      inputColorName: "",
      inputColorMarketName: "",

      inputDisplayCategory: "",
      inputDisplayType: "",
      inputDisplayCaption: "",

      baseRawVariantFront: "",
      baseRawVariantFrontThumb: "",

      baseRawVariantBack: "",
      baseRawVariantBackThumb: "",

      baseRawVariantLeft: "",
      baseRawVariantLeftThumb: "",

      baseRawVariantRight: "",
      baseRawVariantRightThumb: "",

      baseRawVariantObliqueFront: "",
      baseRawVariantObliqueFrontThumb: "",

      baseRawVariantObliqueBack: "",
      baseRawVariantObliqueBackThumb: "",

      baseRawVariantColor: "",
      baseRawVariantColorThumb: "",

      baseDisplay: "",
      baseDisplayThumb: "",
    };

    this.state = { ...this.initState };
    this.formInput = React.createRef();
  }

  onClickCancel = async () => {
    const initState = { ...this.initState };

    this.setState(initState);
    this.setState({
      color_list: [],
    });

    this.formInput.current.resetFields();
  };

  changeInputValueVariantName = (v) => {
    this.setState({
      inputValueVariantName: v.target.value,
    });
  };

  changeInputValueVariantCode = (v) => {
    this.setState({
      inputValueVariantCode: v.target.value,
    });
  };

  changeInputValueVariantCodeVin = (v) => {
    this.setState({
      inputValueVariantCodeVin: v.target.value,
    });
  };

  changeInputValueMarketName = (v) => {
    this.setState({
      inputValueMarketName: v.target.value,
    });
  };

  changeInputValueSeriesName = (v) => {
    this.setState({
      inputValueSeriesName: v.target.value,
    });
  };

  changeInputValueDistributorCode = (v) => {
    this.setState({
      inputValueDistributorCode: v.target.value,
    });
  };

  changeInputValueCategory = async (v) => {
    await this.setState({
      inputValueCategory: v,
    });
    this.fetchDataModel();
  };

  changeInputValueClass = async (v) => {
    await this.setState({
      inputValueClass: v,
    });
    this.fetchDataModel();
  };

  changeInputValueBrand = async (v, i) => {
    await this.setState({
      inputValueBrandUUID: v,
      inputValueBrandName: i.children,
    });
    this.fetchDataModel();
  };

  changeInputValueModel = (v, i) => {
    this.setState({
      inputValueModelUuid: v,
      inputValueModelName: i.children,
      inputValueModelMarketName: i.add,
    });
  };

  changeInputValueDoors = (v) => {
    this.setState({
      inputValueDoors: v.target.value,
    });
  };

  changeInputValueChairs = (v) => {
    this.setState({
      inputValueChairs: v,
    });
  };

  changeInputValueAbout = (html) => {
    this.setState({
      inputValueAbout: html,
    });
  };

  changeInputValueDriveType = (v) => {
    this.setState({
      inputValueDriveType: v,
    });
  };

  changeInputValueStatus = (v) => {
    this.setState({
      inputValueStatus: v,
    });
  };

  fetchDataModel = async () => {
    try {
      const filter = this.state;

      // console.log("inputValueCategory = " + filter.inputValueCategory + "" + "inputValueClass = " + filter.inputValueClass + "" + "inputValueBrandUUID = " + filter.inputValueBrandUUID + "");

      if (filter.inputValueCategory !== "" && filter.inputValueClass !== "" && filter.inputValueBrandUUID !== "") {
        const params = {
          category: filter.inputValueCategory,
          class: filter.inputValueClass,
          brand_uuid: filter.inputValueBrandUUID,
        };

        const dataModel = await vehicleServices.getModelList(params);

        this.setState({
          dataOptionModel: dataModel.data.data,
        });
      }
    } catch (error) {
      this.setState({
        dataOptionModel: [],
      });
    }
  };

  changeInputUrlInfo = (v) => {
    this.setState({
      inputValueUrlInfo: v.target.value,
    });
  };

  changeInputValueEngineCapacity = (v) => {
    this.setState({
      inputValueEngineCapacity: v.target.value,
    });
  };

  changeInputValueEngineCode = (v) => {
    this.setState({
      inputValueEngineCode: v.target.value,
    });
  };

  changeInputValueEngineHP = (v) => {
    this.setState({
      inputValueEngineHP: v.target.value,
    });
  };

  changeInputValueEngineTorque = (v) => {
    this.setState({
      inputValueEngineTorque: v.target.value,
    });
  };

  changeInputValueEngineCylinder = (v) => {
    this.setState({
      inputValueEngineCylinder: v.target.value,
    });
  };

  changeInputValueEngineValve = (v) => {
    this.setState({
      inputValueEngineValve: v.target.value,
    });
  };

  changeInputValueFuel = (v) => {
    this.setState({
      inputValueFuel: v,
    });
  };

  changeInputValueFuelSupplySystem = (v) => {
    this.setState({
      inputValueFuelSupplySystem: v,
    });
  };

  changeInputValueTransmission = (v) => {
    this.setState({
      inputValueTransmission: v,
    });
  };

  changeInputValueEngineValveConfig = (v) => {
    this.setState({
      inputValueEngineValveConfig: v,
    });
  };

  changeInputValueTransmissionSpeed = (v) => {
    this.setState({
      inputValueTransmissionSpeed: v.target.value,
    });
  };

  changeInputBackgroundImageCode = (v) => {
    this.setState({
      inputBackgroundImageCode: v.target.value,
    });
  };

  changeInputLeftTopImageCode = (v) => {
    this.setState({
      inputLeftTopImageCode: v.target.value,
    });
  };

  changeInputRightTopImageCode = (v) => {
    this.setState({
      inputRightTopImageCode: v.target.value,
    });
  };

  changeInputRightBottomImageCode = (v) => {
    this.setState({
      inputRightBottomImageCode: v.target.value,
    });
  };

  changeInputLeftBottomImageCode = (v) => {
    this.setState({
      inputLeftBottomImageCode: v.target.value,
    });
  };

  changeInputTemplateImage = (v) => {
    this.setState({
      inputTemplateImage: v,
    });
  };

  changeInputDistributionDestination = async (v) => {
    await this.setState({
      inputDistributionDestination: v,
    });
    if (v !== "NONE") {
      await this.fetchDataArea();
    }
  };

  fetchDataArea = async () => {
    try {
      const destination = this.state.inputDistributionDestination;

      if (destination !== "NONE" && destination !== "") {
        const dataModel = await distributionServices.getArea(destination);

        this.setState({
          dataOptionArea: dataModel.data.data,
        });
      }
    } catch (error) {
      this.setState({
        dataOptionArea: [],
      });
    }
  };

  changeInputAreaDistribution = (v) => {
    this.setState({
      inputAreaDistribution: v,
    });
  };

  changeInputModelPriceDefault = (v) => {
    this.setState({
      inputModelPriceDefault: v.target.value,
    });
  };

  changeInputModelPriceStrikethrough = (v) => {
    this.setState({
      inputModelPriceStrikethrough: v.target.value,
    });
  };

  changeInputModelPriceMin = (v) => {
    this.setState({
      inputModelPriceMin: v.target.value,
    });
  };

  changeInputModelPriceMax = (v) => {
    this.setState({
      inputModelPriceMax: v.target.value,
    });
  };

  changeInputVariantPriceDefault = (v) => {
    this.setState({
      inputVariantPriceDefault: v.target.value,
    });
  };

  changeInputVariantPriceStrikethrough = (v) => {
    this.setState({
      inputVariantPriceStrikethrough: v.target.value,
    });
  };

  changeInputVariantPriceMin = (v) => {
    this.setState({
      inputVariantPriceMin: v.target.value,
    });
  };

  changeInputVariantPriceMax = (v) => {
    this.setState({
      inputVariantPriceMax: v.target.value,
    });
  };

  // changeInputAddMarginLeft = (v) => {
  //   this.setState({
  //     inputAddMarginLeft: v.target.value,
  //   });
  // };

  // changeInputAddMarginRight = (v) => {
  //   this.setState({
  //     inputAddMarginRight: v.target.value,
  //   });
  // };

  // changeInputAddMarginTop = (v) => {
  //   this.setState({
  //     inputAddMarginTop: v.target.value,
  //   });
  // };

  // changeInputAddMarginBottom = (v) => {
  //   this.setState({
  //     inputAddMarginBottom: v.target.value,
  //   });
  // };

  onClickSave = async () => {
    const status = this.state.inputValueStatus;

    const color_list = this.state.color_list;
    const color_list_no_img = [];
    for (let i = 0; i < color_list?.length; i++) {
      const c = color_list[i];

      color_list_no_img.push({
        color_code: c.color_code,
        color_name: c.color_name,
        color_market_name: c.color_market_name,
      });
    }

    const variant_uuid = uuidv4();
    // const variant_uuid = "d6a110cf-5ba6-45d3-9174-aad7010e46ed";
    const postData = {
      uuid: variant_uuid,
      name: this.state.inputValueVariantName.toString().toLowerCase()?.trim(),
      code: this.state.inputValueVariantCode.toString().toUpperCase().replace(/ /g, ""),
      code_vin: this.state.inputValueVariantCodeVin.toString().toUpperCase().replace(/ /g, ""),

      market_name: this.state.inputValueMarketName,
      series_name: this.state.inputValueSeriesName,
      distributor_code: this.state.inputValueDistributorCode,

      brand_uuid: this.state.inputValueBrandUUID,
      brand_name: this.state.inputValueBrandName,
      model_uuid: this.state.inputValueModelUuid,
      model_name: this.state.inputValueModelName,
      model_market_name: this.state.inputValueModelMarketName,
      model_category: this.state.inputValueCategory,
      model_class: this.state.inputValueClass,

      color: color_list_no_img,
      price: null,

      engine_capacity: this.state.inputValueEngineCapacity,
      engine_code: this.state.inputValueEngineCode,
      engine_hp: this.state.inputValueEngineHP,
      engine_torque: this.state.inputValueEngineTorque,
      engine_valve: this.state.inputValueEngineValve,
      engine_valve_config: this.state.inputValueEngineValveConfig,
      engine_cylinder: this.state.inputValueEngineCylinder,

      fuel: this.state.inputValueFuel,
      fuel_supply_system: this.state.inputValueFuelSupplySystem,

      transmission: this.state.inputValueTransmission,
      transmission_type: this.state.inputValueTransmission,
      transmission_speed: this.state.inputValueTransmissionSpeed,

      generation: null,
      year_production: null,
      year_first_production: null,
      year_latest_production: null,

      url_image: "",
      url_image_thumbnail: "",
      url_info: this.state.inputValueUrlInfo,

      drive_type: this.state.inputValueDriveType,
      doors: this.state.inputValueDoors,
      chairs: this.state.inputValueChairs,
      active: status === "true" ? true : false,
      about: this.state.inputValueAbout,
      url_display_assets: [],
    };
    // console.log(postData);
    // return;

    if (!postData.name) {
      modalError({
        title: "Validation Error",
        content: "Invalid Variant Name",
      });
      return false;
    }
    if (!postData.code) {
      modalError({
        title: "Validation Error",
        content: "Invalid Variant Code",
      });
      return false;
    }
    if (!postData.code_vin) {
      modalError({
        title: "Validation Error",
        content: "Invalid Variant Code VIN",
      });
      return false;
    }
    if (!postData.market_name) {
      modalError({
        title: "Validation Error",
        content: "Invalid Market Name",
      });
      return false;
    }
    if (!postData.brand_uuid) {
      modalError({
        title: "Validation Error",
        content: "Invalid Brand",
      });
      return false;
    }
    if (!postData.model_uuid) {
      modalError({
        title: "Validation Error",
        content: "Invalid Model",
      });
      return false;
    }

    if (!postData.distributor_code) {
      modalError({
        title: "Validation Error",
        content: "Invalid Distributor Code",
      });
      return false;
    }
    if (this.state.inputTemplateImage === "") {
      modalError({
        title: "Validation Error",
        content: "Invalid Template Image",
      });
      return false;
    }
    if (
      this.state.baseRawVariantFront === "" &&
      this.state.baseRawVariantBack === "" &&
      this.state.baseRawVariantLeft === "" &&
      this.state.baseRawVariantRight === "" &&
      this.state.baseRawVariantObliqueFront === "" &&
      this.state.baseRawVariantObliqueBack === ""
    ) {
      modalError({
        title: "Validation Error",
        content: "Invalid Image, Please input at least 1 RAW IMAGE",
      });
      return false;
    }

    this.setState({
      loadingButton: true,
      showHideFormCanvas: "m-2 px-1 d-none",
      showHideSpiner: "d-block px-2 pt-5",
    });

    try {
      const token = this.cookies.get("_t_autotrimitrabiz");

      await vehicleServicesPost.setToken(token);
      await vehicleServicesPost.addVariant([postData]);

      const data_art = {
        brand_uuid: postData.brand_uuid,
        brand_name: postData.brand_name,
        model_uuid: postData.model_uuid,
        model_name: postData.model_name,
        variant_uuid: postData.uuid,
        variant_code: postData.code,
        variant_name: postData.name,
      };

      let data_generate_img = {};
      const url_display_assets = [];

      if (this.state.inputBackgroundImageCode !== "") {
        data_generate_img = {
          template_code: this.state.inputTemplateImage,
          template_name: this.state.inputTemplateImage,
          variant_code: postData?.code,
          variant_uuid: postData?.uuid,
          variant_name: postData?.name,
          brand_uuid: postData?.brand_uuid,
          brand_name: postData?.brand_name,
          model_uuid: postData?.model_uuid,
          model_name: postData?.model_name,
          background_code: this.state.inputBackgroundImageCode,
          main_content: [],
          additional_object_title: true,
          additional_object_title_position: "BOTTOM_CENTER",
          additional_object_code: "FROM_DB",
          additional_object_rasio: 2,
          generate_thumbnail: true,
        };

        if (this.state.inputLeftTopImageCode !== "") {
          data_generate_img.left_top_code = this.state.inputLeftTopImageCode;
          data_generate_img.resize_rasio_left_top_image = 2;
        }

        if (this.state.inputRightTopImageCode !== "") {
          data_generate_img.right_top_code = this.state.inputRightTopImageCode;
          data_generate_img.resize_rasio_right_top_image = 2;
          if (this.state.inputTemplateImage === "TEMP-AMARTAHONDA") {
            data_generate_img.resize_rasio_right_top_image = 3;
          }
        }

        if (this.state.inputLeftBottomImageCode !== "") {
          data_generate_img.left_bottom_code = this.state.inputLeftBottomImageCode;
          data_generate_img.resize_rasio_left_bottom_image = 2;
        }

        if (this.state.inputRightBottomImageCode !== "") {
          data_generate_img.right_bottom_code = this.state.inputRightBottomImageCode;
          data_generate_img.resize_rasio_right_bottom_image = 2;
        }
      }

      await launch.setToken(token);

      // PROSES IMAGE RAW
      // PROSES IMAGE VARIANT
      if (this.state.baseRawVariantFront !== "" && this.state.baseRawVariantFrontThumb !== "") {
        data_art.art_category = "RAW_VEHICLE_VARIANT";
        data_art.notes = "FRONT_VIEW";
        data_art.base_img = this.state.baseRawVariantFront;
        data_art.base_img_thumb = this.state.baseRawVariantFrontThumb;

        const res_image_code = await launch.uploadRawVariantImage(data_art);
        if (res_image_code?.data?.data?.art_code !== "" && Object.keys(data_generate_img)?.length > 0) {
          data_generate_img.main_content.push({
            main_content_code: res_image_code?.data?.data?.art_code,
            notes: data_art.notes,
          });

          url_display_assets.push({
            type: "image",
            category: "raw_variant",
            url_asset: res_image_code?.data?.data?.url_image,
            url_asset_thumbnail: res_image_code?.data?.data?.url_image_thumb,
            caption: "RAW_VARIANT_" + data_art.notes?.toUpperCase(),
            show: false,
          });
        }
      }

      if (this.state.baseRawVariantBack !== "" && this.state.baseRawVariantBackThumb !== "") {
        data_art.art_category = "RAW_VEHICLE_VARIANT";
        data_art.notes = "BACK_VIEW";
        data_art.base_img = this.state.baseRawVariantBack;
        data_art.base_img_thumb = this.state.baseRawVariantBackThumb;

        const res_image_code = await launch.uploadRawVariantImage(data_art);
        if (res_image_code?.data?.data?.art_code !== "" && Object.keys(data_generate_img)?.length > 0) {
          data_generate_img.main_content.push({
            main_content_code: res_image_code?.data?.data?.art_code,
            notes: data_art.notes,
          });

          url_display_assets.push({
            type: "image",
            category: "raw_variant",
            url_asset: res_image_code?.data?.data?.url_image,
            url_asset_thumbnail: res_image_code?.data?.data?.url_image_thumb,
            caption: "RAW_VARIANT_" + data_art.notes?.toUpperCase(),
            show: false,
          });
        }
      }

      if (this.state.baseRawVariantLeft !== "" && this.state.baseRawVariantLeftThumb !== "") {
        data_art.art_category = "RAW_VEHICLE_VARIANT";
        data_art.notes = "LEFT_VIEW";
        data_art.base_img = this.state.baseRawVariantLeft;
        data_art.base_img_thumb = this.state.baseRawVariantLeftThumb;

        const res_image_code = await launch.uploadRawVariantImage(data_art);
        if (res_image_code?.data?.data?.art_code !== "" && Object.keys(data_generate_img)?.length > 0) {
          data_generate_img.main_content.push({
            main_content_code: res_image_code?.data?.data?.art_code,
            notes: data_art.notes,
          });

          url_display_assets.push({
            type: "image",
            category: "raw_variant",
            url_asset: res_image_code?.data?.data?.url_image,
            url_asset_thumbnail: res_image_code?.data?.data?.url_image_thumb,
            caption: "RAW_VARIANT_" + data_art.notes?.toUpperCase(),
            show: false,
          });
        }
      }

      if (this.state.baseRawVariantRight !== "" && this.state.baseRawVariantRightThumb !== "") {
        data_art.art_category = "RAW_VEHICLE_VARIANT";
        data_art.notes = "RIGHT_VIEW";
        data_art.base_img = this.state.baseRawVariantRight;
        data_art.base_img_thumb = this.state.baseRawVariantRightThumb;

        const res_image_code = await launch.uploadRawVariantImage(data_art);
        if (res_image_code?.data?.data?.art_code !== "" && Object.keys(data_generate_img)?.length > 0) {
          data_generate_img.main_content.push({
            main_content_code: res_image_code?.data?.data?.art_code,
            notes: data_art.notes,
          });

          url_display_assets.push({
            type: "image",
            category: "raw_variant",
            url_asset: res_image_code?.data?.data?.url_image,
            url_asset_thumbnail: res_image_code?.data?.data?.url_image_thumb,
            caption: "RAW_VARIANT_" + data_art.notes?.toUpperCase(),
            show: false,
          });
        }
      }

      if (this.state.baseRawVariantObliqueFront !== "" && this.state.baseRawVariantObliqueFrontThumb !== "") {
        data_art.art_category = "RAW_VEHICLE_VARIANT";
        data_art.notes = "OBLIQUE_FRONT_VIEW";
        data_art.base_img = this.state.baseRawVariantObliqueFront;
        data_art.base_img_thumb = this.state.baseRawVariantObliqueFrontThumb;

        const res_image_code = await launch.uploadRawVariantImage(data_art);
        if (res_image_code?.data?.data?.art_code !== "" && Object.keys(data_generate_img)?.length > 0) {
          data_generate_img.main_content.push({
            main_content_code: res_image_code?.data?.data?.art_code,
            notes: data_art.notes,
          });

          url_display_assets.push({
            type: "image",
            category: "raw_variant",
            url_asset: res_image_code?.data?.data?.url_image,
            url_asset_thumbnail: res_image_code?.data?.data?.url_image_thumb,
            caption: "RAW_VARIANT_" + data_art.notes?.toUpperCase(),
            show: false,
          });
        }
      }

      if (this.state.baseRawVariantObliqueBack !== "" && this.state.baseRawVariantObliqueBackThumb !== "") {
        data_art.art_category = "RAW_VEHICLE_VARIANT";
        data_art.notes = "OBLIQUE_BACK_VIEW";
        data_art.base_img = this.state.baseRawVariantObliqueBack;
        data_art.base_img_thumb = this.state.baseRawVariantObliqueBackThumb;

        const res_image_code = await launch.uploadRawVariantImage(data_art);
        if (res_image_code?.data?.data?.art_code !== "" && Object.keys(data_generate_img)?.length > 0) {
          data_generate_img.main_content.push({
            main_content_code: res_image_code?.data?.data?.art_code,
            notes: data_art.notes,
          });

          url_display_assets.push({
            type: "image",
            category: "raw_variant",
            url_asset: res_image_code?.data?.data?.url_image,
            url_asset_thumbnail: res_image_code?.data?.data?.url_image_thumb,
            caption: "RAW_VARIANT_" + data_art.notes?.toUpperCase(),
            show: false,
          });
        }
      }
      // END PROSES IMAGE VARIANT

      // PROSES IMAGE COLOR
      for (let i = 0; i < color_list?.length; i++) {
        const c = color_list[i];

        data_art.art_category = "RAW_VEHICLE_VARIANT_COLOR";
        data_art.notes = "INSERTED BY LAUNCH";
        data_art.color_code = c.color_code;
        data_art.color_name = c.color_name;
        data_art.color_market_name = c.color_market_name;
        data_art.base_img = c.base_raw_variant_color;
        data_art.base_img_thumb = c.base_raw_variant_color_thumb;

        const res_image_code = await launch.uploadRawVariantImage(data_art);

        if (res_image_code?.data?.data?.art_code !== "" && Object.keys(data_generate_img)?.length > 0) {
          data_generate_img.main_content.push({
            main_content_code: res_image_code?.data?.data?.art_code,
            notes: "VARIANT_COLOR : " + c.color_name?.toUpperCase(),
            variant_color_code: c.color_code,
            variant_color_name: c.color_name,
            type_image: "VARIANT_COLOR",
          });

          url_display_assets.push({
            type: "image",
            category: "raw_variant_color",
            url_asset: res_image_code?.data?.data?.url_image,
            url_asset_thumbnail: res_image_code?.data?.data?.url_image_thumb,
            caption: "RAW_VARIANT_COLOR_" + c.color_name?.toUpperCase(),
            show: false,
          });
        }
      }
      // END PROSES IMAGE COLOR
      // END PROSES IMAGE RAW

      // GENERATE IMAGE
      let gen_image_default = "";
      let gen_image_default_thumb = "";
      let gen_color_image = {};
      if (data_generate_img?.main_content?.length > 0) {
        const proses_generate_image = await generateImage.generateImageVariant(data_generate_img);
        // console.log(proses_generate_image?.data?.success?.data);
        if (proses_generate_image?.data?.success?.data?.length > 0) {
          for (const g of proses_generate_image?.data?.success?.data) {
            if (gen_image_default === "" && gen_image_default_thumb === "" && g?.type === "VARIANT") {
              gen_image_default = g?.url_image;
              gen_image_default_thumb = g?.url_image_thumb;
            }

            if (g?.type === "VARIANT_COLOR") {
              gen_color_image[g?.variant_color_code] = {
                url_asset: g?.url_image,
                url_asset_thumbnail: g?.url_image_thumb,
              };

              url_display_assets.push({
                type: "image",
                category: "variant_color",
                url_asset: g?.url_image,
                url_asset_thumbnail: g?.url_image_thumb,
                caption: g?.notes,
                show: true,
              });
            } else {
              url_display_assets.push({
                type: "image",
                category: g?.notes?.toLowerCase(),
                url_asset: g?.url_image,
                url_asset_thumbnail: g?.url_image_thumb,
                caption: g?.notes,
                show: false,
              });
            }
          }
        }
      }
      // END GENERATE IMAGE

      // PROSES IMAGE OTHER DISPLAY
      for (let i = 0; i < this.state.display_list?.length; i++) {
        const d = this.state.display_list[i];

        data_art.art_category = "VEHICLE_VARIANT";
        data_art.notes = d.display_category?.toUpperCase();
        data_art.base_img = d.base_display;
        data_art.base_img_thumb = d.base_display_thumb;

        const url_img_variant = await launch.uploadRawVariantImage(data_art);
        if (url_img_variant?.data?.data?.url_image !== "") {
          url_display_assets.push({
            type: d.display_type,
            category: d.display_category,
            url_asset: url_img_variant?.data?.data?.url_image,
            url_asset_thumbnail: url_img_variant?.data?.data?.url_image_thumb,
            caption: d.display_caption,
            show: true,
          });
        }
      }
      // END ROSES IMAGE OTHER DISPLAY

      let update = false;
      if (url_display_assets.length > 0) {
        update = true;
        postData["url_display_assets"] = url_display_assets;
      }

      postData["url_image"] = gen_image_default;
      postData["url_image_thumbnail"] = gen_image_default_thumb;

      if (update) {
        await vehicleServicesPost.setToken(token);
        await vehicleServicesPost.addVariant([postData]);
      }

      const arr_pricing_model = [];
      const arr_pricing_variant = [];
      const arr_pricing_variant_color = [];
      if (this.state.inputDistributionDestination !== "" && this.state.inputDistributionDestination !== "NONE") {
        for (let i = 0; i < this.state.pricing_list?.length; i++) {
          const d = this.state.pricing_list[i];

          if (d?.price_category === "MODEL") {
            arr_pricing_model.push({
              company_code: this.state.inputDistributionDestination?.toLowerCase(),
              area_code: d?.area_code,
              brand_uuid: postData?.brand_uuid,
              brand: postData?.brand_name,
              class: postData?.model_class,
              category: postData?.model_category,
              model_uuid: postData?.model_uuid,
              name: postData?.model_market_name,
              price: Number(d?.price_default),
              price_strikethrough: Number(d?.price_strikethrough),
              price_max: Number(d?.price_min),
              price_min: Number(d?.price_max),
              sorting: 0,
              label: "new",
              url_image: gen_image_default,
              url_thumb_image: gen_image_default_thumb,
              url_brochure: "",
              url_video: "",
              url_specification: "",
              highlight: true,
              active: true,
            });
          }

          if (d?.price_category === "VARIANT") {
            arr_pricing_variant.push({
              company_code: this.state.inputDistributionDestination?.toLowerCase(),
              area_code: d?.area_code,
              brand_uuid: postData?.brand_uuid,
              brand: postData?.brand_name,
              class: postData?.model_class,
              category: postData?.model_category,
              model_uuid: postData?.model_uuid,
              name: postData?.model_market_name,
              variant_uuid: postData?.uuid,
              variant_code: postData?.code,
              variant_name: postData?.name,
              variant_distributor_code: postData?.distributor_code,
              price: Number(d?.price_default),
              price_strikethrough: Number(d?.price_strikethrough),
              price_min: Number(d?.price_min),
              price_max: Number(d?.price_max),
              sorting: 0,
              label: "new",
              url_image: gen_image_default,
              url_thumb_image: gen_image_default_thumb,
              url_brochure: "",
              url_video: "",
              url_specification: "",
              highlight: true,
              active: true,
            });
          }

          if (d?.price_category === "VARIANT_COLOR") {
            let image_color = gen_image_default;
            let image_color_thumbnail = gen_image_default_thumb;
            if (gen_color_image[d?.color_code]) {
              image_color = gen_color_image[d?.color_code]?.url_asset;
              image_color_thumbnail = gen_color_image[d?.color_code]?.url_asset_thumbnail;
            }

            arr_pricing_variant_color.push({
              company_code: this.state.inputDistributionDestination?.toLowerCase(),
              area_code: d?.area_code,
              brand_uuid: postData?.brand_uuid,
              brand: postData?.brand_name,
              class: postData?.model_class,
              category: postData?.model_category,
              model_uuid: postData?.model_uuid,
              name: postData?.model_market_name,
              variant_uuid: postData?.uuid,
              variant_code: postData?.code,
              variant_name: postData?.name,
              variant_distributor_code: postData?.distributor_code,
              color_code: d?.color_code,
              color_name: d?.color_name,
              price: Number(d?.price_default),
              price_strikethrough: Number(d?.price_strikethrough),
              price_min: Number(d?.price_min),
              price_max: Number(d?.price_max),
              sorting: 0,
              label: "new",
              url_image: image_color,
              url_thumb_image: image_color_thumbnail,
              url_brochure: "",
              url_video: "",
              url_specification: "",
              highlight: true,
              active: true,
            });
          }
        }
      }

      if (arr_pricing_model?.length > 0) {
        for (let m = 0; m < arr_pricing_model.length; m++) {
          const md = arr_pricing_model[m];
          await distributionServices.addModel(md);
        }

        if (arr_pricing_variant?.length > 0) {
          for (let v = 0; v < arr_pricing_variant.length; v++) {
            const va = arr_pricing_variant[v];
            await distributionServices.addVariant(va);
          }

          if (arr_pricing_variant_color?.length > 0) {
            for (let vc = 0; vc < arr_pricing_variant_color.length; vc++) {
              const vcl = arr_pricing_variant_color[vc];
              distributionServices.addVariantColor(vcl);
            }
          }
        }
      }

      // this.onClickCancel();
      this.setState({
        loadingButton: false,
        showHideFormCanvas: "m-2 px-1 d-block",
        showHideSpiner: "d-none px-2 pt-5",
      });
      modalSuccess({
        content: "SUCCESS",
      });
    } catch (error) {
      this.setState({
        loadingButton: false,
      });
      modalError({
        title: "Process Error",
        content: error.message,
      });
    }
  };

  changeInputColorCode = (v) => {
    this.setState({
      inputColorCode: v.target.value,
    });
  };

  changeInputColorName = (v) => {
    this.setState({
      inputColorName: v.target.value,
    });
  };

  changeInputColorMarketName = (v) => {
    this.setState({
      inputColorMarketName: v.target.value,
    });
  };

  changeInputDisplayType = (v) => {
    this.setState({
      inputDisplayType: v,
    });
  };

  changeInputDisplayCategory = (v) => {
    this.setState({
      inputDisplayCategory: v,
    });
  };

  changeInputDisplayCaption = (v) => {
    this.setState({
      inputDisplayCaption: v.target.value,
    });
  };

  showColorModal = async () => {
    this.setState({
      inputColorCode: "",
      inputColorName: "",
      inputColorMarketName: "",
      color_modal: true,
    });
  };

  closeColorModal = async () => {
    this.setState({
      inputColorCode: "",
      inputColorName: "",
      inputColorMarketName: "",
      baseRawVariantColor: "",
      baseRawVariantColorThumb: "",
      color_modal: false,
    });
  };

  addColorList = async () => {
    const color_code = this.state.inputColorCode;
    const color_name = this.state.inputColorName;
    const color_market_name = this.state.inputColorMarketName;
    const base_raw_variant_color = this.state.baseRawVariantColor;
    const base_raw_variant_color_thumb = this.state.baseRawVariantColorThumb;

    if (color_code !== "" && color_name !== "" && color_market_name !== "" && base_raw_variant_color !== "" && base_raw_variant_color_thumb !== "") {
      const color_list = this.state.color_list;
      const color_pricing_list = this.state.color_pricing_list;
      const new_color = {
        color_code: color_code,
        color_name: color_name,
        color_market_name: color_market_name,
        base_raw_variant_color: base_raw_variant_color,
        base_raw_variant_color_thumb: base_raw_variant_color_thumb,
      };

      const new_color_price = {
        color_code: color_code,
        color_name: color_name,
        price_default: 0,
        price_strikethrough: 0,
        price_min: 0,
        price_max: 0,
      };

      color_list.push(new_color);
      color_pricing_list.push(new_color_price);
      this.setState({
        color_modal: false,
        color_list: color_list,
        color_pricing_list: color_pricing_list,
        pricing_list: [],
      });

      this.closeColorModal();
    } else {
      modalError({
        title: "Error Validation",
        content: "Error Validation",
      });
    }
  };

  deleteColorList = (value) => {
    let color_list = this.state.color_list;
    let color_pricing_list = this.state.color_pricing_list;
    const index = color_list.indexOf(value);
    if (index !== -1) {
      color_list.splice(index, 1);
      color_pricing_list.splice(index, 1);
    }
    this.setState({
      color_list: color_list,
      color_pricing_list: color_pricing_list,
      pricing_list: [],
    });
  };

  showDisplayModal = async () => {
    this.setState({
      inputDisplayType: "",
      inputDisplayCategory: "",
      inputDisplayCaption: "",
      baseDisplay: "",
      baseDisplayThumb: "",
      display_modal: true,
    });
  };

  closeDisplayModal = async () => {
    this.setState({
      inputDisplayType: "",
      inputDisplayCategory: "",
      inputDisplayCaption: "",
      baseDisplay: "",
      baseDisplayThumb: "",
      display_modal: false,
    });
  };

  addDisplayList = async () => {
    const display_type = this.state.inputDisplayType;
    const display_category = this.state.inputDisplayCategory;
    const display_caption = this.state.inputDisplayCaption;
    const base_display = this.state.baseDisplay;
    const base_display_thumb = this.state.baseDisplayThumb;

    if (display_type !== "" && display_category !== "" && display_caption !== "" && base_display !== "" && base_display_thumb !== "") {
      const display_list = this.state.display_list;
      const new_display = {
        display_type: display_type,
        display_category: display_category,
        display_caption: display_caption,
        base_display: base_display,
        base_display_thumb: base_display_thumb,
      };

      display_list.push(new_display);
      this.setState({
        display_modal: false,
        display_list: display_list,
      });

      this.closeDisplayModal();
    } else {
      modalError({
        title: "Error Validation",
        content: "Error Validation",
      });
    }
  };

  deleteDisplayList = (value) => {
    let display_list = this.state.display_list;
    const index = display_list.indexOf(value);
    if (index !== -1) {
      display_list.splice(index, 1);
    }
    this.setState({
      display_list: display_list,
    });
  };

  showPricingModal = async () => {
    this.setState({
      inputAreaDistribution: "",
      inputModelPriceDefault: "",
      inputModelPriceStrikethrough: 0,
      inputModelPriceMin: "",
      inputModelPriceMax: "",
      inputVariantPriceDefault: "",
      inputVariantPriceStrikethrough: 0,
      inputVariantPriceMin: "",
      inputVariantPriceMax: "",
      checkboxChecked: false,
      pricing_modal: true,
    });
  };

  closePricingModal = async () => {
    this.setState({
      inputAreaDistribution: "",
      inputModelPriceDefault: "",
      inputModelPriceStrikethrough: 0,
      inputModelPriceMin: "",
      inputModelPriceMax: "",
      inputVariantPriceDefault: "",
      inputVariantPriceStrikethrough: 0,
      inputVariantPriceMin: "",
      inputVariantPriceMax: "",
      checkboxChecked: false,
      pricing_modal: false,
    });
  };

  addPricingList = async () => {
    const color_pricing_list = this.state.color_pricing_list;
    const area_distribution = this.state.inputAreaDistribution;

    const model_price_default = this.state.inputModelPriceDefault;
    const model_price_strikethrough = this.state.inputModelPriceStrikethrough;
    const model_price_min = this.state.inputModelPriceMin;
    const model_price_max = this.state.inputModelPriceMax;

    const variant_price_default = this.state.inputVariantPriceDefault;
    const variant_price_strikethrough = this.state.inputVariantPriceStrikethrough;
    const variant_price_min = this.state.inputVariantPriceMin;
    const variant_price_max = this.state.inputVariantPriceMax;

    if (
      area_distribution !== "" &&
      model_price_default !== "" &&
      model_price_strikethrough !== "" &&
      model_price_min !== "" &&
      model_price_max !== "" &&
      variant_price_default !== "" &&
      variant_price_strikethrough !== "" &&
      variant_price_min !== "" &&
      variant_price_max !== ""
    ) {
      const pricing_list = this.state.pricing_list;
      pricing_list.push({
        area_code: area_distribution,
        area_name: area_distribution,
        price_category: "MODEL",
        price_default: model_price_default,
        price_strikethrough: model_price_strikethrough,
        price_min: model_price_min,
        price_max: model_price_max,
      });

      pricing_list.push({
        area_code: area_distribution,
        area_name: area_distribution,
        price_category: "VARIANT",
        price_default: variant_price_default,
        price_strikethrough: variant_price_strikethrough,
        price_min: variant_price_min,
        price_max: variant_price_max,
      });

      for (let i = 0; i < color_pricing_list?.length; i++) {
        const c = color_pricing_list[i];

        pricing_list.push({
          area_code: area_distribution,
          area_name: area_distribution,
          color_code: c.color_code,
          color_name: c.color_name,
          price_category: "VARIANT_COLOR",
          price_default: c?.price_default,
          price_strikethrough: c?.price_strikethrough,
          price_min: c?.price_min,
          price_max: c?.price_max,
        });
      }

      this.setState({
        pricing_modal: false,
        pricing_list: pricing_list,
      });

      this.closePricingModal();
    } else {
      modalError({
        title: "Error Validation",
        content: "Error Validation",
      });
    }
  };

  addColorPrice = (price_type, v, index) => {
    let color_pricing_list = this.state.color_pricing_list;
    if (color_pricing_list[index]) {
      color_pricing_list[index][price_type] = v?.target?.value;
    }
    // console.log(color_pricing_list[index]);
    this.setState({
      color_pricing_list: color_pricing_list,
    });
  };

  changeCheckboxVariatPrice = (e) => {
    const checked = e.target.checked;

    const new_color_pricing_list = [];
    const color_pricing_list = this.state.color_pricing_list;
    const variant_price_default = this.state.inputVariantPriceDefault;
    const variant_price_strikethrough = this.state.inputVariantPriceStrikethrough;
    const variant_price_min = this.state.inputVariantPriceMin;
    const variant_price_max = this.state.inputVariantPriceMax;

    for (let i = 0; i < color_pricing_list?.length; i++) {
      const c = color_pricing_list[i];
      if (checked === true) {
        new_color_pricing_list.push({
          color_code: c.color_code,
          color_name: c.color_name,
          price_default: variant_price_default,
          price_strikethrough: variant_price_strikethrough,
          price_min: variant_price_min,
          price_max: variant_price_max,
        });
      } else {
        new_color_pricing_list.push({
          color_code: c.color_code,
          color_name: c.color_name,
          price_default: 0,
          price_strikethrough: 0,
          price_min: 0,
          price_max: 0,
        });
      }
    }

    // console.log("new_color_pricing_list", new_color_pricing_list);

    this.setState({
      color_pricing_list: new_color_pricing_list,
      checkboxChecked: checked,
    });
  };

  deletePricingList = (value) => {
    let pricing_list = this.state.pricing_list;
    const index = pricing_list.indexOf(value);
    if (index !== -1) {
      pricing_list.splice(index, 1);
    }
    this.setState({
      pricing_list: pricing_list,
    });
  };

  resizeImage = async (base64Str, imgWidth, imgHeight) => {
    let img = new Image();
    img.src = base64Str;
    let canvas = document.createElement("canvas");
    let MAX_WIDTH = imgWidth;
    let MAX_HEIGHT = imgHeight;
    let width = img.width;
    let height = img.height;

    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } else {
      if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
      }
    }
    canvas.width = width;
    canvas.height = height;
    let ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);
    return canvas.toDataURL();
  };

  getBaseRawVariantFront = async (base64DataImagePng) => {
    if (!base64DataImagePng || base64DataImagePng === "#") {
      Modal.error({
        title: "Process Error",
        content: "silakan lakukan crop image terlebih dahulu",
      });
      return;
    }

    this.setState({
      baseRawVariantFront: await this.resizeImage(base64DataImagePng, 600, 600),
      baseRawVariantFrontThumb: await this.resizeImage(base64DataImagePng, 180, 180),
    });
  };

  getBaseRawVariantBack = async (base64DataImagePng) => {
    if (!base64DataImagePng || base64DataImagePng === "#") {
      Modal.error({
        title: "Process Error",
        content: "silakan lakukan crop image terlebih dahulu",
      });
      return;
    }

    this.setState({
      baseRawVariantBack: await this.resizeImage(base64DataImagePng, 600, 600),
      baseRawVariantBackThumb: await this.resizeImage(base64DataImagePng, 180, 180),
    });
  };

  getBaseRawVariantLeft = async (base64DataImagePng) => {
    if (!base64DataImagePng || base64DataImagePng === "#") {
      Modal.error({
        title: "Process Error",
        content: "silakan lakukan crop image terlebih dahulu",
      });
      return;
    }

    this.setState({
      baseRawVariantLeft: await this.resizeImage(base64DataImagePng, 600, 600),
      baseRawVariantLeftThumb: await this.resizeImage(base64DataImagePng, 180, 180),
    });
  };

  getBaseRawVariantRight = async (base64DataImagePng) => {
    if (!base64DataImagePng || base64DataImagePng === "#") {
      Modal.error({
        title: "Process Error",
        content: "silakan lakukan crop image terlebih dahulu",
      });
      return;
    }

    this.setState({
      baseRawVariantRight: await this.resizeImage(base64DataImagePng, 600, 600),
      baseRawVariantRightThumb: await this.resizeImage(base64DataImagePng, 180, 180),
    });
  };

  getBaseRawVariantObliqueFront = async (base64DataImagePng) => {
    if (!base64DataImagePng || base64DataImagePng === "#") {
      Modal.error({
        title: "Process Error",
        content: "silakan lakukan crop image terlebih dahulu",
      });
      return;
    }

    this.setState({
      baseRawVariantObliqueFront: await this.resizeImage(base64DataImagePng, 600, 600),
      baseRawVariantObliqueFrontThumb: await this.resizeImage(base64DataImagePng, 180, 180),
    });
  };

  getBaseRawVariantObliqueBack = async (base64DataImagePng) => {
    if (!base64DataImagePng || base64DataImagePng === "#") {
      Modal.error({
        title: "Process Error",
        content: "silakan lakukan crop image terlebih dahulu",
      });
      return;
    }

    this.setState({
      baseRawVariantObliqueBack: await this.resizeImage(base64DataImagePng, 600, 600),
      baseRawVariantObliqueBackThumb: await this.resizeImage(base64DataImagePng, 180, 180),
    });
  };

  getBaseRawVariantColor = async (base64DataImagePng) => {
    if (!base64DataImagePng || base64DataImagePng === "#") {
      Modal.error({
        title: "Process Error",
        content: "silakan lakukan crop image terlebih dahulu",
      });
      return;
    }

    this.setState({
      baseRawVariantColor: await this.resizeImage(base64DataImagePng, 600, 600),
      baseRawVariantColorThumb: await this.resizeImage(base64DataImagePng, 180, 180),
    });
  };

  getBaseDisplay = async (base64DataImagePng) => {
    if (!base64DataImagePng || base64DataImagePng === "#") {
      Modal.error({
        title: "Process Error",
        content: "silakan lakukan crop image terlebih dahulu",
      });
      return;
    }

    this.setState({
      baseDisplay: await this.resizeImage(base64DataImagePng, 600, 600),
      baseDisplayThumb: await this.resizeImage(base64DataImagePng, 180, 180),
    });
  };
  render() {
    return (
      <React.Fragment>
        <LayoutDefault defaultOpenKeys={["m-launch"]} defaultSelectedKeys={["m-launch-vehicle-variant"]} loading={true}>
          <div id="add_edit_canvas" className={this.state.showHideFormCanvas}>
            {/* <h4 style={{ textTransform: "capitalize" }}>{this.state.titleCanvas}</h4> */}
            {/* <hr /> */}
            <Form layout="vertical" wrapperCol={{ span: 24 }} ref={this.formInput}>
              <Tabs>
                <TabPane
                  tab={
                    <Text>
                      <strong>Data Variant </strong>
                    </Text>
                  }
                  key="1"
                >
                  <div className="row mx-0">
                    <div className="col-lg-6">
                      <div className="row mx-0">
                        <div className="code-box-title  border-bottom border-secondary">
                          <strong>Detail Variant</strong>
                        </div>
                        <div className="col-lg-6">
                          <Form.Item label="Variant Name" className="my-1 py-1">
                            <Input name="variant_name" value={this.state.inputValueVariantName} onChange={this.changeInputValueVariantName} placeholder="Variant Name" />
                          </Form.Item>
                          <Form.Item label="Variant Code" className="my-1 py-1">
                            <Input name="code" value={this.state.inputValueVariantCode} onChange={this.changeInputValueVariantCode} placeholder="Variant Code" />
                          </Form.Item>
                          <Form.Item label="Variant Code Vin" className="my-1 py-1">
                            <Input name="code_vin" value={this.state.inputValueVariantCodeVin} onChange={this.changeInputValueVariantCodeVin} placeholder="Variant Code Vin" />
                          </Form.Item>
                          <Form.Item label="Market Name" className="my-1 py-1">
                            <Input name="market_name" value={this.state.inputValueMarketName} onChange={this.changeInputValueMarketName} placeholder="Market Name" />
                          </Form.Item>
                          <Form.Item label="Series Name" className="my-1 py-1">
                            <Input name="series_name" value={this.state.inputValueSeriesName} onChange={this.changeInputValueSeriesName} placeholder="Series Name" />
                          </Form.Item>
                          <Form.Item label="Distributor Code" className="my-1 py-1">
                            <Input name="distributor_code" value={this.state.inputValueDistributorCode} onChange={this.changeInputValueDistributorCode} placeholder="Distributor Code" />
                          </Form.Item>
                          <SelectActive value={this.state.inputValueStatus} onChangeValue={this.changeInputValueStatus}></SelectActive>
                        </div>
                        <div className="col-lg-6">
                          <SelectCategory value={this.state.inputValueCategory} onChangeValue={this.changeInputValueCategory} />
                          <SelectClass value={this.state.inputValueClass} onChangeValue={this.changeInputValueClass} />
                          <SelectBrand value={this.state.inputValueBrandUUID} onChangeValue={this.changeInputValueBrand} />
                          <Form.Item label="Model" name="model_uuid" className="col-lg-12 my-1 py-1">
                            <Select name="model_uuid" style={{ fontSize: "85%", minWidth: "200px" }} placeholder="Select a Model" onChange={this.changeInputValueModel}>
                              {this.state.dataOptionModel.map((d, i) => {
                                return (
                                  <Option key={d.model_uuid} value={d.model_uuid} add={d.market_name}>
                                    {d.model_name}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                          <SelectDriveType value={this.state.inputValueDriveType} onChangeValue={this.changeInputValueDriveType}></SelectDriveType>
                          <Form.Item label="Doors" className="my-1 py-1">
                            <Input type="number" name="doors" value={this.state.inputValueDoors} onChange={this.changeInputValueDoors} placeholder="Total Doors" />
                          </Form.Item>
                          <SelectChairs value={this.state.inputValueChairs} onChangeValue={this.changeInputValueChairs} mode="multiple" />
                          <Form.Item label="Link Info" className="my-1 py-1">
                            <Input name="url_info" value={this.state.inputValueUrlInfo} onChange={this.changeInputUrlInfo} placeholder="Link Wiki" />
                          </Form.Item>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="row mx-0">
                        <div className="col-lg-6">
                          <div className="code-box-title  border-bottom border-secondary">
                            <strong>Engine Detail</strong>
                          </div>
                          <Form.Item label="Engine Capacity" className="my-1 py-1">
                            <Input type="number" name="engine_capacity" value={this.state.inputValueEngineCapacity} onChange={this.changeInputValueEngineCapacity} placeholder="Engine Capacity" />
                          </Form.Item>
                          <Form.Item label="Engine Code" className="my-1 py-1">
                            <Input name="engine_code" value={this.state.inputValueEngineCode} onChange={this.changeInputValueEngineCode} placeholder="Engine Code" />
                          </Form.Item>
                          <Form.Item label="Engine HP" className="my-1 py-1">
                            <Input type="number" name="engine_hp" value={this.state.inputValueEngineHP} onChange={this.changeInputValueEngineHP} placeholder="Engine HP" />
                          </Form.Item>
                          <Form.Item label="Engine Torque" className="my-1 py-1">
                            <Input type="number" name="engine_torque" value={this.state.inputValueEngineTorque} onChange={this.changeInputValueEngineTorque} placeholder="Engine Torque" />
                          </Form.Item>
                          <SelectValveConfig value={this.state.inputValueEngineValveConfig} onChangeValue={this.changeInputValueEngineValveConfig} />
                          <Form.Item label="Engine Valve" className="my-1 py-1">
                            <Input type="number" name="engine_valve" value={this.state.inputValueEngineValve} onChange={this.changeInputValueEngineValve} placeholder="Engine Valve" />
                          </Form.Item>
                          <Form.Item label="Engine Cylinder" className="my-1 py-1">
                            <Input type="number" name="engine_cylinder" value={this.state.inputValueEngineCylinder} onChange={this.changeInputValueEngineCylinder} placeholder="Engine Cylinder" />
                          </Form.Item>
                        </div>
                        <div className="col-lg-6">
                          <div className="col-lg-12  px-0">
                            <div className="code-box-title  border-bottom border-secondary">
                              <strong>Fuel & Transmission Detail</strong>
                            </div>
                            <SelectFuel value={this.state.inputValueFuel} onChangeValue={this.changeInputValueFuel} />
                            <SelectFuelSupply value={this.state.inputValueFuelSupplySystem} onChangeValue={this.changeInputValueFuelSupplySystem} />
                            <SelectTransmission value={this.state.inputValueTransmission} onChangeValue={this.changeInputValueTransmission} />
                            <Form.Item label="Transmission Speed" className="my-1 py-1">
                              <Input type="number" name="transmission_speed" value={this.state.inputValueTransmissionSpeed} onChange={this.changeInputValueTransmissionSpeed} placeholder="Transmission Speed" />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mx-0">
                    <div className="col-lg-6">
                      <div className="row mx-0">
                        <div className="col-lg-12">
                          <EditorAbout value={this.state.inputValueAbout} onChange={this.changeInputValueAbout}></EditorAbout>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 pt-4">
                      <div className="row mx-0">
                        <div className="code-box-title  border-bottom border-secondary ">
                          <strong>Option Generate Image</strong>
                        </div>
                        <div className="row mx-0">
                          <div className="col-lg-6">
                            <SelectTemplateImage value={this.state.inputTemplateImage} onChangeValue={this.changeInputTemplateImage} />
                          </div>
                          <div className="col-lg-6">
                            <Form.Item label="Background Image Code" className="my-1 py-1">
                              <Input name="background_code" value={this.state.inputBackgroundImageCode} onChange={this.changeInputBackgroundImageCode} placeholder="Background Image Code" />
                            </Form.Item>
                          </div>
                          <div className="col-lg-6">
                            <Form.Item label="Left Top Image Code" className="my-1 py-1">
                              <Input name="left_top_code" value={this.state.inputLeftTopImageCode} onChange={this.changeInputLeftTopImageCode} placeholder="Left Top Image Code" />
                            </Form.Item>
                          </div>
                          <div className="col-lg-6">
                            <Form.Item label="Right Top Image Code" className="my-1 py-1">
                              <Input name="right_top_code" value={this.state.inputRightTopImageCode} onChange={this.changeInputRightTopImageCode} placeholder="Right Top Image Code" />
                            </Form.Item>
                          </div>
                          <div className="col-lg-6">
                            <Form.Item label="Right Bottom Image Code" className="my-1 py-1">
                              <Input name="right_bottom_code" value={this.state.inputRightBottomImageCode} onChange={this.changeInputRightBottomImageCode} placeholder="Right Bottom Image Code" />
                            </Form.Item>
                          </div>
                          <div className="col-lg-6">
                            <Form.Item label="Left Bottom Image Code" className="my-1 py-1">
                              <Input name="left_bottom_code" value={this.state.inputLeftBottomImageCode} onChange={this.changeInputLeftBottomImageCode} placeholder="Left Bottom Image Code" />
                            </Form.Item>
                          </div>

                          {/* <div className="col-lg-6">
                            <Form.Item label="Margin Left" className="my-1 py-1">
                              <Input type="number" name="add_margin_left" value={this.state.inputAddMarginLeft} onChange={this.changeInputAddMarginLeft} placeholder="Additional Margin Left" />
                            </Form.Item>
                          </div>

                          <div className="col-lg-6">
                            <Form.Item label="Margin Right" className="my-1 py-1">
                              <Input type="number" name="add_margin_right" value={this.state.inputAddMarginRight} onChange={this.changeInputAddMarginRight} placeholder="Additional Margin Right" />
                            </Form.Item>
                          </div>

                          <div className="col-lg-6">
                            <Form.Item label="Margin Top" className="my-1 py-1">
                              <Input type="number" name="add_margin_top" value={this.state.inputAddMarginTop} onChange={this.changeInputAddMarginTop} placeholder="Additional Margin Top" />
                            </Form.Item>
                          </div>

                          <div className="col-lg-6">
                            <Form.Item label="Margin Bottom" className="my-1 py-1">
                              <Input type="number" name="add_margin_bottom" value={this.state.inputAddMarginBottom} onChange={this.changeInputAddMarginBottom} placeholder="Additional Margin Bottom" />
                            </Form.Item>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPane>
                <TabPane
                  tab={
                    <Text>
                      <strong>Raw Image </strong>
                    </Text>
                  }
                  key="2"
                >
                  <div className="row mx-0">
                    <div className="row mx-0">
                      <div className="col-lg-6">
                        <div className={`site-layout-background site-layout-content`}>
                          <Text>
                            <strong>Upload Front View Image </strong>
                          </Text>
                          <NoCrop params={this.state} onSaveImage={this.getBaseRawVariantFront} />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className={`site-layout-background site-layout-content`}>
                          <Text>
                            <strong>Upload Back View Image </strong>
                          </Text>
                          <NoCrop params={this.state} onSaveImage={this.getBaseRawVariantBack} />
                        </div>
                      </div>
                    </div>

                    <div className="row mx-0">
                      <div className="col-lg-6">
                        <div className={`site-layout-background site-layout-content`}>
                          <Text>
                            <strong>Upload Left View Image </strong>
                          </Text>
                          <NoCrop params={this.state} onSaveImage={this.getBaseRawVariantLeft} />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className={`site-layout-background site-layout-content`}>
                          <Text>
                            <strong>Upload Right View Image </strong>
                          </Text>
                          <NoCrop params={this.state} onSaveImage={this.getBaseRawVariantRight} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mx-0">
                    <div className="col-lg-6">
                      <div className={`site-layout-background site-layout-content`}>
                        <Text>
                          <strong>Upload Oblique Front View Image </strong>
                        </Text>
                        <NoCrop params={this.state} onSaveImage={this.getBaseRawVariantObliqueFront} />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className={`site-layout-background site-layout-content`}>
                        <Text>
                          <strong>Upload Oblique Back View Image </strong>
                        </Text>
                        <NoCrop params={this.state} onSaveImage={this.getBaseRawVariantObliqueBack} />
                      </div>
                    </div>
                  </div>
                </TabPane>
                <TabPane
                  tab={
                    <Text>
                      <strong>Display Assets </strong>
                    </Text>
                  }
                  key="3"
                >
                  <div className="row mx-0 mt-1" style={{ minHeight: 200, width: "100%" }}>
                    <div className="col-lg-6">
                      <div className="code-box-title  border-bottom border-secondary">
                        <strong>Vehicle Color</strong>
                      </div>
                      <Button
                        // onClick={this.addColorList}
                        onClick={this.showColorModal}
                        className="mt-2"
                        type="primary"
                        icon={<PlusSquareOutlined />}
                      >
                        Add Color
                      </Button>
                      <div className="row mx-0 mt-2 ">
                        <table id="color_list" className=" col-lg-12 px-1 table-color">
                          <tr>
                            <th className="text-center">Color Code</th>
                            <th className="text-center">Color Name</th>
                            <th className="text-center">Color Market Name</th>
                            <th className="text-center"></th>
                          </tr>
                          {this.state.color_list.map((r, index) => (
                            <tr id={index} key={index}>
                              <td className="px-2 py-0">{r.color_code}</td>
                              <td className="px-2 py-0">{r.color_name}</td>
                              <td className="px-2 py-0">{r.color_market_name}</td>
                              <td className="text-center">
                                <Button onClick={() => this.deleteColorList(r)} type="danger" icon={<DeleteOutlined />} className="px-2 me-1 my-1" />
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="code-box-title  border-bottom border-secondary">
                        <strong>Other Display </strong>
                      </div>
                      <Button
                        // onClick={this.addColorList}
                        onClick={this.showDisplayModal}
                        className="mt-2"
                        type="primary"
                        icon={<PlusSquareOutlined />}
                      >
                        Add Display
                      </Button>
                      <div className="row mx-0 mt-2 ">
                        <table id="display_list" className=" col-lg-12 px-1 table-color">
                          <tr>
                            <th className="text-center">Type</th>
                            <th className="text-center">Category Display</th>
                            <th className="text-center">Caption Display</th>
                            <th className="text-center"></th>
                          </tr>
                          {this.state.display_list.map((r, index) => (
                            <tr id={index} key={index}>
                              <td className="px-2 py-0">{r.display_type}</td>
                              <td className="px-2 py-0">{r.display_category}</td>
                              <td className="px-2 py-0">{r.display_caption}</td>
                              <td className="text-center">
                                <Button onClick={() => this.deleteDisplayList(r)} type="danger" icon={<DeleteOutlined />} className="px-2 me-1 my-1" />
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </TabPane>
                <TabPane
                  tab={
                    <Text>
                      <strong>Pricing & Distribution </strong>
                    </Text>
                  }
                  key="4"
                >
                  <div className="row mx-0" style={{ minHeight: 200, width: "100%" }}>
                    <div className="col-lg-3">
                      <SelectDistributionDestination value={this.state.inputDistributionDestination} onChangeValue={this.changeInputDistributionDestination} />
                    </div>
                    <div className="col-lg-12">
                      <div className="code-box-title  border-bottom border-secondary">
                        <strong>Pricing</strong>
                      </div>
                      <Button
                        // onClick={this.addColorList}
                        onClick={this.showPricingModal}
                        className="mt-2"
                        type="primary"
                        icon={<PlusSquareOutlined />}
                      >
                        Add Pricing
                      </Button>
                      <div className="row mx-0 mt-2 ">
                        <table id="color_pricing_list" className=" col-lg-12 px-1 table-color">
                          <tr>
                            <th className="text-center">Area</th>
                            <th className="text-center">Price Category</th>
                            <th className="text-center">Color Code</th>
                            <th className="text-center">Color Name</th>
                            <th className="text-center">Price Default</th>
                            <th className="text-center">Price Strikethrough</th>
                            <th className="text-center">Price Min</th>
                            <th className="text-center">Price Max</th>
                            <th className="text-center"></th>
                          </tr>
                          {this.state.pricing_list.map((r, index) => (
                            <tr id={index} key={index}>
                              <td className="px-2 py-0">{r.area_name}</td>
                              <td className="px-2 py-0">{r.price_category}</td>
                              <td className="px-2 py-0">{r?.color_code}</td>
                              <td className="px-2 py-0">{r?.color_name}</td>
                              <td className="px-2 py-0">{r.price_default}</td>
                              <td className="px-2 py-0">{r.price_strikethrough}</td>
                              <td className="px-2 py-0">{r.price_min}</td>
                              <td className="px-2 py-0">{r.price_max}</td>
                              <td className="text-center">
                                <Button onClick={() => this.deletePricingList(r)} type="danger" icon={<DeleteOutlined />} className="px-2 me-1 my-1" />
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </TabPane>
              </Tabs>

              <div className="row mx-0">
                <div className="col-lg-12">
                  <Form.Item wrapperCol={{}} className="mt-5">
                    <Button onClick={this.onClickSave} type="primary" loading={this.state.loadingButton}>
                      Launch Variant
                    </Button>
                    <Button onClick={this.onClickCancel} type="danger" className="mx-1" loading={this.state.loadingButton}>
                      Cancel
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>

            <Modal title="Add Color" visible={this.state.color_modal} onOk={this.addColorList} onCancel={this.closeColorModal} width={1000} className="custom-modal">
              <Form layout="vertical">
                <div className="row mx-0 py-0">
                  <div className="col-lg-4 px-1 py-1">
                    <Form.Item label="Color Code" className="my-1 py-1">
                      <Input type="text" value={this.state.inputColorCode} onChange={this.changeInputColorCode} placeholder="Color Code" />
                    </Form.Item>
                  </div>
                  <div className="col-lg-4 px-1 py-1">
                    <Form.Item label="Color Name" className="my-1 py-1">
                      <Input type="text" value={this.state.inputColorName} onChange={this.changeInputColorName} placeholder="Color Name" />
                    </Form.Item>
                  </div>
                  <div className="col-lg-4 px-1 py-1">
                    <Form.Item label="Market Name" className="my-1 py-1">
                      <Input type="text" value={this.state.inputColorMarketName} onChange={this.changeInputColorMarketName} placeholder="Color Market Name" />
                    </Form.Item>
                  </div>
                  <div className="col-lg-8 px-1 py-1">
                    <NoCrop params={this.state} onSaveImage={this.getBaseRawVariantColor} />
                  </div>
                </div>
              </Form>
            </Modal>

            <Modal title="Add Display" visible={this.state.display_modal} onOk={this.addDisplayList} onCancel={this.closeDisplayModal} width={1000}>
              <Form layout="vertical">
                <div className="row mx-0">
                  <div className="col-lg-4 ">
                    <SelectDisplayType value={this.state.inputDisplayType} onChangeValue={this.changeInputDisplayType} />
                  </div>
                  <div className="col-lg-4 ">
                    <SelectDisplayCategory value={this.state.inputDisplayCategory} onChangeValue={this.changeInputDisplayCategory} />
                  </div>
                  <div className="col-lg-4 ">
                    <Form.Item label="Caption Display" className="my-1 py-1">
                      <Input type="text" value={this.state.inputDisplayCaption} onChange={this.changeInputDisplayCaption} placeholder="Caption Display" />
                    </Form.Item>
                  </div>
                  <div className="col-lg-8 ">
                    <NoCrop params={this.state} onSaveImage={this.getBaseDisplay} />
                  </div>
                </div>
              </Form>
            </Modal>

            <Modal title="Add Pricing" visible={this.state.pricing_modal} onOk={this.addPricingList} onCancel={this.closePricingModal} width={1000}>
              <div className="row mx-0">
                <div className="col-lg-6">
                  <Form.Item label="Area" className="col-lg-12 my-1 py-1">
                    <Select style={{ fontSize: "85%", minWidth: "200px" }} placeholder="Select an Area" onChange={this.changeInputAreaDistribution} value={this.state.inputAreaDistribution}>
                      {this.state.dataOptionArea.map((d, i) => {
                        return (
                          <Option key={d.area} value={d.area} add={d.area}>
                            {d.area}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="row mx-0 px-0">
                <div className="col-lg-6">
                  <div className="code-box-title  border-bottom border-secondary my-2">
                    <strong>Model Price</strong>
                  </div>

                  <div className="row mx-0">
                    <div className="col-lg-6 mx-0 ps-0">
                      <Form.Item label="Price Default" className="my-1 py-0">
                        <Input type="number" value={this.state.inputModelPriceDefault} onChange={this.changeInputModelPriceDefault} placeholder="Price Default" />
                      </Form.Item>
                    </div>
                    <div className="col-lg-6 mx-0 ps-0">
                      <Form.Item label="Price Strikethrough" className="my-1 py-0">
                        <Input type="number" value={this.state.inputModelPriceStrikethrough} onChange={this.changeInputModelPriceStrikethrough} placeholder="Price Strikethrough" />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="row mx-0">
                    <div className="col-lg-6 mx-0 ps-0">
                      <Form.Item label="Price Min" className="my-1 py-0">
                        <Input type="number" value={this.state.inputModelPriceMin} onChange={this.changeInputModelPriceMin} placeholder="Price Min" />
                      </Form.Item>
                    </div>
                    <div className="col-lg-6 mx-0 ps-0">
                      <Form.Item label="Price Max" className="my-1 py-0">
                        <Input type="number" value={this.state.inputModelPriceMax} onChange={this.changeInputModelPriceMax} placeholder="Price Max" />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="code-box-title  border-bottom border-secondary my-2">
                    <strong>Variant Price</strong>
                  </div>

                  <div className="row mx-0">
                    <div className="col-lg-6 mx-0 ps-0">
                      <Form.Item label="Price Default" className="my-1 py-0">
                        <Input type="number" value={this.state.inputVariantPriceDefault} onChange={this.changeInputVariantPriceDefault} placeholder="Price Default" />
                      </Form.Item>
                    </div>
                    <div className="col-lg-6 mx-0 ps-0">
                      <Form.Item label="Price Strikethrough" className="my-1 py-0">
                        <Input type="number" value={this.state.inputVariantPriceStrikethrough} onChange={this.changeInputVariantPriceStrikethrough} placeholder="Price Strikethrough" />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="row mx-0">
                    <div className="col-lg-6 mx-0 ps-0">
                      <Form.Item label="Price Min" className="my-1 py-0">
                        <Input type="number" value={this.state.inputVariantPriceMin} onChange={this.changeInputVariantPriceMin} placeholder="Price Min" />
                      </Form.Item>
                    </div>
                    <div className="col-lg-6 mx-0 ps-0">
                      <Form.Item label="Price Max" className="my-1 py-0">
                        <Input type="number" value={this.state.inputVariantPriceMax} onChange={this.changeInputVariantPriceMax} placeholder="Price Max" />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mx-0 px-0">
                <div className="col-lg-12">
                  <div className="code-box-title  border-bottom border-secondary my-3">
                    <strong>Variant Color Price</strong>
                  </div>

                  <Checkbox onChange={this.changeCheckboxVariatPrice} checked={this.state.checkboxChecked}>
                    <strong>Same as Variant Price</strong>
                  </Checkbox>

                  <div className="row mx-0 mt-2 ">
                    <table id="color_list" className=" col-lg-12 px-1 table-color">
                      <tr>
                        <th className="text-center">&nbsp;Color&nbsp;Code&nbsp;</th>
                        <th className="text-center">&nbsp;Color&nbsp;Name&nbsp;</th>
                        <th className="text-center">Price Default</th>
                        <th className="text-center">Price Strikethrough</th>
                        <th className="text-center">Price Min</th>
                        <th className="text-center">Price Max</th>
                      </tr>
                      {this.state.color_pricing_list.map((r, index) => (
                        <tr id={index} key={index}>
                          <td className="px-2 py-0">{r.color_code}</td>
                          <td className="px-2 py-0">{r.color_name}</td>
                          <td>
                            <Form.Item className="mx-1 px-1 pt-1 pb-0 mb-1">
                              <Input type="number" onChange={(e) => this.addColorPrice("price_default", e, index)} value={r.price_default} placeholder="Price Default" />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item className="mx-1 px-1 pt-1 pb-0 mb-1">
                              <Input type="number" onChange={(e) => this.addColorPrice("price_strikethrough", e, index)} value={r.price_strikethrough} placeholder="Price Strikethrough" />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item className="mx-1 px-1 pt-1 pb-0 mb-1">
                              <Input type="number" onChange={(e) => this.addColorPrice("price_min", e, index)} value={r.price_min} placeholder="Price Min" />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item className="mx-1 px-1 pt-1 pb-0 mb-1">
                              <Input type="number" onChange={(e) => this.addColorPrice("price_max", e, index)} value={r.price_max} placeholder="Price Max" />
                            </Form.Item>
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
          <Spin id="me_spinner" tip="Loading..." size="large" className={this.state.showHideSpiner}></Spin>
        </LayoutDefault>
      </React.Fragment>
    );
  }
}

export default MasterVehicleVariant;
