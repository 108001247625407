import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";


import Login from "./components/screen/login/login.component";
import Dashboard from "./components/screen/dashboard/dashboard.component";

import MasterBusinessEntity from "./components/screen/business-entity/business-entity.component";
import MasterVehicleBrand from "./components/screen/vehicle-brand/vehicle-brand.component";
import MasterVehicleModel from "./components/screen/vehicle-model/vehicle-model.component";
import MasterVehicleVariant from "./components/screen/vehicle-variant/vehicle-variant.component";
import MasterVehiclePart from "./components/screen/vehicle-part/vehicle-part.component";
import MasterVehiclePartType from "./components/screen/vehicle-part/vehicle-part-type.component";

import ImportVehicleModel from "./components/screen/vehicle-model/import-vehicle-model.component";
import ImportVehicleVariant from "./components/screen/vehicle-variant/import-vehicle-variant.component";
import ImportVehiclePart from "./components/screen/vehicle-part/import-vehicle-part.component";
import ImportVehiclePartPrice from "./components/screen/vehicle-part/import-vehicle-part-default-price.component";

import MultiUpdateVehicleModel from "./components/screen/vehicle-model/multi-update-vehicle-model.component";

import LaunchVariant from "./components/screen/vehicle-variant/launch-vehicle-variant.component";
import PrivateRoute from "./PrivateRoute";

class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <PrivateRoute exact path="/" component={Dashboard} />
          <Route exact path="/login" component={Login} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/master/business-entity" component={MasterBusinessEntity} />
          <PrivateRoute exact path="/master/vehicle-brand" component={MasterVehicleBrand} />
          <PrivateRoute exact path="/master/vehicle-model" component={MasterVehicleModel} />
          <PrivateRoute exact path="/master/vehicle-variant" component={MasterVehicleVariant} />
          <PrivateRoute exact path="/master/vehicle-part-type" component={MasterVehiclePartType} />
          <PrivateRoute exact path="/master/vehicle-part" component={MasterVehiclePart} />
          <PrivateRoute exact path="/import/vehicle-model" component={ImportVehicleModel} />
          <PrivateRoute exact path="/import/vehicle-variant" component={ImportVehicleVariant} />
          <PrivateRoute exact path="/import/vehicle-part" component={ImportVehiclePart} />
          <PrivateRoute exact path="/import/vehicle-part-price" component={ImportVehiclePartPrice} />
          <PrivateRoute exact path="/update/multi-vehicle-model" component={MultiUpdateVehicleModel} />
          <PrivateRoute exact path="/launch/vehicle-variant" component={LaunchVariant} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;