import axios from "axios";
import { baseUrlAutotrimitraPart } from "../config/api-url.config";

class VehiclePartService {
  axios = axios.create({
    baseURL: baseUrlAutotrimitraPart,
  });

  setToken(token) {
    this.axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }

  async getPartTypeList(params = {}) {
    try {

      const queries = {
        // key: apikeyAutotrimitraBizApi,
        ...(params?.part_type && {
          part_type: params.part_type,
        }),
        ...(params?.category && {
          category: params.category,
        }),
      }

      return await this.axios.get("vehicle/part-type", {
        params: {
          ...queries,
        }
      });
    } catch (e) {
      const { response } = e;
      // console.log(response);
      const { request, ...errorObject } = response;
      throw new Error(errorObject.data.error.message);
    }
  }

  async getPartList(params = {}) {
    try {

      const queries = {
        // key: apikeyAutotrimitraBizApi,
        ...(params?.category && {
          category: params.category,
        }),
        ...(params?.part_type_uuid && {
          part_type_uuid: params.part_type_uuid,
        }),
        ...(params?.brand_uuid && {
          brand_uuid: params.brand_uuid,
        }),
        ...(params?.variant_code && {
          variant_code: params.variant_code,
        }),
        ...(params?.part_number && {
          part_number: params.part_number,
        }),
        ...(params?.last_uuid && {
          last_uuid: params.last_uuid,
        })
      }

      return await this.axios.get("vehicle/parts", {
        params: {
          ...queries,
        }
      });
    } catch (e) {
      const { response } = e;
      // console.log(response);
      const { request, ...errorObject } = response;
      throw new Error(errorObject.data.error.message);
    }
  }

}

export const vehiclePartService = new VehiclePartService();
