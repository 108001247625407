import React, { Component } from "react";
import Cookies from "universal-cookie";
import { HotTable } from "@handsontable/react";
import LayoutDefault from "../../layout/layout-default.component";

import SelectCategory from "../../layout/select-category.component";

import { vehicleServicesPut } from "../../../services/vehicle-part-put.services";
import { modalError, modalInfo } from "../../../helpers/modal.helper";

import { Button, Form } from "antd";

import "handsontable/dist/handsontable.full.css";
import FormItem from "antd/lib/form/FormItem";

class ImportVehiclePartPrice extends Component {
  cookies = new Cookies();

  constructor(props) {
    super(props);

    this.initState = {
      isValidTable: true,
      loadingButton: false,
      inputValueCategory: "",
    };

    this.state = { ...this.initState };
    this.hotTableComponent = React.createRef();
    this.settingHandsontable = {
      rowHeaders: true,
      colWidths: 200,
      height: 400,
      colHeaders: ["Part Number", "Price Default"],
      columns: [
        { data: "part_number", type: "text" },
        { data: "price_default", type: "text" },
      ],
    };
  }

  componentDidMount = () => {
    this.onClickRefreshTable();
  };

  afterValidate = (isValid) => {
    this.setState({
      isValidTable: isValid,
    });
  };

  onClickRefreshTable = () => {
    let emptyData = [];
    this.hotTableComponent.current.hotInstance.loadData([]);
    for (let i = 0; i < 15; i++) {
      emptyData.push(["", "", "", "", "", "", "", "", "", "", "", "", ""]);
    }
    this.hotTableComponent.current.hotInstance.loadData(emptyData);
    console.log("countRows", this.hotTableComponent.current.hotInstance.countRows());

    this.setState({
      loadingButton: false,
    });
  };

  onClickUploadData = async () => {
    const { isValidTable, inputValueCategory } = this.state;

    if (!isValidTable) {
      modalError({
        title: "Validation Error",
        content: "Data Table Not Valid",
      });
      return false;
    }

    let dataTable = this.hotTableComponent.current.hotInstance.getData();
    let notValidInput = [];
    let dataCleaned = [];

    if (inputValueCategory) {
      for (const row of dataTable) {
        const part_number = row[0];
        const price_default = row[1] ? Number(row[1]) : 0;

        if (part_number && price_default && price_default > 0) {
          dataCleaned.push({
            part_number: part_number,
            category: inputValueCategory,
            price_default: price_default,
          });
        }
      }
    } else {
      modalError({
        title: "Validation Error",
        content: "Empty Category ",
      });
      return false;
    }

    if (notValidInput.length > 0) {
      modalError({
        title: "Validation Error",
        content: (
          <div>
            {notValidInput.map((d, i) => {
              return (
                <div>
                  {d}
                  <br />
                </div>
              );
            })}
          </div>
        ),
      });
      return false;
    }

    if (dataCleaned.length > 0) {
      try {
        this.setState({
          loadingButton: true,
        });

        const token = this.cookies.get("_t_autotrimitrabiz");
        await vehicleServicesPut.setToken(token);
        const upload = await vehicleServicesPut.updateDefaultPrice(dataCleaned);
        // console.log(upload.data.data);

        modalInfo({
          title: "Data Processed",
          width: 800,
          content: (
            <div className="table-responsive" style={{ height: 200 }}>
              <table className="table-simple table-sm" style={{ fontSize: "83%" }}>
                <tbody>
                  <tr>
                    <th>Part Number</th>
                    <th>Description</th>
                  </tr>
                  {upload.data.data.error.map((row) => {
                    return (
                      <tr key={row.part_number}>
                        <td>{row.part_number}</td>
                        <td className="text-danger">FAILED - {row.description}</td>
                      </tr>
                    );
                  })}
                  {upload.data.data.success.map((row) => {
                    return (
                      <tr key={row.part_number}>
                        <td>{row.part_number}</td>
                        <td className="text-success">SUCCESS</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ),
        });
        this.onClickRefreshTable();
      } catch (error) {
        this.setState({
          loadingButton: false,
        });

        const multi_message = error.message.split(",");
        if (multi_message.length > 0) {
          modalError({
            title: "Process Error",
            content: (
              <div>
                {multi_message.map((d, i) => {
                  return (
                    <div>
                      {d}
                      <br />
                    </div>
                  );
                })}
              </div>
            ),
          });
        } else {
          modalError({
            title: "Process Error",
            content: error.message,
          });
        }
      }
    } else {
      modalError({
        title: "Validation Error",
        content: "Empty Table",
      });
    }
  };

  changeInputCategory = async (v) => {
    await this.setState({
      inputValueCategory: v,
    });
  };

  render() {
    return (
      <React.Fragment>
        <LayoutDefault defaultOpenKeys={["m-import"]} defaultSelectedKeys={["m-import-vehicle-part-price"]}>
          <div className="row px-3">
            <Form name="form-filter" layout="vertical" className="pt-2">
              <div className="row mx-0">
                <div className="col-lg-11">
                  <div className="row px-2">
                    <div className="col-lg-2 px-1">
                      <SelectCategory value={this.state.inputValueCategory} onChangeValue={this.changeInputCategory} />
                    </div>
                    <div className="col-lg-3 px-1 pt-2">
                      <span className="mb-3 me-0">
                        <FormItem label=" ">
                          <Button
                            onClick={this.onClickUploadData}
                            title="add"
                            type="primary"
                            style={{
                              background: "#1784b8",
                              borderColor: "#1784b8",
                            }}
                            loading={this.state.loadingButton}
                            className="custom-class"
                          >
                            <span>
                              <strong style={{ fontSize: "100%" }}>+&nbsp;</strong> Update Part
                            </span>
                          </Button>
                          &nbsp;
                          <Button
                            onClick={this.onClickRefreshTable}
                            title="add"
                            type="danger"
                            style={{
                              background: "#b81717",
                              borderColor: "#b81717",
                            }}
                            loading={this.state.loadingButton}
                            className="custom-class"
                          >
                            <span>Refresh Table</span>
                          </Button>
                        </FormItem>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Form>

            <div className="col-lg-12">
              <HotTable ref={this.hotTableComponent} settings={this.settingHandsontable} afterValidate={this.afterValidate} />
            </div>
          </div>
        </LayoutDefault>
      </React.Fragment>
    );
  }
}

export default ImportVehiclePartPrice;
