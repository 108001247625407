import React, { Component } from "react";
import { config } from "../../config/general.config";
import { Form, Select } from "antd";

const { Option } = Select;

class SelectTemplateImage extends Component {
  initState = {
    dataOption: config.enum_template_generate_image,
  };
  state = { ...this.initState };

  componentDidMount = async () => {
    try {
    } catch (error) {
      this.setState({
        dataOption: [],
      });
    }
  };

  render() {
    return (
      <Form.Item label="Template Generate Image" className="col-lg-12 my-1 py-1" labelCol={this.props.labelCol} wrapperCol={this.props.wrapperCol}>
        <Select style={{ fontSize: "85%", minWidth: "200px" }} placeholder="Select Template Generate Image" value={this.props.value} onChange={this.props.onChangeValue} mode={this.props.mode}>
          {this.state.dataOption.map((d, i) => {
            return (
              <Option key={d} value={d}>
                {d}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  }
}

export default SelectTemplateImage;
