import React, { Component } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Form } from "antd";

class EditorAbout extends Component {
  render() {
    return (
      <Form.Item label="About" className="my-1 py-1">
        <ReactQuill theme="snow" value={this.props.value} onChange={this.props.onChange} style={{ height: "100%" }} />
      </Form.Item>
    );
  }
}

export default EditorAbout;
