import React, { Component } from "react";
import Cookies from "universal-cookie";
import { v4 as uuidv4 } from "uuid";
import LayoutDefault from "../../layout/layout-default.component";
import { vehicleServices } from "../../../services/vehicle.services";
import { vehicleServicesPost } from "../../../services/vehicle-post.services";
import { modalError, modalSuccess } from "../../../helpers/modal.helper";

import { Form, Input, Table, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";

class MasterVehicleBrand extends Component {
  cookies = new Cookies();

  constructor(props) {
    super(props);

    this.initState = {
      processing: false,
      processingFetchTableData: false,

      pageSize: 100,
      filterValueBrand: "",

      tableSelectedRowKeys: [],
      dataBrand: [],
      titleCanvas: "Default Title",
      inputImage: [],
      validImage: false,
      brand_name: "",
      country: "",
      founded_year: "",
      url_info: "",
      url_image: "",
      loadingButton: false,
      showHideReadCanvas: "d-block",
      showHideFormCanvas: "m-2 px-1 d-none",
      showHideButtonForm: "me-1",
      formEdit: false,
    };

    this.state = { ...this.initState };
  }

  tableColumn = [
    {
      title: "UUID",
      dataIndex: "brand_uuid",
      key: "brand_uuid",
    },
    {
      title: "Brand Name",
      dataIndex: "brand_name",
      key: "brand_name",
    },
    {
      title: "Founded",
      dataIndex: "founded_year",
      key: "founded_year",
    },
    {
      title: "Brand Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Action",
      dataIndex: "url_info",
      key: "url_info",
      width: 300,
      render: (text, record) => {
        return (
          <span>
            <span
              style={{ display: "table-cell", color: "#1890ff", cursor: "pointer" }}
              onClick={() => {
                this.onClickEditBrand(record);
              }}
            >
              Detail Info
            </span>
            &nbsp;|&nbsp;
            <a style={{ display: "table-cell" }} href={record.url_image} target="_blank" rel="noreferrer">
              Link Image
            </a>
          </span>
        );
      },
    },
  ];

  changeFilterBrand = (v) => {
    this.setState({
      filterValueBrand: v.target.value,
    });
  };

  fetchDataOrder = async () => {
    try {
      const filter = this.state;

      this.setState({
        processingFetchTableData: true,
        loadingButton: true,
      });

      const params = {};

      if (filter.filterValueBrand !== "") {
        params.brand_name = filter.filterValueBrand;
      }

      const dataBrand = await vehicleServices.getBrandList(params);
      // const dataBrand = await dealerServices.getDealer();
      // console.log(dataBrand.data.data);

      this.setState({
        dataBrand: dataBrand.data.data,
        processingFetchTableData: false,
        loadingButton: false,
        tableSelectedRowKeys: [],
      });
    } catch (error) {
      this.setState({
        dataBrand: [],
        processingFetchTableData: false,
        loadingButton: false,
        tableSelectedRowKeys: [],
      });
    }
  };

  onClickFilter = async () => {
    await this.fetchDataOrder();
  };

  onClickAddBrand = async () => {
    this.setState({
      titleCanvas: "Add New Brand",
      showHideReadCanvas: "d-none",
      showHideFormCanvas: "m-2 px-1 d-block",
      showHideButtonForm: "me-1",
      formEdit: false,
    });
  };

  onClickEditBrand = async (r) => {
    this.setState({
      titleCanvas: "Detail Info",
      showHideReadCanvas: "d-none",
      showHideFormCanvas: "m-2 px-1 d-block",
      brand_name: r.brand_name,
      country: r.country,
      founded_year: r.founded_year,
      url_info: r.url_info,
      url_image: r.url_image,
      showHideButtonForm: "d-none",
      formEdit: true,
    });
  };

  onClickCancel = async () => {
    this.setState({
      brand_name: "",
      country: "",
      founded_year: "",
      url_info: "",
      url_image: "",
      showHideReadCanvas: "d-block",
      showHideFormCanvas: "m-2 px-1 d-none",
      loadingButton: false,
    });
  };

  changeInputBrandName = (v) => {
    this.setState({
      brand_name: v.target.value,
    });
  };

  changeInputCountry = (v) => {
    this.setState({
      country: v.target.value,
    });
  };

  changeInputFoundedYear = (v) => {
    this.setState({
      founded_year: v.target.value,
    });
  };

  changeInputUrlInfo = (v) => {
    this.setState({
      url_info: v.target.value,
    });
  };

  changeInputUrlImage = (v) => {
    this.setState({
      url_image: v.target.value,
    });
  };

  onClickSave = async () => {
    if (this.state.formEdit) {
      return false;
    }
    const postData = {
      uuid: uuidv4(),
      name: this.state.brand_name.toString().toLowerCase(),
      country: this.state.country,
      founded_year: this.state.founded_year,
      url_info: this.state.url_info,
      url_image: this.state.url_image,
    };

    if (!postData.name) {
      modalError({
        title: "Validation Error",
        content: "Invalid Brand Name",
      });
      return false;
    }
    if (!postData.country) {
      modalError({
        title: "Validation Error",
        content: "Invalid Brand Country",
      });
      return false;
    }
    if (!postData.founded_year) {
      modalError({
        title: "Validation Error",
        content: "Invalid Founded In",
      });
      return false;
    }

    this.setState({
      loadingButton: true,
    });

    try {
      const token = this.cookies.get("_t_autotrimitrabiz");
      await vehicleServicesPost.setToken(token);
      await vehicleServicesPost.addBrand([postData]);
      this.onClickCancel();
      modalSuccess({
        content: "SUCCESS",
      });
    } catch (error) {
      this.setState({
        loadingButton: false,
      });
      modalError({
        title: "Process Error",
        content: error.message,
      });
    }
  };

  render() {
    const rowTableSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          tableSelectedRowKeys: selectedRows,
        });
      },
    };

    return (
      <React.Fragment>
        <LayoutDefault defaultOpenKeys={["sm-master"]} defaultSelectedKeys={["m-master-vehicle-brand"]}>
          <div id="read_data_canvas" className={this.state.showHideReadCanvas}>
            <div className="row mx-0">
              <div className="col-lg-10 pt-2">
                <Button onClick={this.onClickAddBrand} title="add" type="primary" style={{ background: "#1784b8", borderColor: "#1784b8" }} loading={this.state.loadingButton}>
                  <span>
                    <strong style={{ fontSize: "100%" }}>+&nbsp;</strong> Add Brand
                  </span>
                </Button>
              </div>
              <div className="col-lg-2 mb-1">
                <Form name="form-filter" layout="vertical" className="me-3 pt-2">
                  <div className="row px-2">
                    <div className="col-lg-11 col-11 px-0">
                      <Form.Item name="brand_name" className="col-lg-12 mb-1">
                        <Input onChange={this.changeFilterBrand} placeholder="Brand Name" />
                      </Form.Item>
                    </div>
                    <div className="col-lg-1 col-1 px-0">
                      <Button
                        onClick={this.onClickFilter}
                        type="primary"
                        icon={<SearchOutlined />}
                        title="filter"
                        className="px-2 me-1 mb-1"
                        style={{
                          background: "#36383a",
                          borderColor: "#36383a",
                          width: "40px",
                        }}
                        loading={this.state.loadingButton}
                      ></Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>

            <Table
              rowSelection={rowTableSelection}
              rowKey={"brand_uuid"}
              size={"small"}
              loading={this.state.processingFetchTableData}
              columns={this.tableColumn}
              dataSource={this.state.dataBrand}
              pagination={{ pageSize: this.state.pageSize }}
              onChange={this.onChangeTable}
            />
          </div>
          <div id="add_edit_canvas" className={this.state.showHideFormCanvas}>
            <h4 style={{ textTransform: "capitalize" }}>{this.state.titleCanvas}</h4>
            <hr />
            <Form layout="horizontal" labelCol={{ span: 2 }} wrapperCol={{ span: 10 }} ref={this.formInput}>
              <Form.Item label="Brand Name" className="my-1 py-1">
                <Input name="brand_name" value={this.state.brand_name} onChange={this.changeInputBrandName} placeholder="Brand Name" />
              </Form.Item>
              <Form.Item label="Brand Country" className="my-1 py-1">
                <Input name="country" value={this.state.country} onChange={this.changeInputCountry} placeholder="Brand Country" />
              </Form.Item>
              <Form.Item label="Founded In" className="my-1 py-1">
                <Input type="number" name="founded_year" value={this.state.founded_year} onChange={this.changeInputFoundedYear} placeholder="Year" />
              </Form.Item>
              <Form.Item label="Link Info" className="my-1 py-1">
                <Input name="url_info" value={this.state.url_info} onChange={this.changeInputUrlInfo} placeholder="Link Wiki" />
              </Form.Item>
              <Form.Item label="Link Image" className="my-1 py-1">
                <Input name="url_image" value={this.state.url_image} onChange={this.changeInputUrlImage} placeholder="Link Image" />
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  sm: { span: 16, offset: 2 },
                }}
                className="mt-3"
              >
                <Button onClick={this.onClickSave} type="primary" className={this.state.showHideButtonForm} style={{ background: "#1784b8", borderColor: "#1784b8" }} loading={this.state.loadingButton}>
                  Save Brand
                </Button>
                <Button onClick={this.onClickCancel} type="danger" className="" loading={this.state.loadingButton}>
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </div>
        </LayoutDefault>
      </React.Fragment>
    );
  }
}

export default MasterVehicleBrand;
