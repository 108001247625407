import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Cookies from "universal-cookie";

import ButtonLogout from "./button-logout.component";

import { Layout, Menu } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined, AppstoreOutlined, UploadOutlined, EditOutlined, DiffOutlined } from "@ant-design/icons";

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

class LayoutDefault extends Component {
  cookies = new Cookies();

  constructor(props) {
    super(props);
    // console.log("Contruct ");

    const token = this.cookies.get("_t_autotrimitrabiz");
    if (token) {
      this.state = {
        username: atob(this.cookies.get("_u_autotrimitrabiz")),
        collapsed: false,
        showHideComp: "block",
      };
    }
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
      showHideComp: this.state.showHideComp === "none" ? "block" : "none",
    });
  };

  render() {
    const { defaultSelectedKeys, defaultOpenKeys, children } = this.props;

    return (
      <Layout>
        <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
          <div className="logo p-3 text-white">
            <div className="d-flex">
              <div style={{ display: this.state.showHideComp }}>Autotrimitra</div>
            </div>
          </div>
          <Menu theme="dark" mode="inline" defaultSelectedKeys={defaultSelectedKeys} defaultOpenKeys={defaultOpenKeys} collapsed={this.state.collapsed}>
            <Menu.Item key="m-dashboard" icon={<AppstoreOutlined />}>
              <Link to={"/dashboard"}>Dashboard</Link>
            </Menu.Item>
            <SubMenu key="sm-master" title="Master" icon={<AppstoreOutlined />}>
              <Menu.Item key="m-master-business-entity">
                <Link to={"/master/business-entity"}>Business Entity</Link>
              </Menu.Item>
              <Menu.Item key="m-master-vehicle-brand">
                <Link to={"/master/vehicle-brand"}>Vehicle Brand</Link>
              </Menu.Item>
              <Menu.Item key="m-master-vehicle-model">
                <Link to={"/master/vehicle-model"}>Vehicle Model</Link>
              </Menu.Item>
              <Menu.Item key="m-master-vehicle-variant">
                <Link to={"/master/vehicle-variant"}>Vehicle Variant</Link>
              </Menu.Item>
              <Menu.Item key="m-master-vehicle-part-type">
                <Link to={"/master/vehicle-part-type"}>Vehicle Part Type</Link>
              </Menu.Item>
              <Menu.Item key="m-master-vehicle-part">
                <Link to={"/master/vehicle-part"}>Vehicle Part</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="m-import" icon={<UploadOutlined />} title="Import">
              <Menu.Item key="m-import-vehicle-model">
                <Link to={"/import/vehicle-model"}>Import Vehicle Model</Link>
              </Menu.Item>
              <Menu.Item key="m-import-vehicle-variant">
                <Link to={"/import/vehicle-variant"}>Import Vehicle Variant</Link>
              </Menu.Item>
              <Menu.Item key="m-import-vehicle-part">
                <Link to={"/import/vehicle-part"}>Import Vehicle Part</Link>
              </Menu.Item>
              <Menu.Item key="m-import-vehicle-part-price">
                <Link to={"/import/vehicle-part-price"}>Import Vehicle Part Price</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="m-multi-update" icon={<EditOutlined />} title="Multi Update">
              <Menu.Item key="m-multi-update-vehicle-model">
                <Link to={"/update/multi-vehicle-model"}>Update Vehicle Model</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="m-launch" icon={<DiffOutlined />} title="Launch">
              <Menu.Item key="m-launch-vehicle-variant">
                <Link to={"/launch/vehicle-variant"}>Vehicle Variant</Link>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            <div className="d-flex justify-content-between pe-3">
              <div>
                {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                  className: "trigger",
                  onClick: this.toggle,
                })}
              </div>
              <div className="">
                <div className="ml-auto">
                  <span className="mt-3 mr-3 text-capitalize">Hi,&nbsp;{this.state.username}</span>
                  &nbsp;
                  <ButtonLogout />
                </div>
              </div>
            </div>
          </Header>
          <Content className="site-layout-background m-3">{children}</Content>
        </Layout>
      </Layout>
    );
  }
}

export default withRouter(LayoutDefault);
