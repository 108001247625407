import React, { Component } from "react";
import { config } from "../../config/general.config";
import { Form, Select } from "antd";

const { Option } = Select;

class SelectFeedbackType extends Component {
  initState = {
    dataOption: config.enum_feedback_type,
  };
  state = { ...this.initState };

  componentDidMount = async () => {
    try {
    } catch (error) {
      this.setState({
        dataOption: [],
      });
    }
  };

  render() {
    return (
      <Form.Item label="Feedback Type" className="col-lg-12 my-1 py-1">
        <Select style={{ fontSize: "85%", minWidth: "200px" }} placeholder="Select Feedback Type" onChange={this.props.onChange} mode={this.props.mode}>
          {this.state.dataOption.map((d, i) => {
            return (
              <Option key={d} value={d}>
                {d?.toString()?.toUpperCase()}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  }
}

export default SelectFeedbackType;
