import React, { Component } from "react";
import LayoutDefault from "../../layout/layout-default.component";

class Dashboard extends Component {
  render() {
    return (
      <React.Fragment>
        <LayoutDefault defaultSelectedKeys={["m-dashboard"]}>
          <div className="row col-lg-12 px-4 py-3">Welcome</div>
        </LayoutDefault>
      </React.Fragment>
    );
  }
}

export default Dashboard;
