import axios from "axios";
import qs from "qs";

import { baseUrlAuth, apikeybaseUrlAuth } from "../config/api-url.config";

class AuthServices {

  axios = axios.create({
    baseURL: baseUrlAuth,
    headers: { "X-API-KEY": apikeybaseUrlAuth }
  });

  setToken(token) {
    this.axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }

  async login(params) {
    try {

      params.app_code = "autotrimitra";
      const data = qs.stringify(params);
      const config = {
        method: 'post',
        url: '/auth/login',
        headers: {
          'X-API-KEY': apikeybaseUrlAuth,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: data
      };

      return await this.axios(config);
    } catch (e) {
      const { response } = e;
      throw new Error(response.data.message);
    }
  }
}

export const authServices = new AuthServices();
