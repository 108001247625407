import Cookies from "universal-cookie";
import { PoweroffOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";

const ButtonLogout = (props) => {
  const cookies = new Cookies();

  function onPressed() {
    const c = cookies.get("_t_autotrimitrabiz");
    if (c) {
      cookies.remove("_t_autotrimitrabiz");
      cookies.remove("_u_autotrimitrabiz");
      cookies.remove("_ls_autotrimitrabiz");
    }
    // console.log(c);
    props.history.push("/login");
  }
  return <PoweroffOutlined onClick={onPressed} className="ms-2" />;
};

export default withRouter(ButtonLogout);
