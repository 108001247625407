import React, { Component } from "react";
import Cookies from "universal-cookie";
import { HotTable } from "@handsontable/react";
import LayoutDefault from "../../layout/layout-default.component";

import SelectBrand from "../../layout/select-brand.component";
import SelectCategory from "../../layout/select-category.component";

import { vehicleServices } from "../../../services/vehicle.services";
import { config } from "../../../config/general.config";
import { vehicleServicesPost } from "../../../services/vehicle-post.services";
import { modalError, modalSuccess } from "../../../helpers/modal.helper";

import { Button, Form } from "antd";

import "handsontable/dist/handsontable.full.css";

class MultiUpdateVehicleModel extends Component {
  cookies = new Cookies();

  constructor(props) {
    super(props);

    this.initState = {
      isValidTable: true,
      loadingButton: false,
      inputValueBrandUUID: "",
      inputValueCategory: "",
    };

    this.state = { ...this.initState };
    this.hotTableComponent = React.createRef();
    this.settingHandsontable = {
      rowHeaders: true,
      colWidths: 150,
      width: "100%",
      height: 400,
      colHeaders: ["Model UUID", "Model Class", "Engine Capacity", "Min Engine Capacity", "Max Engine Capacity", "Fuel Type", "Fuel Supply System", "Transmission", "Segment", "Link Info", "Link Image"],
      columns: [
        { data: "model_uuid", type: "text" },
        { data: "class", type: "text" },
        { data: "engine_capacity", type: "text" },
        { data: "min_engine_capacity", type: "text" },
        { data: "max_engine_capacity", type: "text" },
        { data: "fuel_type", type: "text" },
        { data: "fuel_supply_system", type: "text" },
        { data: "transmission", type: "text" },
        { data: "segment", type: "text" },
        { data: "link_wiki", type: "text" },
        { data: "link_image", type: "text" },
      ],
    };
  }

  changeInputValueBrand = (v, i) => {
    this.setState({
      inputValueBrandUUID: v,
    });
  };

  changeInputCategory = (v) => {
    this.setState({
      inputValueCategory: v,
    });
  };

  componentDidMount = () => {
    this.onClickRefreshTable();
  };

  afterValidate = (isValid) => {
    this.setState({
      isValidTable: isValid,
    });
  };

  onClickRefreshTable = () => {
    let emptyData = [];
    this.hotTableComponent.current.hotInstance.loadData([]);
    for (let i = 0; i < 15; i++) {
      emptyData.push(["", "", "", "", "", "", "", "", "", "", "", "", ""]);
    }
    this.hotTableComponent.current.hotInstance.loadData(emptyData);
    console.log("countRows", this.hotTableComponent.current.hotInstance.countRows());

    this.setState({
      loadingButton: false,
    });
  };

  onClickUploadData = async () => {
    const { isValidTable, inputValueBrandUUID, inputValueCategory } = this.state;
    console.log(this.state.inputValueBrandUUID);

    if (!isValidTable) {
      modalError({
        title: "Validation Error",
        content: "Data Table Not Valid",
      });
      return false;
    }

    if (!inputValueCategory) {
      modalError({
        title: "Validation Error",
        content: "Invalid Category",
      });
      return false;
    }
    if (!inputValueBrandUUID) {
      modalError({
        title: "Validation Error",
        content: "Invalid Brand",
      });
      return false;
    }

    let dataTable = this.hotTableComponent.current.hotInstance.getData();
    let notValidInput = [];
    const dataModel = await vehicleServices.getModelList({
      category: inputValueCategory,
      brand_uuid: inputValueBrandUUID,
    });
    const listModel = {};
    for (const b of dataModel.data.data) {
      listModel[b.model_uuid] = b;
    }

    let dataCleaned = [];
    let i = 1;
    for (const row of dataTable) {
      const model_uuid = row[0] ? row[0].toString().toLowerCase() : null;
      if (model_uuid) {
        if (listModel[model_uuid]) {
          const model_name = listModel[model_uuid].model_name;
          const brand_uuid = listModel[model_uuid].brand_uuid;
          const brand_name = listModel[model_uuid].brand_name;
          const market_name = listModel[model_uuid].market_name;
          const series_name = listModel[model_uuid].series_name;
          const category = listModel[model_uuid].category;
          const model_class = row[1] ? row[1].trim().toString().toLowerCase() : null;
          const engine_capacity = row[2] ? Number(row[2]) : 0;
          const min_engine_capacity = row[3] ? Number(row[3]) : 0;
          const max_engine_capacity = row[4] ? Number(row[4]) : 0;
          const fuel_type = row[5] ? row[5].trim().toString().toLowerCase().split(",") : null;
          const fuel_supply_system = row[6] ? row[6].trim().toString().toLowerCase().split(",") : null;
          const transmission = row[7] ? row[7].trim().toString().toLowerCase().split(",") : null;
          const segment = row[8] ? row[8].trim().toString().toUpperCase() : null;
          const link_wiki = row[9] ? row[9].trim() : null;
          const link_image = row[10] ? row[10].trim() : null;
          let obj_fuel = {};
          let obj_fuel_supply_system = {};
          let obj_transmission = {};

          // console.log(fuel_supply_system);

          if (fuel_type) {
            for (let ft = 0; ft < fuel_type.length; ft++) {
              const fuel = fuel_type[ft];
              if (fuel) {
                if (!config.enum_fuel.includes(fuel)) {
                  notValidInput.push("Fuel Type Not Listed On Line " + i);
                } else {
                  obj_fuel[fuel] = true;
                }
              }
            }
          }

          if (fuel_supply_system) {
            for (let fuel = 0; fuel < fuel_supply_system.length; fuel++) {
              const f = fuel_supply_system[fuel];
              if (f) {
                if (!config.enum_fuel_supply_system.includes(f)) {
                  notValidInput.push("Fuel Supply System Not Listed On Line " + i);
                } else {
                  obj_fuel_supply_system[f] = true;
                }
              }
            }
          }

          if (transmission) {
            for (let t = 0; t < transmission.length; t++) {
              const tra = transmission[t];
              if (tra) {
                if (!config.enum_transmission.includes(tra)) {
                  notValidInput.push("Transmission Not Listed On Line " + i);
                } else {
                  obj_transmission[tra] = true;
                }
              }
            }
          }

          if (segment) {
            if (!config.enum_segment.includes(segment)) {
              notValidInput.push("Segment Not Listed On Line " + i);
            }
          }

          if (notValidInput.length < 1) {
            dataCleaned.push({
              uuid: model_uuid,
              name: model_name,
              market_name: market_name,
              category: category,
              class: model_class,
              engine_capacity: engine_capacity,
              engine_capacity_minimum: min_engine_capacity,
              engine_capacity_maximum: max_engine_capacity,
              fuel: obj_fuel,
              fuel_supply_system: obj_fuel_supply_system,
              transmission: obj_transmission,
              year_production: null,
              year_first_production: null,
              year_latest_production: null,
              url_image: link_image,
              url_info: link_wiki,
              series_name: series_name,
              brand_uuid: brand_uuid,
              brand_name: brand_name,
              segment: segment,
            });
          }
        } else {
          notValidInput.push("Model Not listed On Line " + i);
        }
      }

      i++;
    }

    if (notValidInput.length > 0) {
      modalError({
        title: "Validation Error",
        content: (
          <div>
            {notValidInput.map((d, i) => {
              return (
                <div>
                  {d}
                  <br />
                </div>
              );
            })}
          </div>
        ),
      });
      return false;
    }

    if (dataCleaned.length > 0) {
      try {
        this.setState({
          loadingButton: true,
        });
        const token = this.cookies.get("_t_autotrimitrabiz");
        await vehicleServicesPost.setToken(token);
        await vehicleServicesPost.addModel(dataCleaned);
        modalSuccess({
          title: "Data Processed",
          width: 800,
          content: "SUCCESS",
        });
        this.onClickRefreshTable();
      } catch (error) {
        this.setState({
          loadingButton: false,
        });
        modalError({
          title: "Process Error",
          content: error.message,
        });
      }
    } else {
      modalError({
        title: "Validation Error",
        content: "Empty Table",
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <LayoutDefault defaultOpenKeys={["m-multi-update"]} defaultSelectedKeys={["m-multi-update-vehicle-model"]}>
          <div className="row px-3">
            <Form name="form-update" layout="vertical" className="pt-2">
              <div className="row mx-0">
                <div className="col-lg-11">
                  <div className="row px-2">
                    <div className="col-lg-2 px-1">
                      <SelectCategory value={this.state.inputValueCategory} onChangeValue={this.changeInputCategory} />
                    </div>
                    <div className="col-lg-2 px-1">
                      <SelectBrand value={this.state.inputValueBrandUUID} onChangeValue={this.changeInputValueBrand} />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
            <span className="mb-3 me-0">
              <Button onClick={this.onClickUploadData} title="add" type="primary" style={{ background: "#1784b8", borderColor: "#1784b8" }} loading={this.state.loadingButton} className="custom-class me-1 mt-1">
                <span>
                  <strong style={{ fontSize: "100%" }}>+&nbsp;</strong> Update Model
                </span>
              </Button>
              <Button onClick={this.onClickRefreshTable} title="add" type="danger" style={{ background: "#b81717", borderColor: "#b81717" }} loading={this.state.loadingButton} className="custom-class me-1 mt-1">
                <span>Refresh Table</span>
              </Button>
            </span>
            <div className="col-lg-12">
              <HotTable ref={this.hotTableComponent} settings={this.settingHandsontable} afterValidate={this.afterValidate} />
            </div>
          </div>
        </LayoutDefault>
      </React.Fragment>
    );
  }
}

export default MultiUpdateVehicleModel;
