import axios from "axios";
import { baseUrlAutotrimitraBizApiPost } from "../config/api-url.config";

class VehicleServicesPost {
  axios = axios.create({
    baseURL: baseUrlAutotrimitraBizApiPost,
  });

  setToken(token) {
    this.axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }

  async addBrand(postdata) {
    try {
      return await this.axios.post("vehicle/addbrand", postdata);
    } catch (e) {
      throw new Error("SERVER REJECTED YOUR INPUT");
      // const { response } = e;
      // const { request, ...errorObject } = response;
      // throw new Error(JSON.stringify(errorObject.data.message));
    }
  }

  async addModel(postdata) {
    try {
      return await this.axios.post("vehicle/addmodel", postdata);
    } catch (e) {
      throw new Error("SERVER REJECTED YOUR INPUT");
      // const { response } = e;
      // console.log(response);
      // const { request, ...errorObject } = response;
      // throw new Error(errorObject.data.error.message);
    }
  }

  async addVariant(postdata) {
    try {
      return await this.axios.post("vehicle/addvariant", postdata);
    } catch (e) {
      throw new Error("SERVER REJECTED YOUR INPUT");
      // const { response } = e;
      // const { request, ...errorObject } = response;
      // throw new Error(errorObject.data.error.message);
    }
  }

  async updateBrand(postdata) {
    try {
      return await this.axios.put("vehicle/updatebrand", postdata);
    } catch (e) {
      throw new Error("SERVER REJECTED YOUR INPUT");
      // const { response } = e;
      // const { request, ...errorObject } = response;
      // throw new Error(JSON.stringify(errorObject.data.message));
    }
  }

  async updateModel(postdata) {
    try {
      return await this.axios.put("vehicle/updatemodel", postdata);
    } catch (e) {
      throw new Error("SERVER REJECTED YOUR INPUT");
      // const { response } = e;
      // const { request, ...errorObject } = response;
      // throw new Error(JSON.stringify(errorObject.data.message));
    }
  }

  async updateVariant(postdata) {
    try {
      return await this.axios.put("vehicle/updatevariant", postdata);
    } catch (e) {
      throw new Error("SERVER REJECTED YOUR INPUT");
      // const { response } = e;
      // const { request, ...errorObject } = response;
      // throw new Error(JSON.stringify(errorObject.data.message));
    }
  }

}

export const vehicleServicesPost = new VehicleServicesPost();
