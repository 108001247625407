import React, { Component } from "react";
import { config } from "../../config/general.config";
import { Form, Select } from "antd";

const { Option } = Select;

class FilterSelectClass extends Component {
  initState = {
    dataOption: config.enum_class,
  };
  state = { ...this.initState };

  componentDidMount = async () => {
    try {
    } catch (error) {
      this.setState({
        dataOption: [],
      });
    }
  };

  render() {
    return (
      <Form.Item label="Class" className="col-lg-12">
        <Select
          style={{ fontSize: "85%", minWidth: "200px" }}
          placeholder="Select a Class"
          onChange={this.props.onChangeValue}
          value={this.props.value}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
        >
          <Option key="0" value="0">
            ALL
          </Option>
          {this.state.dataOption.map((d, i) => {
            return (
              <Option key={d} value={d}>
                {d}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  }
}

export default FilterSelectClass;
