import React, { Component } from "react";
import { vehicleServices } from "../../services/vehicle.services";
import { Form, Select } from "antd";

const { Option } = Select;

class FilterSelectBrand extends Component {
  initState = {
    dataOption: [],
  };
  state = { ...this.initState };

  componentDidMount = async () => {
    try {
      const dataBrand = await vehicleServices.getBrandList();
      let listOption = dataBrand.data.data.map((d) => {
        return { brand_uuid: d.brand_uuid, brand_name: d.brand_name };
      });
      this.setState({
        dataOption: listOption,
      });
    } catch (error) {
      this.setState({
        dataOption: [],
      });
    }
  };

  render() {
    return (
      <Form.Item label="Brand" className="col-lg-12">
        <Select
          style={{ fontSize: "85%", minWidth: "200px" }}
          placeholder="Select a Brand"
          onChange={this.props.onChangeValue}
          value={this.props.value}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
        >
          {this.state.dataOption.map((d, i) => {
            return (
              <Option key={d.brand_uuid} value={d.brand_uuid}>
                {d.brand_name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  }
}

export default FilterSelectBrand;
