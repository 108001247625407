import axios from "axios";
import { apikeyCopan, baseUrlDistCopan } from "../config/api-url.config";

class DistributionServices {
  axios = axios.create({
    baseURL: baseUrlDistCopan,
    headers: { "X-API-KEY": apikeyCopan }

  });

  async getArea(company) {
    try {

      return await this.axios.get("area/" + company + "/area");
    } catch (e) {
      const { response } = e;
      // console.log(response);
      const { request, ...errorObject } = response;
      throw new Error(errorObject.data.error.message);
    }
  }

  async addModel(postdata) {
    try {
      return await this.axios.post("product/add-model", postdata);
    } catch (e) {
      throw new Error(e?.response?.data?.error.message);

    }
  }

  async addVariant(postdata) {
    try {
      return await this.axios.post("product/add-variant", postdata);
    } catch (e) {
      throw new Error(e?.response?.data?.error.message);
    }
  }

  async addVariantColor(postdata) {
    try {
      return await this.axios.post("product/add-variant-color", postdata);
    } catch (e) {
      throw new Error(e?.response?.data?.error.message);

    }
  }


}

export const distributionServices = new DistributionServices();
