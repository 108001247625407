import React from "react";
import { Redirect, Route } from "react-router-dom";
import Cookies from "universal-cookie";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const cookies = new Cookies();
  const isLoggedIn = cookies.get("_t_autotrimitrabiz");
  // console.log(isLoggedIn);

  return (
    <Route
      {...rest}
      render={(props) => {
        // console.log(props.match.params);
        return isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
