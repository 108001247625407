const companyCode = "amarta";
const apikeyAutotrimitraBizApi = "AIzaSyCToQTj8VyACsfBVFvxNTtS9Sq01eqEIsU";
const apikeybaseUrlAuth = "Uj7IujdaOe2RqiTzSjo8zaW9NN6es1uZ54BTHeqi";
const apikeyCopan = "TTliO9UPlx7qvPGLt73Y838gsdSvqhlY4QlUJYGe";

/**
 * PRODUCTION LINK.
 */
const baseUrlAuth = "https://01z4tn3758.execute-api.ap-southeast-1.amazonaws.com/v1/";
const baseUrlAutotrimitraBizApi = "https://au-api-trimitra-get-65q45htc.ts.gateway.dev";
const baseUrlAutotrimitraBizApiPost = "https://fh63sf7bye.execute-api.ap-southeast-1.amazonaws.com/v1";
const baseUrlAutotrimitraPart = "https://asia-southeast2-autotrimitra.cloudfunctions.net/autotrimitra___part-GET";
const baseUrlAutotrimitraPartPost = "https://asia-southeast2-autotrimitra.cloudfunctions.net/autotrimitra___part-POST";
const baseUrlAutotrimitraPartPut = "https://asia-southeast2-autotrimitra.cloudfunctions.net/autotrimitra___part-PUT";
const baseUrlArt = "https://2dkeeytpe6.execute-api.ap-southeast-1.amazonaws.com/v1";
const baseUrlArtGenImage = "https://asia-southeast2-autotrimitra.cloudfunctions.net/art__image-variant-converter";
const baseUrlDistCopan = "https://zvu1c5uoue.execute-api.ap-southeast-1.amazonaws.com/v1/";

/**
 * DEVELOPMENT LINK.
 */
// const baseUrlAuth = "http://localhost:9999/autotrimitra";
// const baseUrlAutotrimitraBizApi = "http://localhost:9999/autotrimitra";
// const baseUrlAutotrimitraBizApiPost = "http://localhost:9999/autotrimitra";
// const baseUrlAutotrimitraPart = "http://localhost:9999/autotrimitra";
// const baseUrlAutotrimitraPartPost = "http://localhost:9999/autotrimitra";
// const baseUrlAutotrimitraPartPut = "http://localhost:9999/autotrimitra";
// const baseUrlArt = "http://localhost:9999/autotrimitra";
// const baseUrlArtGenImage = "http://localhost:9999/autotrimitra";
// const baseUrlDistCopan = "http://localhost:9999/autotrimitra";

// const baseUrlAutotrimitraBizApiPost = "http://localhost:3001";
// const baseUrlAutotrimitraPart = "http://localhost:3001";
// const baseUrlAutotrimitraPartPost = "http://localhost:3001";

export {
  companyCode,
  apikeybaseUrlAuth,
  baseUrlAuth,
  apikeyAutotrimitraBizApi,
  baseUrlAutotrimitraBizApi,
  baseUrlAutotrimitraBizApiPost,
  baseUrlAutotrimitraPart,
  baseUrlAutotrimitraPartPost,
  baseUrlAutotrimitraPartPut,
  baseUrlArt,
  baseUrlArtGenImage,
  apikeyCopan,
  baseUrlDistCopan,
};
