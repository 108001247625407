const config = {
  porta_biro_jasa_apikey: "hwfub9Mvj29SLFL9hrOIH7EedeUjnX4U1kckJiQW",
  limit_pay_invoice: 200000000,
  allowed_menu_repay: ["roraim", "zamhur"],
  version: "0.2.8",
  maintenance: false,
  enum_category: ["motorcycle", "car", "truck", "bus", "tram", "train", "boat", "ship", "airplane", "helicopters", "spacecraft"],
  enum_class: [
    "sedan",
    "mpv",
    "suv",
    "hatchback",
    "coupe",
    "cross-over",
    "mini-vans",
    "van",
    "station-wagon",
    "wagon",
    "bus",
    "truck",
    "scooter",
    "scooter-high",
    "moped",
    "cub",
    "sport-low",
    "sport-super",
    "sport",
    "off-road",
    "cruiser",
    "street",
    "touring",
    "cafe-racer",
    "adventure-touring",
    "electric-vehicle",
  ],
  enum_fuel: ["gasoline", "diesel", "electric", "flex", "hybrid"],
  enum_fuel_supply_system: ["carburetor", "efi", "mpi", "crdi", "fuel-injection", "pgmfi"],
  enum_transmission: ["manual", "automatic", "cvt", "semi-automatic", "tiptronic", "dsg", "ivt", "dual clutch"],
  enum_valve_config: ["dohc", "sohc", "ohv", "rotary", "hla"],
  enum_active_config: ["true", "false"],
  enum_drive_type: ["2x1", "4x2", "4x4"],
  enum_segment: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
  enum_display_category: ["general", "interior", "front_view", "back_view", "left_view", "right_view", "oblique_front", "oblique_back", "raw_variant", "raw_variant_color", "variant_color"],
  enum_display_type: ["image"],
  enum_feedback_type: ["positive", "negative"],
  enum_feedback_lang: ["ID", "ENG"],
  enum_template_generate_image: ["TEMP-TRIMOBI", "TEMP-AMARTAHONDA", "TEMP-OTODIS"],
  enum_distribution_destination: { none: "NONE", trimoto: "COPAN-TRIMOTO", trimobi: "COPAN-TRIMOBI", amarta: "COPAN-AMARTAHONDA", chery: "COPAN-CHERY", neta: "COPAN-NETA" },
};

export { config };
