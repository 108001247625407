import React, { Component } from "react";
import Cookies from "universal-cookie";
import { v4 as uuidv4 } from "uuid";
import { HotTable } from "@handsontable/react";
import LayoutDefault from "../../layout/layout-default.component";

import { vehicleServices } from "../../../services/vehicle.services";
import { config } from "../../../config/general.config";
import { vehicleServicesPost } from "../../../services/vehicle-post.services";
import { modalError, modalSuccess } from "../../../helpers/modal.helper";

import { Button } from "antd";

import "handsontable/dist/handsontable.full.css";

class ImportVehicleModel extends Component {
  cookies = new Cookies();

  constructor(props) {
    super(props);

    this.initState = {
      isValidTable: true,
      loadingButton: false,
    };

    this.state = { ...this.initState };
    this.hotTableComponent = React.createRef();
    this.settingHandsontable = {
      rowHeaders: true,
      colWidths: 150,
      width: "100%",
      height: 400,
      colHeaders: [
        "Brand UUID",
        "Model Name",
        "Market Name",
        "Series Name",
        "Category",
        "Class",
        "Engine Capacity",
        "Min Engine Capacity",
        "Max Engine Capacity",
        "Fuel Type",
        "Fuel Supply System",
        "Transmission",
        "Segment",
        "Link Info",
        "Link Image",
      ],
      columns: [
        { data: "brand_uuid", type: "text" },
        { data: "model_name", type: "text" },
        { data: "market_name", type: "text" },
        { data: "series_name", type: "text" },
        { data: "category", type: "text" },
        { data: "class", type: "text" },
        { data: "engine_capacity", type: "text" },
        { data: "min_engine_capacity", type: "text" },
        { data: "max_engine_capacity", type: "text" },
        { data: "fuel_type", type: "text" },
        { data: "fuel_supply_system", type: "text" },
        { data: "transmission", type: "text" },
        { data: "segment", type: "text" },
        { data: "link_wiki", type: "text" },
        { data: "link_image", type: "text" },
      ],
    };
  }

  componentDidMount = () => {
    this.onClickRefreshTable();
  };

  afterValidate = (isValid) => {
    this.setState({
      isValidTable: isValid,
    });
  };

  onClickRefreshTable = () => {
    let emptyData = [];
    this.hotTableComponent.current.hotInstance.loadData([]);
    for (let i = 0; i < 15; i++) {
      emptyData.push(["", "", "", "", "", "", "", "", "", "", "", "", ""]);
    }
    this.hotTableComponent.current.hotInstance.loadData(emptyData);
    console.log("countRows", this.hotTableComponent.current.hotInstance.countRows());

    this.setState({
      loadingButton: false,
    });
  };

  onClickUploadData = async () => {
    const { isValidTable } = this.state;

    if (!isValidTable) {
      modalError({
        title: "Validation Error",
        content: "Data Table Not Valid",
      });
      return false;
    }

    let dataTable = this.hotTableComponent.current.hotInstance.getData();
    let notValidInput = [];
    const dataBrand = await vehicleServices.getBrandList();
    const listBrand = {};
    for (const b of dataBrand.data.data) {
      listBrand[b.brand_uuid] = b.brand_name;
    }

    let dataCleaned = [];
    let i = 1;
    for (const row of dataTable) {
      const brand_uuid = row[0] ? row[0].toString().toLowerCase() : null;
      let brand_name = "";
      const model_name = row[1] ? row[1].trim().toString().toLowerCase() : null;
      const market_name = row[2] ? row[2].trim() : null;
      const series_name = row[3] ? row[3].trim() : null;
      const category = row[4] ? row[4].trim().toString().toLowerCase() : null;
      const model_class = row[5] ? row[5].trim().toString().toLowerCase() : null;
      const engine_capacity = row[6] ? Number(row[6]) : 0;
      const min_engine_capacity = row[7] ? Number(row[7]) : 0;
      const max_engine_capacity = row[8] ? Number(row[8]) : 0;
      const fuel_type = row[9] ? row[9].trim().toString().toLowerCase().split(",") : null;
      const fuel_supply_system = row[10] ? row[10].trim().toString().toLowerCase().split(",") : null;
      const transmission = row[11] ? row[11].trim().toString().toLowerCase().split(",") : null;
      const segment = row[12] ? row[12].trim().toString().toUpperCase() : null;
      const link_wiki = row[13] ? row[13].trim() : null;
      const link_image = row[14] ? row[14].trim() : null;

      let obj_fuel = {};
      let obj_fuel_supply_system = {};
      let obj_transmission = {};

      if (brand_uuid || brand_name || model_name || market_name || series_name || category || model_class) {
        if (brand_uuid) {
          if (listBrand[brand_uuid]) {
            brand_name = listBrand[brand_uuid];
          } else {
            notValidInput.push("Brand UUID Not Listed On Line " + i);
          }
        } else {
          notValidInput.push("Empty Brand UUID On Line " + i);
        }

        if (!model_name) {
          notValidInput.push("Empty Model Name On Line " + i);
        }

        if (!market_name) {
          notValidInput.push("Empty Market Name On Line " + i);
        }

        if (!series_name) {
          notValidInput.push("Empty Series Name On Line " + i);
        }

        if (!category) {
          notValidInput.push("Empty Category On Line " + i);
        } else {
          if (!config.enum_category.includes(category)) {
            notValidInput.push("Category Not Listed On Line " + i);
          }
        }

        if (!model_class) {
          notValidInput.push("Empty Class On Line " + i);
        } else {
          if (!config.enum_class.includes(model_class)) {
            notValidInput.push("Class Not Listed On Line " + i);
          }
        }

        if (fuel_type) {
          for (let ft = 0; ft < fuel_type.length; ft++) {
            const fuel = fuel_type[ft];
            if (fuel) {
              if (!config.enum_fuel.includes(fuel)) {
                notValidInput.push("Fuel Type Not Listed On Line " + i);
              } else {
                obj_fuel[fuel] = true;
              }
            }
          }
        }

        if (fuel_supply_system) {
          for (let fuel = 0; fuel < fuel_supply_system.length; fuel++) {
            const f = fuel_supply_system[fuel];
            if (f) {
              if (!config.enum_fuel_supply_system.includes(f)) {
                notValidInput.push("Fuel Supply System Not Listed On Line " + i);
              } else {
                obj_fuel_supply_system[f] = true;
              }
            }
          }
        }

        if (transmission) {
          for (let t = 0; t < transmission.length; t++) {
            const tra = transmission[t];
            if (tra) {
              if (!config.enum_transmission.includes(tra)) {
                notValidInput.push("Transmission Not Listed On Line " + i);
              } else {
                obj_transmission[tra] = true;
              }
            }
          }
        }

        if (segment) {
          if (!config.enum_segment.includes(segment)) {
            notValidInput.push("Segment Not Listed On Line " + i);
          }
        }

        if (notValidInput.length < 1) {
          dataCleaned.push({
            uuid: uuidv4(),
            name: model_name,
            market_name: market_name,
            category: category,
            class: model_class,
            engine_capacity: engine_capacity,
            engine_capacity_minimum: min_engine_capacity,
            engine_capacity_maximum: max_engine_capacity,
            fuel: obj_fuel,
            fuel_supply_system: obj_fuel_supply_system,
            transmission: obj_transmission,
            year_production: null,
            year_first_production: null,
            year_latest_production: null,
            url_image: link_image,
            url_info: link_wiki,
            series_name: series_name,
            brand_uuid: brand_uuid,
            brand_name: brand_name,
            segment: segment,
          });
        }
      }
      i++;
    }

    if (notValidInput.length > 0) {
      modalError({
        title: "Validation Error",
        content: (
          <div>
            {notValidInput.map((d, i) => {
              return (
                <div>
                  {d}
                  <br />
                </div>
              );
            })}
          </div>
        ),
      });
      return false;
    }

    if (dataCleaned.length > 0) {
      try {
        this.setState({
          loadingButton: true,
        });
        const token = this.cookies.get("_t_autotrimitrabiz");
        await vehicleServicesPost.setToken(token);
        await vehicleServicesPost.addModel(dataCleaned);
        modalSuccess({
          title: "Data Processed",
          width: 800,
          content: "SUCCESS",
        });
        this.onClickRefreshTable();
      } catch (error) {
        this.setState({
          loadingButton: false,
        });
        modalError({
          title: "Process Error",
          content: error.message,
        });
      }
    } else {
      modalError({
        title: "Validation Error",
        content: "Empty Table",
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <LayoutDefault defaultOpenKeys={["m-import"]} defaultSelectedKeys={["m-import-vehicle-model"]}>
          <div className="row px-3 my-3">
            <span className="mb-3 me-0">
              <Button onClick={this.onClickUploadData} title="add" type="primary" style={{ background: "#1784b8", borderColor: "#1784b8" }} loading={this.state.loadingButton} className="custom-class">
                <span>
                  <strong style={{ fontSize: "100%" }}>+&nbsp;</strong> Add Model
                </span>
              </Button>
              &nbsp;
              <Button onClick={this.onClickRefreshTable} title="add" type="danger" style={{ background: "#b81717", borderColor: "#b81717" }} loading={this.state.loadingButton} className="custom-class">
                <span>Refresh Table</span>
              </Button>
            </span>
            <div className="col-lg-12">
              <HotTable ref={this.hotTableComponent} settings={this.settingHandsontable} afterValidate={this.afterValidate} />
            </div>
          </div>
        </LayoutDefault>
      </React.Fragment>
    );
  }
}

export default ImportVehicleModel;
