import React, {Component} from "react";
import Cookies from "universal-cookie";
import LayoutDefault from "../../layout/layout-default.component";
import {businessEntityServices} from "../../../services/business-entity.services";

import {Form, Input, Table, Button, Modal, Row, Divider, Col} from "antd";
import {SearchOutlined} from "@ant-design/icons";

class MasterBusinessEntity extends Component {
    cookies = new Cookies();

    constructor(props) {
        super(props);

        this.initState = {
            loadingButton: false,
            processingFetchTableData: false,
            pageSize: 100,
            filterText: "",
            tableSelectedRowKeys: [],
            dataTable: [],
            showModalEdit: false,
            radioVerifyBusiness: false,
            selectedRowData: {},
            showModalRemove: false,
            removeProcess: false,

            username: atob(this.cookies.get("_u_autotrimitrabiz")),
        };

        this.state = {...this.initState};
    }

    tableColumn = [{
        title: "UUID",
        dataIndex: "uuid",
        key: "uuid",
    }, {
        title: "Name",
        dataIndex: "business_entity_name",
        key: "business_entity_name",
    }, {
        title: "Location",
        dataIndex: "city_name",
        key: "city_name",
    }, {
        title: "NPWP",
        dataIndex: "npwp_code",
        key: "npwp_code",
    }, {
        title: "NIB Code",
        dataIndex: "nib_code",
        key: "nib_code",
    }, {
        title: "Verified",
        dataIndex: "verified",
        key: "verified",
        render: (value) => {
            return value.toString().toUpperCase();
        },
    }, {
        title: "Active",
        dataIndex: "active",
        key: "active",
        render: (value) => {
            return value.toString().toUpperCase();
        },
    }, {
        title: "Action",
        dataIndex: "action",
        width: 100,
        render: (value, row) => {
            return (
                <React.Fragment>
                    <Button style={{margin: 2}} size={"small"} type={"primary"} onClick={(event) => this.showEditModal(row)}>Verify Business</Button>
                    <Button style={{margin: 2}} size={"small"} type={"primary"} danger={true} onClick={(event) => this.showRemoveModal(row)}>Remove Business</Button>
                </React.Fragment>
            );
        },
    }];

    showEditModal = async (data) => {
        this.setState({
            selectedRowData: data,
            radioVerifyBusiness: data?.verified,
            showModalEdit: true,
        });
    };

    showRemoveModal = async (data) => {
        this.setState({
            selectedRowData: data,
            showModalRemove: true,
        });
    };

    closeModalEdit = (data) => {
        this.setState({
            showModalEdit: false,
        });
    };

    closeModalRemove = (data) => {
        this.setState({
            showModalRemove: false,
        });
    };

    changeFilterBrand = (v) => {
        this.setState({
            filterText: v.target.value,
        });
    };

    componentDidMount() {
        this.fetchDataOrder();
    }

    fetchDataOrder = async () => {
        try {
            this.setState({
                processingFetchTableData: true,
                loadingButton: true,
            });

            const params = {};
            const dataTable = await businessEntityServices.getBusinessEntity(params);

            this.setState({
                dataTable: dataTable.data.data,
                processingFetchTableData: false,
                loadingButton: false,
                tableSelectedRowKeys: [],
            });
        } catch (error) {
            this.setState({
                dataTable: [],
                processingFetchTableData: false,
                loadingButton: false,
                tableSelectedRowKeys: [],
            });
        }
    };

    onClickFilter = async () => {
        await this.fetchDataOrder();
    };

    onFieldChange = async (target, value) => {
        const currentState = {...this.state};
        switch (target) {
            case "radioVerifyBusiness":
                currentState.radioVerifyBusiness = value;
                break;
            default:
                break;
        }
        this.setState({
            ...currentState,
        });
    };

    onRemoveChanges = async () => {
        this.setState({
            loading: true,
            removeProcess: true,
        });

        try {
            await businessEntityServices
                .removeBusinessEntity({
                    abstract: "general",
                    uuid: this.state.selectedRowData.uuid,
                    verify_admin_id: this.state.username,
                })
                .then(async (successData) => {
                    Modal.success({title: "Succes", content: "Remove Data Success "});
                    this.setState(this.initState);
                    await this.fetchDataOrder();
                });
        } catch (e) {
            Modal.error({
                title: "Failed",
                content: "Server Error " + e,
            });
            this.setState({
                loading: false,
                removeProcess: false,
            });
        }
    }

    onSaveChanges = async () => {
        this.setState({
            loading: true,
            editProcess: true,
        });

        try {
            await businessEntityServices
                .verifyBusinessEntity({
                    abstract: "general",
                    uuid: this.state.selectedRowData.uuid,
                    verified: this.state.radioVerifyBusiness,
                    verify_admin_id: this.state.username,
                })
                .then(async (successData) => {
                    Modal.success({
                        title: "Succes",
                        content: "Update Data Success ",
                    });
                    this.setState(this.initState);
                    await this.fetchDataOrder();
                });
        } catch (e) {
            Modal.error({
                title: "Failed",
                content: "Server Error " + e,
            });
            this.setState({
                loading: false,
                editProcess: false,
            });
        }
    };

    render() {
        const rowTableSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    tableSelectedRowKeys: selectedRows,
                });
            },
        };

        return (
            <React.Fragment>
                <LayoutDefault defaultOpenKeys={["sm-master"]} defaultSelectedKeys={["m-master-business-entity"]}>
                    <div className="row mx-0">
                        <div className="col-lg-2 mb-1">
                            <Form name="form-filter" layout="vertical" className="me-3 pt-2">
                                <div className="row px-2">
                                    <div className="col-lg-8 col-11 px-0">
                                        <Form.Item name="brand_name" className="col-lg-12 mb-1">
                                            <Input onChange={this.changeFilterBrand} disabled placeholder="Show data"/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-lg-1 col-1 px-0">
                                        <Button
                                            onClick={this.onClickFilter}
                                            type="primary"
                                            icon={<SearchOutlined/>}
                                            title="filter"
                                            className="px-2 me-1 mb-1"
                                            style={{
                                                background: "#36383a",
                                                borderColor: "#36383a",
                                                width: "40px",
                                            }}
                                            loading={this.state.loadingButton}
                                        ></Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>

                    <Table
                        rowSelection={rowTableSelection}
                        rowKey={"brand_uuid"}
                        size={"small"}
                        loading={this.state.processingFetchTableData}
                        columns={this.tableColumn}
                        dataSource={this.state.dataTable}
                        pagination={{pageSize: this.state.pageSize}}
                        onChange={this.onChangeTable}
                    />
                </LayoutDefault>
                <Modal
                    open={this.state.showModalEdit}
                    title="Verify Business Entity"
                    onOk={this.closeModalEdit}
                    onCancel={this.closeModalEdit}
                    cancelText={"Tutup"}
                    okText={false}
                    footer={[
                        <Button type={"primary"} loading={this.state.editProcess} key="1" onClick={this.onSaveChanges}>
                            Verifikasi
                        </Button>,
                        <Button loading={this.state.editProcess} key="2" onClick={this.closeModalEdit}>
                            Close
                        </Button>,
                    ]}
                >
                    <Divider orientation="left" plain>
                        <span className={`divider-stock-purchase`}>Badan Usaha</span>
                    </Divider>
                    <Row>
                        <Col span={8} className={`col-grey`}>
                            Jenis Badan Usaha:
                        </Col>
                        <Col span={16}>{this.state.selectedRowData?.business_entity_type}</Col>
                        <Col span={8} className={`col-grey`}>
                            Nama Badan Usaha:
                        </Col>
                        <Col span={16}>{this.state.selectedRowData?.business_entity_name}</Col>
                        <Col span={8} className={`col-grey`}>
                            Kode NPWP:
                        </Col>
                        <Col span={16}>{this.state.selectedRowData?.npwp_code}</Col>
                        <Col span={8} className={`col-grey`}>
                            Image NPWP:
                        </Col>
                        <Col span={16}>
                            {this.state.selectedRowData?.npwp_image ? (
                                <a target={`_blank`} href={this.state.selectedRowData?.npwp_image}>
                                    Lihat Image
                                </a>
                            ) : (
                                ""
                            )}
                        </Col>
                        <Col span={8} className={`col-grey`}>
                            Kode NIB:
                        </Col>
                        <Col span={16}>{this.state.selectedRowData?.nib_code}</Col>
                        <Col span={8} className={`col-grey`}>
                            PDF NIB:
                        </Col>
                        <Col span={16}>
                            {this.state.selectedRowData?.nib_pdf ? (
                                <a target={`_blank`} href={this.state.selectedRowData?.nib_pdf}>
                                    Lihat PDF
                                </a>
                            ) : (
                                ""
                            )}
                        </Col>
                        <Col span={8} className={`col-grey`}>
                            Nomor Telpon:
                        </Col>
                        <Col span={16}>{this.state.selectedRowData?.phone_number}</Col>
                        <Col span={8} className={`col-grey`}>
                            Email:
                        </Col>
                        <Col span={16}>{this.state.selectedRowData?.email}</Col>
                        <Col span={8} className={`col-grey`}>
                            Website:
                        </Col>
                        <Col span={16}>{this.state.selectedRowData?.website}</Col>
                    </Row>

                    <Divider orientation="left" plain>
                        <span className={`divider-stock-purchase`}>Alamat</span>
                    </Divider>
                    <Row>
                        <Col span={8} className={`col-grey`}>
                            Provinsi:
                        </Col>
                        <Col span={16}>{this.state.selectedRowData?.province_name}</Col>
                        <Col span={8} className={`col-grey`}>
                            Kota:
                        </Col>
                        <Col span={16}>{this.state.selectedRowData?.city_name}</Col>
                        <Col span={8} className={`col-grey`}>
                            Kecamatan:
                        </Col>
                        <Col span={16}>{this.state.selectedRowData?.district_name}</Col>
                        <Col span={8} className={`col-grey`}>
                            Kelurahan:
                        </Col>
                        <Col span={16}>{this.state.selectedRowData?.subdistrict_name}</Col>
                        <Col span={8} className={`col-grey`}>
                            Kode Pos:
                        </Col>
                        <Col span={16}>{this.state.selectedRowData?.postal_code}</Col>
                        <Col span={8} className={`col-grey`}>
                            Alamat:
                        </Col>
                        <Col span={16}>{this.state.selectedRowData?.address}</Col>
                    </Row>
                </Modal>

                <Modal
                    open={this.state.showModalRemove}
                    title="Remove Unverified Business Entity"
                    onOk={this.closeModalRemove}
                    onCancel={this.closeModalRemove}
                    cancelText={"Tutup"}
                    okText={false}
                    footer={[
                        <Button danger={true} type={"primary"} loading={this.state.removeProcess} key="1" onClick={this.onRemoveChanges}>Remove</Button>,
                        <Button loading={this.state.removeProcess} key="2" onClick={this.closeModalRemove}>Close</Button>
                    ]}
                >
                    <Divider orientation="left" plain>
                        <span className={`divider-stock-purchase`}>Badan Usaha</span>
                    </Divider>
                    <Row>
                        <Col span={8} className={`col-grey`}>Jenis Badan Usaha:</Col>
                        <Col span={16}>{this.state.selectedRowData?.business_entity_type}</Col>
                        <Col span={8} className={`col-grey`}>Nama Badan Usaha:</Col>
                        <Col span={16}>{this.state.selectedRowData?.business_entity_name}</Col>
                        <Col span={8} className={`col-grey`}>Kode NPWP:</Col>
                        <Col span={16}>{this.state.selectedRowData?.npwp_code}</Col>
                        <Col span={8} className={`col-grey`}>Image NPWP:</Col>
                        <Col span={16}>
                            {this.state.selectedRowData?.npwp_image ? (
                                <a target={`_blank`} href={this.state.selectedRowData?.npwp_image}>
                                    Lihat Image
                                </a>
                            ) : ("")}
                        </Col>
                    </Row>
                </Modal>
            </React.Fragment>
        );
    }
}

export default MasterBusinessEntity;
