import axios from "axios";
import { baseUrlArtGenImage } from "../config/api-url.config";

class GenerateImage {
  axios = axios.create({
    baseURL: baseUrlArtGenImage,
  });

  setToken(token) {
    this.axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }

  async generateImageVariant(postdata) {
    try {
      return await this.axios.post("generate-image/autotrimitra/variant", postdata);
    } catch (e) {
      throw new Error("SERVER REJECTED YOUR INPUT");
      // const { response } = e;
      // const { request, ...errorObject } = response;
      // throw new Error(errorObject.data.error.message);
    }
  }

}

export const generateImage = new GenerateImage();
