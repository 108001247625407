import React, { Component } from "react";
import Cookies from "universal-cookie";
import { HotTable } from "@handsontable/react";
import LayoutDefault from "../../layout/layout-default.component";

import SelectCategory from "../../layout/select-category.component";

import { vehicleServicesPost } from "../../../services/vehicle-part-post.services";
import { modalError, modalSuccess } from "../../../helpers/modal.helper";

import { Button, Form } from "antd";

import "handsontable/dist/handsontable.full.css";
import FormItem from "antd/lib/form/FormItem";

class ImportVehiclePart extends Component {
  cookies = new Cookies();

  constructor(props) {
    super(props);

    this.initState = {
      isValidTable: true,
      loadingButton: false,
      inputValueCategory: "",
    };

    this.state = { ...this.initState };
    this.hotTableComponent = React.createRef();
    this.settingHandsontable = {
      rowHeaders: true,
      colWidths: 200,
      height: 400,
      colHeaders: ["Variant Code", "Part Number", "Part Number Market", "Part Name", "Part Maker Name", "Part Maker Image", "Part Type UUID", "Qty", "Description"],
      columns: [
        { data: "variant_code", type: "text" },
        { data: "part_number", type: "text" },
        { data: "part_number_market", type: "text" },
        { data: "part_name", type: "text" },
        { data: "part_maker_name", type: "text" },
        { data: "part_maker_image", type: "text" },
        { data: "part_type_uuid", type: "text" },
        { data: "part_qty", type: "text" },
        { data: "part_description", type: "text" },
      ],
    };
  }

  componentDidMount = () => {
    this.onClickRefreshTable();
  };

  afterValidate = (isValid) => {
    this.setState({
      isValidTable: isValid,
    });
  };

  onClickRefreshTable = () => {
    let emptyData = [];
    this.hotTableComponent.current.hotInstance.loadData([]);
    for (let i = 0; i < 15; i++) {
      emptyData.push(["", "", "", "", "", "", "", "", "", "", "", "", ""]);
    }
    this.hotTableComponent.current.hotInstance.loadData(emptyData);
    console.log("countRows", this.hotTableComponent.current.hotInstance.countRows());

    this.setState({
      loadingButton: false,
    });
  };

  onClickUploadData = async () => {
    const { isValidTable, inputValueCategory } = this.state;

    if (!isValidTable) {
      modalError({
        title: "Validation Error",
        content: "Data Table Not Valid",
      });
      return false;
    }

    let dataTable = this.hotTableComponent.current.hotInstance.getData();
    let notValidInput = [];
    let dataCleaned = [];

    if (inputValueCategory) {
      for (const row of dataTable) {
        const variant_code = row[0];
        const part_number = row[1];
        const part_number_market = row[2];
        const part_name = row[3];
        const part_maker_name = row[4];
        const part_maker_image = row[5];
        const part_type_uuid = row[6];
        const part_qty = row[5] ? Number(row[7]) : "";
        const part_description = row[8];

        if (part_number && part_name && part_type_uuid && part_qty) {
          dataCleaned.push({
            category: inputValueCategory,
            variant_code: variant_code,
            part_number: part_number,
            part_number_market: part_number_market,
            part_name: part_name,
            part_maker_name: part_maker_name,
            part_maker_image: part_maker_image,
            part_type_uuid: part_type_uuid,
            part_qty: part_qty,
            part_description: part_description,
          });
        }
      }
    } else {
      modalError({
        title: "Validation Error",
        content: "Empty Category ",
      });
      return false;
    }

    if (notValidInput.length > 0) {
      modalError({
        title: "Validation Error",
        content: (
          <div>
            {notValidInput.map((d, i) => {
              return (
                <div>
                  {d}
                  <br />
                </div>
              );
            })}
          </div>
        ),
      });
      return false;
    }

    if (dataCleaned.length > 0) {
      try {
        this.setState({
          loadingButton: true,
        });

        const token = this.cookies.get("_t_autotrimitrabiz");
        await vehicleServicesPost.setToken(token);
        await vehicleServicesPost.addPart(dataCleaned);
        modalSuccess({
          title: "Data Processed",
          width: 800,
          content: "SUCCESS",
        });
        this.onClickRefreshTable();
      } catch (error) {
        this.setState({
          loadingButton: false,
        });

        const multi_message = error.message.split(",");
        if (multi_message.length > 0) {
          modalError({
            title: "Process Error",
            content: (
              <div>
                {multi_message.map((d, i) => {
                  return (
                    <div>
                      {d}
                      <br />
                    </div>
                  );
                })}
              </div>
            ),
          });
        } else {
          modalError({
            title: "Process Error",
            content: error.message,
          });
        }
      }
    } else {
      modalError({
        title: "Validation Error",
        content: "Empty Table",
      });
    }
  };

  changeInputCategory = async (v) => {
    await this.setState({
      inputValueCategory: v,
    });
  };

  render() {
    return (
      <React.Fragment>
        <LayoutDefault defaultOpenKeys={["m-import"]} defaultSelectedKeys={["m-import-vehicle-part"]}>
          <div className="row px-3">
            <Form name="form-filter" layout="vertical" className="pt-2">
              <div className="row mx-0">
                <div className="col-lg-11">
                  <div className="row px-2">
                    <div className="col-lg-2 px-1">
                      <SelectCategory value={this.state.inputValueCategory} onChangeValue={this.changeInputCategory} />
                    </div>
                    <div className="col-lg-3 px-1 pt-2">
                      <span className="mb-3 me-0">
                        <FormItem label=" ">
                          <Button
                            onClick={this.onClickUploadData}
                            title="add"
                            type="primary"
                            style={{
                              background: "#1784b8",
                              borderColor: "#1784b8",
                            }}
                            loading={this.state.loadingButton}
                            className="custom-class"
                          >
                            <span>
                              <strong style={{ fontSize: "100%" }}>+&nbsp;</strong> Add Part
                            </span>
                          </Button>
                          &nbsp;
                          <Button
                            onClick={this.onClickRefreshTable}
                            title="add"
                            type="danger"
                            style={{
                              background: "#b81717",
                              borderColor: "#b81717",
                            }}
                            loading={this.state.loadingButton}
                            className="custom-class"
                          >
                            <span>Refresh Table</span>
                          </Button>
                        </FormItem>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Form>

            <div className="col-lg-12">
              <HotTable ref={this.hotTableComponent} settings={this.settingHandsontable} afterValidate={this.afterValidate} />
            </div>
          </div>
        </LayoutDefault>
      </React.Fragment>
    );
  }
}

export default ImportVehiclePart;
