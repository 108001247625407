import React, { Component } from "react";
import Cookies from "universal-cookie";
import LayoutDefault from "../../layout/layout-default.component";

import FilterSelectCategory from "../../layout/filter-select-category.component";
import SelectCategory from "../../layout/select-category.component";

import { vehiclePartService } from "../../../services/vehicle-part.services";
import { vehicleServicesPost } from "../../../services/vehicle-part-post.services";
import { modalError, modalSuccess } from "../../../helpers/modal.helper";

import { Form, Input, Table, Button, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const { Option } = Select;

class MasterVehiclePartType extends Component {
  cookies = new Cookies();

  constructor(props) {
    super(props);

    this.initState = {
      processing: false,
      processingFetchTableData: false,

      pageSize: 100,
      filterPartType: "",
      filterCategory: "",

      tableSelectedRowKeys: [],
      dataPartType: [],
      titleCanvas: "Default Title",

      inputPartType: "",
      inputCategory: "",
      inputActive: "",

      loadingButton: false,
      showHideReadCanvas: "d-block",
      showHideFormCanvas: "m-2 px-1 d-none",
      showHideButtonForm: "me-1",
      formEdit: false,
    };

    this.state = { ...this.initState };
  }

  tableColumn = [
    {
      title: "UUID",
      dataIndex: "uuid",
      key: "uuid",
    },
    {
      title: "Part Type",
      dataIndex: "part_type",
      key: "part_type",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
    },
  ];

  changeFilterPartType = (v) => {
    this.setState({
      filterPartType: v.target.value,
    });
  };

  changeFilterCategory = (v) => {
    this.setState({
      filterCategory: v,
    });
  };

  fetchDataOrder = async () => {
    try {
      const filter = this.state;

      this.setState({
        processingFetchTableData: true,
        loadingButton: true,
      });

      const params = {};

      if (filter.filterCategory !== "" && filter.filterCategory !== "0") {
        params.category = filter.filterCategory;
      }
      if (filter.filterPartType !== "") {
        params.part_type = filter.filterPartType;
      }

      const token = this.cookies.get("_t_autotrimitrabiz");
      await vehiclePartService.setToken(token);
      const dataPartType = await vehiclePartService.getPartTypeList(params);
      // const dataPartType = await dealerServices.getDealer();
      // console.log(dataPartType.data.data);

      this.setState({
        dataPartType: dataPartType.data.data,
        processingFetchTableData: false,
        loadingButton: false,
        tableSelectedRowKeys: [],
      });
    } catch (error) {
      this.setState({
        dataPartType: [],
        processingFetchTableData: false,
        loadingButton: false,
        tableSelectedRowKeys: [],
      });
    }
  };

  onClickFilter = async () => {
    await this.fetchDataOrder();
  };

  onClickAddPartType = async () => {
    this.setState({
      titleCanvas: "Add New Part Type",
      showHideReadCanvas: "d-none",
      showHideFormCanvas: "m-2 px-1 d-block",
      showHideButtonForm: "me-1",
      formEdit: false,
    });
  };

  onClickCancel = async () => {
    this.setState({
      inputPartType: "",
      showHideReadCanvas: "d-block",
      showHideFormCanvas: "m-2 px-1 d-none",
      loadingButton: false,
    });
  };

  changeInputPartType = (v) => {
    this.setState({
      inputPartType: v.target.value,
    });
  };

  changeInputCategory = (v) => {
    this.setState({
      inputCategory: v,
    });
  };

  changeInputActive = (v) => {
    this.setState({
      inputActive: v,
    });
  };

  onClickSave = async () => {
    if (this.state.formEdit) {
      return false;
    }
    const postData = {
      part_type: this.state.inputPartType,
      category: this.state.inputCategory,
      active: this.state.inputActive,
    };

    if (!postData.part_type) {
      modalError({
        title: "Validation Error",
        content: "Invalid Part Type",
      });
      return false;
    }
    if (!postData.category) {
      modalError({
        title: "Validation Error",
        content: "Invalid Category",
      });
      return false;
    }
    if (!postData.active) {
      modalError({
        title: "Validation Error",
        content: "Invalid Active",
      });
      return false;
    }

    this.setState({
      loadingButton: true,
    });

    try {
      const token = this.cookies.get("_t_autotrimitrabiz");
      await vehicleServicesPost.setToken(token);
      await vehicleServicesPost.addPartType([postData]);
      this.onClickCancel();
      modalSuccess({
        content: "SUCCESS",
      });
      this.setState({
        dataPartType: [],
      });
    } catch (error) {
      this.setState({
        loadingButton: false,
      });
      modalError({
        title: "Process Error",
        content: error.message,
      });
    }
  };

  render() {
    const rowTableSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          tableSelectedRowKeys: selectedRows,
        });
      },
    };

    return (
      <React.Fragment>
        <LayoutDefault defaultOpenKeys={["sm-master"]} defaultSelectedKeys={["m-master-vehicle-part-type"]}>
          <div id="read_data_canvas" className={this.state.showHideReadCanvas}>
            <div className="row mx-0">
              <div className="col-lg-3">
                <Form name="form-filter" layout="vertical" className="me-3 pt-2">
                  <div className="row px-2">
                    <div className="col-lg-6 col-11 px-0">
                      <FilterSelectCategory value={this.state.filterCategory} onChangeValue={this.changeFilterCategory} />
                    </div>
                    <div className="col-lg-4 col-11 px-0">
                      <Form.Item label="Part Type" name="part_type" className="col-lg-12 mb-1">
                        <Input onChange={this.changeFilterPartType} placeholder="Part Type" />
                      </Form.Item>
                    </div>
                    <div className="col-lg-1 col-1 px-0">
                      <Form.Item label=" ">
                        <Button
                          onClick={this.onClickFilter}
                          type="primary"
                          icon={<SearchOutlined />}
                          title="filter"
                          className="px-2 me-1 mb-1"
                          style={{
                            background: "#36383a",
                            borderColor: "#36383a",
                            width: "40px",
                          }}
                          loading={this.state.loadingButton}
                        ></Button>
                      </Form.Item>
                    </div>
                  </div>
                </Form>
              </div>
              <div className="col-lg-7"></div>
              <div className="col-lg-2 pt-2 mb-3">
                <Button onClick={this.onClickAddPartType} title="add" type="primary" style={{ background: "#1784b8", borderColor: "#1784b8" }} loading={this.state.loadingButton} className="float-end">
                  <span>
                    <strong style={{ fontSize: "100%" }}>+&nbsp;</strong> Add Part Type
                  </span>
                </Button>
              </div>
            </div>

            <Table
              rowSelection={rowTableSelection}
              rowKey={"uuid"}
              size={"small"}
              loading={this.state.processingFetchTableData}
              columns={this.tableColumn}
              dataSource={this.state.dataPartType}
              pagination={{ pageSize: this.state.pageSize }}
              onChange={this.onChangeTable}
            />
          </div>
          <div id="add_edit_canvas" className={this.state.showHideFormCanvas}>
            <h4 style={{ textTransform: "capitalize" }}>{this.state.titleCanvas}</h4>
            <hr />
            <Form layout="horizontal" labelCol={{ span: 2 }} wrapperCol={{ span: 10 }} ref={this.formInput}>
              <Form.Item label="Part Type" className="my-1 py-1">
                <Input name="part_type" value={this.state.inputPartType} onChange={this.changeInputPartType} placeholder="Part Type" />
              </Form.Item>
              <SelectCategory value={this.state.inputCategory} onChangeValue={this.changeInputCategory} />
              <Form.Item label="Active" name="active" className="my-1 py-1">
                <Select style={{ fontSize: "85%", minWidth: "200px" }} placeholder="Choose Status Active" value={this.state.inputActive} onChange={this.changeInputActive}>
                  <Option key="true" value="true">
                    TRUE
                  </Option>
                  <Option key="false" value="false">
                    FALSE
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  sm: { span: 16, offset: 2 },
                }}
                className="mt-3"
              >
                <Button onClick={this.onClickSave} type="primary" className={this.state.showHideButtonForm} style={{ background: "#1784b8", borderColor: "#1784b8" }} loading={this.state.loadingButton}>
                  Save Part Type
                </Button>
                <Button onClick={this.onClickCancel} type="danger" className="" loading={this.state.loadingButton}>
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </div>
        </LayoutDefault>
      </React.Fragment>
    );
  }
}

export default MasterVehiclePartType;
