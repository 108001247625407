import React, { Component } from "react";
import Cookies from "universal-cookie";

import { vehiclePartService } from "../../services/vehicle-part.services";
import { Form, Select } from "antd";

const { Option } = Select;

class FilterSelectPartType extends Component {
  cookies = new Cookies();

  initState = {
    dataOption: [],
  };
  state = { ...this.initState };

  componentDidMount = async () => {
    try {
      const token = this.cookies.get("_t_autotrimitrabiz");
      await vehiclePartService.setToken(token);
      const dataPartType = await vehiclePartService.getPartTypeList();
      let listOption = dataPartType.data.data.map((d) => {
        return { uuid: d.uuid, part_type: d.part_type };
      });
      this.setState({
        dataOption: listOption,
      });
    } catch (error) {
      this.setState({
        dataOption: [],
      });
    }
  };

  render() {
    return (
      <Form.Item label="Part Type" className="col-lg-12">
        <Select
          style={{ fontSize: "85%", minWidth: "200px" }}
          placeholder="Select a Part Type"
          onChange={this.props.onChangeValue}
          value={this.props.value}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
        >
          <Option key="0" value="0">
            ALL
          </Option>
          {this.state.dataOption.map((d, i) => {
            return (
              <Option key={d.uuid} value={d.uuid}>
                {d.part_type}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  }
}

export default FilterSelectPartType;
