import React, { Component } from "react";
import { Form, Select } from "antd";

const { Option } = Select;

class SelectChairs extends Component {
  initState = {
    dataOption: Array.from({ length: 64 }, (_, i) => i + 1),
  };
  state = { ...this.initState };

  render() {
    return (
      <Form.Item label="Total Chairs" className="col-lg-12 my-1 py-1">
        <Select style={{ fontSize: "85%", minWidth: "200px" }} placeholder="Select Total Chairs" onChange={this.props.onChangeValue} mode={this.props.mode} value={this.props.value}>
          {this.state.dataOption.map((d) => {
            return (
              <Option key={d} value={d}>
                {d}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  }
}

export default SelectChairs;
