import axios from "axios";
import { baseUrlAutotrimitraPartPut } from "../config/api-url.config";

class VehiclePartServicesPut {
  axios = axios.create({
    baseURL: baseUrlAutotrimitraPartPut,
  });

  setToken(token) {
    this.axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }

  async updateDefaultPrice(postdata) {
    try {
      return await this.axios.put("vehicle/update-part-default-price", postdata);
    } catch (e) {
      const { response } = e;
      const { request, ...errorObject } = response;
      throw new Error(errorObject.data.error);
    }
  }


}

export const vehicleServicesPut = new VehiclePartServicesPut();
